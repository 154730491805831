/* -- import Bootstrap v3 --------------------------- */

/* -- import Bootstrap docs ------------------------- */

/* -- import Roboto Font ---------------------------- */

/* -- Body style ------------------------------------ */

/* -- Input styles ---------------------------------- */
._form-material {

    .form-group {
        position: relative;
        margin-top: 30px;
        margin-bottom: 10px;
    }

    .input-group {
        position: relative;
    }

    .form-control {
        display: block;
        height: 36px;
        width: 100%;
        border: none;
        border-radius: 0 !important;
        font-size: 18px;
        padding: 0;
        background-color: transparent;
        box-shadow: none;
        border-bottom: 1px solid $cl-cinza2;
        font-weight: $fw-medium;
        color: $cl-texto;        
    }

    textarea {
        height: auto !important;
    }

    select.form-control:not([size]):not([multiple]) {
        height: 36px;

    }

    .input-group .form-control {
        position: relative;
        z-index: inherit;
        float: inherit;
        width: 100%;
        margin-bottom: 0;
    }

    .form-control:focus {
        border-color: #757575;
        outline: none;
        box-shadow: none;
    }

    /* -- label styles ---------------------------------- */

    label {
        position: absolute;
        top: -18px;
        color: #adadad;
        font-size: 12px;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
        font-weight: bold;
    }

    .form-horizontal .control-label {
        position: relative;
        top: 0;
        margin-bottom: 0;
    }

    @media (min-width: 768px) {
        .form-horizontal .control-label {
            font-size: 16px;
        }
    }

    .float-label {
        left: 15px;
        top: 7px;
        font-size: 14px;
        pointer-events: none;
    }

    /* active state */

    .form-group._group-active {
        .float-label {
            top: -15px;
            font-size: 12px;
            &._top{
                top: -22px;
            }
        }
    }

    .form-control:focus~.float-label,
    .form-control:valid~.float-label {
        top: -15px;
        font-size: 12px;
        &._top{
            top: -22px;
        }
    }

    /* input colors ---- */

    // default
    .form-control:focus {
        ~label {
            color: $cl-maximun-red;
        }

        ~.form-bar:before,
        ~.form-bar:after {
            background: $cl-maximun-red;
        }
    }

    .form-control:valid~.control-label {}

    /* help-block */

    .form-group .help-block {
        position: absolute;
    }

    .help-block {
        color: #bdbdbd; // caption
        font-size: 12px;
        font-weight: 300;
    }

    /* input addon ---*/

    .input-group-addon {
        border: none;
        background: transparent;
    }

    /* ------  inline ----*/

    .input-group-addon,
    .form-inline .input-group {
        display: table-cell;
    }

    .input-group-addon,
    .input-group-btn {
        width: inherit;
    }

    .input-group {
        width: 100%;
    }

    @media (min-width: 768px) {
        .form-inline .form-group {
            margin-top: 16px;
        }

        .input-group-btn,
        .input-group .form-control,
        .input-group-addon,
        .form-inline .input-group {
            display: inline-block;
        }

        .input-group {
            width: auto;
        }
    }

    /* -- bar styles -------------------------------------- */

    .form-bar {
        position: relative;
        display: block;
        width: 100%;
    }

    .form-bar:before,
    .form-bar:after {
        content: '';
        height: 1px;
        width: 0;
        bottom: 0;
        position: absolute;
        transition: 0.3s ease all;
        -moz-transition: 0.3s ease all;
        -webkit-transition: 0.3s ease all;
    }

    .form-bar:before {
        left: 50%;
    }

    .form-bar:after {
        right: 50%;
    }

    /* active state */

    .form-control:focus~.form-bar:before,
    .form-control:focus~.form-bar:after {
        width: 50%;
    }

    /* -- highlighter styles ------------------------------ */

    .form-highlight {
        position: absolute;
        height: 60%;
        width: 60px;
        top: 25%;
        left: 0;
        pointer-events: none;
        opacity: 0.4;
    }

    /* active state */

    .form-control:focus~.form-highlight {
        -webkit-animation: inputHighlighter 0.3s ease;
        -moz-animation: inputHighlighter 0.3s ease;
        animation: inputHighlighter 0.3s ease;
    }

    /* -- highlighter animation --------------------------- */

    @-webkit-keyframes inputHighlighter {
        from {
            background: #5264AE;
        }

        to {
            width: 0;
            background: transparent;
        }
    }

    @-moz-keyframes inputHighlighter {
        from {
            background: #5264AE;
        }

        to {
            width: 0;
            background: transparent;
        }
    }

    @keyframes inputHighlighter {
        from {
            background: #5264AE;
        }

        to {
            width: 0;
            background: transparent;
        }
    }

    /*-- Checkbox --------------------------- */

    // fork from https://codepen.io/jasonmayes/pen/wxtFr
    /* 
     * Core styles required for the checkboxes.
     * @author Jason Mayes 2014, www.jasonmayes.com
    */

    .form-group.checkbox {
        margin-top: 20px;
    }

    .checkbox input[type='checkbox'] {
        height: 0;
        width: 0;
        opacity: 0;
    }

    .checkbox label {
        font-size: 14px;
        font-weight: 300;
        line-height: 1;
        display: inline-block;
        overflow: hidden;
        position: relative;
        top: 10px;
        padding-left: 0;
    }

    .checkbox .chk-span {
        top: 0;
        border: 1px solid #5a5a5a;
        color: #1d1d1d;
        cursor: pointer;
        display: inline-block;
        float: left;
        height: 14px;
        margin: 0 14px 14px 1px;
        outline-color: #eaeaea;
        padding: 0;
        position: relative;
        width: 14px;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        z-index: 1;
    }

    .checkbox .chk-span.checked {
        top: -2px;
        border-left: 2px solid #03a9f4;
        border-bottom: 4px solid #03a9f4;
        border-top: 1px solid rgba(0, 0, 0, 0);
        border-right: 1px solid rgba(0, 0, 0, 0);
        -webkit-transform: rotate(-45deg) scaleY(0.5);
        -moz-transform: rotate(-45deg) scaleY(0.5);
        -ms-transform: rotate(-45deg) scaleY(0.5);
        -o-transform: rotate(-45deg) scaleY(0.5);
        transform: rotate(-45deg) scaleY(0.5);
    }

    // Checkbox colors
    .checkbox .chk-span.checked {
        border-left-color: #03a9f4;
        border-bottom-color: #03a9f4;
    }


    // focus state
    .checkbox input[type='checkbox']:focus~label {
        color: #03a9f4;

        .chk-span {
            border-color: #03a9f4;

            &.checked {
                border-left-color: #03a9f4;
                border-bottom-color: #03a9f4;
                border-top: 1px solid rgba(0, 0, 0, 0);
                border-right: 1px solid rgba(0, 0, 0, 0);
            }
        }
    }

    @media (min-width: 768px) {

        .form-inline .radio label,
        .form-inline .checkbox label {
            padding-left: 5px;
        }
    }

    .form-control-static {
        font-size: 16px;
    }

    .form-control[disabled],
    .form-control[readonly],
    fieldset[disabled] .form-control {
        background-color: transparent;
        border-bottom-style: dashed;

    }

    .form-control[disabled]~label,
    .form-control[readonly]~label {
        position: absolute;
        top: -18px;
        color: #b1adb7;
        font-size: 12px;
        font-weight: 300;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
        font-weight: bold;
    }

    #focusedInput {
        border-color: #ccc;
        border-color: rgba(82, 168, 236, .8);
        outline: 0;
        box-shadow: none;
    }
}
