.pub-genera-skin {
    @include box-branco;
    background-image: url('#{$path-image}/box.gif');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    padding: 65px 35px;

    h3 {
        @include texto-1;
        color: $cl-branco;
        font-size: 16px;
        font-weight: $fw-bold;
    }

    .box {
        @include box-branco;
        margin: 40px 0 0 0;

        h4 {
            @include texto-1;
            font-size: 16px;
            
        }

        p {
            @include texto-1;
            font-size: 12px;
        }

        .btn {
            padding: 8px 30px;
            &:hover {
                color: $cl-branco;
                background: $cl-cinza3;
            }
        }
    }
}
