.fade {
  @include transition($transition-fade);

  &:not(.show) {
    opacity: 0;
  }
}

.collapse {
  &:not(.show) {
    display: none;
    
    @include media-breakpoint-down(md) {
      display: none !important;
    }
    
    @include media-breakpoint-down(md) {
      display: none !important;
    }
  }
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  @include transition($transition-collapse);
}
