canvas {
    margin: 0!important;
    padding: 0!important;
  }
//Instagram Modals
.instagram-options {
    display: none;
}

.feed-await,
.story-await {
    background-color: rgba(0, 0, 0, 0.85);
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9999999;
    text-align: center;
    vertical-align: middle;
    justify-content: center;
    flex-direction: column;
    span {
        color: #fff;
        font-size: 18px;
        padding: 10px 15px 0;
        letter-spacing: -1px;
        font-weight: 600;
    }
    > div {
        background: #FFF;
        border-radius: 50%;
        margin: 0 auto;
        padding: .25rem;
        width: 2.5rem;
        .spinner-border {
            display: inherit;
            margin: 0% auto;
            color: red;
            background-color: #fff;
        }
    }
}

.modalInstagram {
    display: none;
    background: rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;

    .imgFeed,
    .imgStory {
        display: block;
        &::before {
            background:linear-gradient(102.54deg, #622A52 0%, #C73741 99.49%);
            content: '';
            width: 100%;
            height: 8px;
            position: absolute;
            top: 0;
        }
        .storyBtnMobile,
        .feedBtnMobile,
        .storyBtn,
        .feedBtn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            background: rgb(255, 255, 255);
            width: 100%;
            height: 30px;
            top: 0;
            left: 0;
            text-align: center;
            cursor: pointer;
            img {
                margin: 0 10px;
            }
            a {
                color: #4f4f4f;
                text-decoration: underline;
                &:hover {
                    color: #4f4f4f;
                }
            }
            p {
                font-weight: 600;
                margin: 0;
            }
            span {
                &.closeInstaModalFeed,
                &.closeInstaModalStory {
                    padding: 0 20px 0 10px;
                    right: 15px;
                    z-index: 9999999;
                    i {
                        margin-left: 10px;
                    }
                }
            }
        }
        @media screen and (max-width: 425px)  {
            .storyBtnMobile {
                img {
                    margin: 0;
                    padding: 0 10px 0 20px;
                }
                p {
                    font-size: 11px;
                }
            }
        }
    }
    .imgFeed {
        //background: url('#{$path-image}/instagram/insta-feed-genera-min.png') no-repeat center center!important;
        //background-size: contain!important;
        max-width: 539px;
        max-height: 539px;
        width: 100%;
        height: 539px;
        margin: 4% auto;
        position: relative;
        >img {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            z-index: -1;
        }
    }

    .imgStory {
        // background: url('#{$path-image}/instagram/insta-story-genera-min.png') no-repeat center center!important;
        // background-size: contain!important;
        position: relative;
        max-width: 397px;
        max-height: 705px;
        width: 100%;
        height: 100%;
        margin: 4% auto;
        @media screen and (max-width: 767px) and (min-width: 426px)  {
            margin: 0% auto;
        }
        @media screen and (max-width: 425px)  {
            margin: 0% auto;
            max-width: 320px;
            max-height: 568px;
        }
        >img {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            z-index: -1;
        }

        .box-image-txt {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            padding: 30px 0px 20px;
            .circle-progress {
                margin-top: 15px;
            }
            .progress {
                width: 150px;
                height: 150px;
                @media screen and (max-width: 425px)  {
                    width: 100px;
                    height: 100px;
                }
                .progress-value {
                    img {
                        width: 110px;
                        @media screen and (max-width: 425px)  {
                            width: 80%;
                        }
                    }
                    .initials {
                        width: 80%;
                        height: 80%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        @media screen and (max-width: 425px) {
                            width: 75%;
                            height: 75%;
                        }
                        p {
                            padding: 0;
                            font-size: 2.5rem;
                            margin: 0;
                            @media screen and (max-width: 425px) {
                                font-size: 2rem;
                            }
                        }
                    }
                }
            }
            .tootip-box-red {
                background: $cl-maximun-red;
                border-radius: 10px;
                width: 45%;
                height: 90px;
                display: flex;
                align-items: center;
                vertical-align: middle;
                text-align: center;
                color: #fff;
                padding: 0px;
                margin-top: 45px;
                @media screen and (max-width: 425px)  {
                    margin-top: 20px;
                    width: 50%;
                }
                &::before {
                    content: "";
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: -9px;
                    width: 0;
                    height: 0;
                    border-top: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                    border-right: 10px solid $cl-maximun-red;
                }
                p {
                    margin-top: 12px;
                    padding: 0px 20px;
                    font-weight: 600;
                    @media screen and (max-width: 425px)  {
                        font-size: 12px;
                        padding: 0px 10px;
                    }
                }
            }
        }

        .ancestral-maps {
            background: #fff;
            max-width: 370px;
            margin: 0 auto;
            border-radius: 10px;
            @media screen and (max-width: 425px)  {
                margin: 0% auto;
                max-width: 90%;
            }

            #mapa_ancestral {
                height: 210px;
                @media screen and (max-width: 425px)  {
                    height: 160px;
                }


                figure {
                    &.mapa {
                        width: 100% !important;
                        height: 100%!important;
                        right: 0!important;
                        top: 10%!important;
                        @media screen and (max-width: 425px)  {
                            width: 100% !important;
                            max-width: 300px;
                            height: auto!important;
                            right: 0 !important;
                            top: 10% !important;
                            margin: 0;
                        }
                    }
                }
            }
            
        }

        .regions-insta {
            .accordion {
                height: auto;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
            }
            .card {
                background: none;
                border: none;
                width: 50%;
                width: 80%;
                margin: auto;
                .card-header {
                    background-color: transparent;
                    border: none;
                    position: relative;
                    width: 100%;
                    @media screen and (max-width: 425px)  {
                        padding-top: 5px;
                        padding-bottom: 5px;
                    }
                    h3 {
                        font-style: normal;
                        font-weight: bold;
                        color: #FFFFFF;
                        font-size: 14px;
                    }
                    figure {
                        display: none;
                    }
                    .row-1 {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        .box-rigth {
                            h3 {
                                position: absolute;
                                right: 1.55rem;
                                bottom: 0.42em;
                                @media screen and (max-width: 425px)  {
                                    bottom: 0;
                                }
                            }
                        }
                    }
                    .row-2 {
                        .progress {
                            background: #212121;
                        }
                    }
                }
                .collapse {
                    display: none;
                }
            }
        }

        .txt-brand-genera {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0.75rem 1.25rem;
            p {
                color: #fff;
                margin: 10px 0;
                @media screen and (max-width: 425px)  {
                    font-size: 11px;
                }
            }
            img {
                width: 95px;
                height: 35px;
            }
        }
    }
    .imgInstaDownload {
        max-width: 400px;
        max-height: 708px;
        width: 100%;
        height: 100%;
        margin: 4% auto;
        display: none;
        justify-content: center;
        align-items: center;
    }
    .imgFeed {
        position: relative;
        @media screen and  (max-width: 767px) and (min-width: 426px)  {
            height: 425px;
            width: 425px;
        }
        @media screen and (max-width: 425px) {
            height: 375px;
            width: 375px;
        }

        .flexrow {
            display: flex;
            flex-direction: row;

            .box-image-txt {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                width: 50%;
                height: 420px;
                margin-top: 40px;
                @media screen and (max-width: 767px) and (min-width: 426px) {
                    height: 330px;
                }
                @media screen and (max-width: 425px) {
                    height: 250px;
                }
                .circle-progress {
                    margin-top: 24px;
                    @media screen and (max-width: 425px) {
                        margin-top: 0;
                    }
                }

                .progress {
                    width: 165px;
                    height: 165px;
                    @media screen and (max-width: 767px) and (min-width: 426px) {
                        width: 150px;
                        height: 150px;
                    }
                    @media screen and (max-width: 425px) {
                        width: 110px;
                        height: 110px;
                    }
                    .progress-value {
                        img {
                            width: 80%;
                        }
                        .initials {
                            width: 80%;
                            height: 80%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            @media screen and (max-width: 425px) {
                                width: 75%;
                                height: 75%;
                            }
                            p {
                                padding: 0;
                                font-size: 2.5rem;
                                margin: 0;
                                @media screen and (max-width: 425px) {
                                    font-size: 2rem;
                                }
                            }
                        }
                    }
                    .progress-right {
                        .progress-bar {
                            border-top-left-radius: 110px;
                            border-bottom-left-radius: 110px;
                        }
                    }
                }
                .tootip-box-red {
                    background: $cl-maximun-red;
                    border-radius: 10px;
                    width: 85%;
                    height: 120px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    vertical-align: middle;
                    text-align: center;
                    color: #fff;
                    padding: 0;
                    margin: auto;
                    @media screen and (max-width: 425px) {
                        margin-top: 20px;
                        height: 90px;
                        p {
                            margin-top: 10px;
                            font-size: 12px;
                        }
                    }
                    &::before {
                        content: "";
                        display: inline-block;
                        vertical-align: middle;
                        margin-top: -10px;
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 10px solid $cl-maximun-red;
                    }
                    p {
                        margin-top: 12px;
                        padding: 10px 10px;
                        font-size: 16px;
                        font-weight: 600;
                        @media screen and (max-width: 425px) {
                            margin-top: 10px;
                            font-size: 12px;
                        }
                    }
                }
            }
            .regions-insta {
                width: 50%;
                margin-top: 40px;
                .accordion {
                    height: 250px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                }
                .card {
                    background: none;
                    border: none;
                    width: 100%;
                    .card-header {
                        background-color: transparent;
                        border: none;
                        position: relative;
                        @media screen and (max-width: 425px) {
                            padding: 5px 15px;
                        }
                        h3 {
                            font-style: normal;
                            font-weight: bold;
                            color: #FFFFFF;
                            font-size: 14px;
                            @media screen and (max-width: 425px) {
                                font-size: 12px;
                            }
                        }
                        figure {
                            display: none;
                        }
                        .row-1 {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            @media screen and (max-width: 425px) {
                                height: 16px;
                            }
                            .box-rigth {
                                h3 {
                                    position: absolute;
                                    right: 1.55rem;
                                    bottom: 0.42em;
                                    @media screen and (max-width: 425px) {
                                        bottom: 0.0em;
                                    }
                                }
                            }
                        }
                        .row-2 {
                            .progress {
                                background: #212121;
                            }
                        }
                    }
                    .collapse {
                        display: none;
                    }
                }
            }
        }
        .txt-brand-genera {
            position: absolute;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            padding: 20px 30px;
            left: 0;
            bottom: 0;
            @media screen and (max-width: 425px) {
                padding: 20px 20px;
            }
            p {
                color: #fff;
                margin: 10px 0;
            }
            img {
                width: 117px;
                height: 40px;
            }
        }
    }
}

.menu-category {
    &.ancestry {
        a {
            font-family: $ff-mulish;
            text-decoration: none;
            cursor: auto;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: 0.25px;
            color: #333333;
        }
    }
}

._partial-exame-menu {
    background-size: cover;
    background-position: center;
    position: relative;
    margin-bottom: 24px;

    &:last-child {
        margin: 0 0 0;
    }

    @include border-radius;
    @include transition;

    a {
        font-family: 'Mulish';
        font-style: normal;
        font-weight: bold;
        font-size: $fourteen-px;
        line-height: $eighteen-px;
        display: flex;
        align-items: center;
        color: $graydark-genera;
        text-decoration: none;
        padding-left: 1.3rem;

        &:last-child {
            margin-bottom: 0;
        }

        &:before {
            content: '';
            position: absolute;
            opacity: 1;
            background: #ABABAB;
            left: 0;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            z-index: 0;
        }

        &._active {
            color: $graydark-genera;

            &:before {
                opacity: 1;
                background: $red-genera;
                width: 12px;
                height: 12px;
                margin: 5px 8px 5px 0;
            }
        }

        &.menu-main-category {
            &:before {
                content: '';
                width: 0;
                height: 0;
            }
        }
    }
    &._pending {
        filter: grayscale(100%);

        a {
            color: $cl-silver-chalice;
            cursor: default;
            opacity: 0.6;
        }

        &:before {
            color: $cl-silver-chalice;
            opacity: 0.6;
        }
    }

    &.menu-main-category {
        line-height: 25px;
        letter-spacing: 0.25px;
        color: $graydark-genera;

        > a {
            cursor: default;
            font-family: $ff-mulish;
            font-style: normal;
            font-weight: bold !important;
            font-size: $twenty-px;
            padding-left: 0px;
            line-height: $twenty-five-px;
        }

        &:before {
            content: '';
            width: 0;
            height: 0;
        }
    }
}

.coluna-2 {
    ._partial-exame-menu{
        a{
            &._active {
                &:before {
                    opacity: 1;
                    background: $cl-europa;
                    width: 12px;
                    height: 12px;
                    margin: 5px 8px 5px 0;
                }
            }
        }
    }
}

._partial-perfil-ances {
    .gen-card-body {
        flex-direction: row;
        
        @include media-breakpoint-down(md) {
            flex-direction: column;
            align-items: flex-start;
            margin-right: 0;
        }

        @include media-breakpoint-down(lg) {
            flex-direction: column;
            align-items: flex-start;
            margin-right: 0;
        }
    }

    &.share {
        .row-1 {
            margin: 0;
        }

        .ances-tooltip {
            display: none;
        }
    }

    .row-1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 16px;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            align-items: flex-start;
            margin-right: 0;
        }

        @include media-breakpoint-down(lg) {
            flex-direction: column;
            align-items: flex-start;
            margin-right: 0;
        }

        h3 {
            &.ances {
                --text-weight: 600;
                line-height: $twenty-eight-px;
            }
        }

        #title-ancestry {
            @include media-breakpoint-down(md) {
                display: none;
            }

            @include media-breakpoint-down(lg) {
                display: none;
            }
        }

        #title-ancestry-mobile {
            display: none;
            margin: 16px 0 0;

            @include media-breakpoint-down(md) {
                display: flex;
                align-items: center;
            }

            @include media-breakpoint-down(lg) {
                display: flex;
                align-items: center;
            }
        }

        figure {
            border: 10px solid $cl-cinza2;
            border-radius: 50%;
            margin: 0 20px 0 0;
            position: relative;

            &::after,
            &::before {
                content: '';
                border-radius: 50%;
                border: 10px solid $cl-maximun-red;
                position: absolute;
                width: 120px;
                height: 120px;
                top: -10px;
                left: -10px;
                right: 0;
                margin: 0 auto;
            }

            &.porc-70 {
                &::after {
                    clip: rect(0px, 108px, 58px, 0px);
                }

                &::before {
                    clip: rect(0px, 127px, 47px, 0px);
                    transform: rotateZ(60deg);
                }
            }

            img {
                width: 80px;
                border-radius: 50%;
                margin: 10px;
            }

        }
    }
    .row-2 {
        &.m-bottom {
            @media (max-width: 991px) {
                margin-top: 15px;
            }
        }
        h3 {
            &.ances {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0;
                
                h3 {
                    line-height: $twenty-five-px;
                    text-align: left;
                }

                @include media-breakpoint-down(md) {
                    display: none;
                }

                @include media-breakpoint-down(lg) {
                    display: none;
                }
            }
        }
        p {
            word-break: break-word;

            &.ancestry-description {
                line-height: $twenty-four-px;
                --text-color: $cl-gray-jet;
            }
        }
    }

    .ances-tooltip {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #E1E1E1;
        color: $cl-gray-davys;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: .5px;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        margin: 0 0 0 16px;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            background: #D1D1D1;
            color: #393939;
            text-decoration: none;
        }
    }
}

._partial-perfil-linhagem {
    min-height: calc(100% - 10px);

    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: stretch;
        flex-direction: column;
        margin: 0;
    }

    @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: stretch;
        flex-direction: column;
        margin: 0;
    }

    .box {
        @include box-branco;
        display: flex;
        flex-direction: column;
        margin: 0;
    }

    &.card-mobile {
        .gen-card-body {
            @include media-breakpoint-down(md) {
                padding: 0;
            }
        
            @include media-breakpoint-down(lg) {
                padding: 0;
            }
        }
    }

    .haplogrupo {
        border-radius: 6px;
        background: $cl-maximun-red;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        color: #FFFFFF;
        font-size: 30px;
        font-weight: 600;
        height: 56px;
        line-height: 56px;
        margin: 15px 0;
        text-align: center;
    }

    ._partial-share {
        margin-top: auto;
    }

    figure {
        img {
            width: 100%;
            @include border-radius;
        }

        @include media-breakpoint-up(lg) {
            margin: 15px 0 0;
        }
    }

    footer {
        margin-top: auto;
    }
}

._partial-regiao-collapse {
    position: relative;
    margin: 0;

    .card {
        border: none;
        background: none;
        margin: 0 0 15px 0;

        &:last-child {
            margin: 0;
        }

        .card-header {
            padding: 0;
            border: none;
            margin: 0 0 20px 0;
            background: none;

            .row-1 {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 0 0 6px 0;

                h3 {
                    display: inline-flex;
                    max-width: 180px;
                    font-size: 20px;
                    color: #4f4f4f;
                    font-weight: $fw-bold;
                    text-decoration: none solid rgb(79, 79, 79);
                    margin: 0;

                    @include transition;

                    &:hover {
                        color: $cl-maximun-red;
                    }
                }

                .box-left {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    &:hover {
                        cursor: pointer;
                    }

                    &.collapsed {
                        figure {
                            img {
                                transform: rotateZ(0deg);
                                transition: all 300ms ease-in-out;
                            }
                        }

                        ~.box-rigth {
                            opacity: 1;
                            transition: all 200ms ease-out;
                        }
                    }

                    figure {
                        img {
                            transform: rotateZ(90deg);
                            width: 28px;
                            height: 28px;
                            transition: all 300ms ease-in-out;
                        }
                    }
                }

                .box-rigth {
                    transition: all 200ms ease-out;
                }

            }
        }

        .card-body {
            padding: 0;
        }
    }
}



._partial-subregiao {
    margin: 0 0 15px 0;

    .microregion {
        display: none;
    }

    &:hover,
    &.active {
        cursor: pointer;

        .row-1 {
            h4 {
                transition: all 200ms ease;
                color: $cl-maximun-red;
            }
        }
    }

   .row-1 {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
            font-size: 14px;
            color: #565656;
            font-weight: $fw-bold;
            text-decoration: none solid rgb(79, 79, 79);
            margin: 0 0 10px 0;
            transition: all 300ms ease;

            &.porcent {
                font-size: 14px;
            }
        }

        .box-left {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            figure {
                img {
                    width: 28px;
                }
            }

            &:hover {
                cursor: pointer;
            }

            .arrow-conteudo {
                border-radius: 6px;
                padding: 2px 8px;
                margin: -10px 0 0 0;
                line-height: 1;

                img {
                    width: 12px;
                    height: 12px;
                    margin: -2px 0 0 0;
                }
            }
        }
    }

    .progress {
        height: 8px;

        .progress-bar {
            position: relative;
            box-shadow: none!important;
        }
    }
}

#accordion_regiaored {
    .card {
        .collapse.show {
            ._partial-subregiao {
                .microregion {
                    display: block;
                    background: #F7F7F7;
                    border-radius: 0px;
        
                    ul {
                        padding: 0;
                        margin: 0;
                        cursor: initial;
                        li {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 16px;
                            padding: 0 16px;

                            &:last-child {
                                margin-bottom: 0;
                                padding-bottom: 16px;
                            }

                            &:first-child {
                                padding-top: 16px;
                            }

                            div {
                                font-family: Mulish;
                                font-style: normal;
                                font-weight: bold;
                                font-size: 14px;
                                line-height: 18px;
                                letter-spacing: 0.5px;
                                color: #565656;

                                &:first-of-type {
                                    width: 80%;
                                    margin-right: 24px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

._partial-mapa {
    border-radius: 0;
    height: 600px;
    overflow: hidden;
    margin-top: -140px;
    padding: 0;
    position: relative;

    @media (max-width: 1200px) {
        height: 400px;
    }

    &._show {
        background: none;
        transition: all 1200ms ease 200ms;

        @media (max-width: 1200px) {
            background: $cl-silver-cultured;
        }
    }

    &.anim {
        opacity: .2;
        transition: all 300ms ease-out;
    }

    &:hover {
        cursor: grab;
    }

    &:active {
        cursor: grabbing;
    }

    .mapa {
        margin: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;

        &._loaded {
            transition: opacity 750ms ease;
            opacity: 1;
        }

        &._started {
            transition: all 750ms ease;
        }

        &.ui-draggable-dragging {
            transition: none;
        }

        @media (max-width: 1200px) {
            margin: 0 0 0 20px;
        }

        img {
            width: 100%;
            height: 100%;
            position: relative;
        }

        text {
            &._show-in {
                opacity: 0;
                transition: all 600ms ease;
            }
        }

        .cartesiano {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;

            .pin {
                transition: all 1200ms ease-in-out;

                position: absolute;
                border-radius: 50%;

                &:after,
                &:before {
                    display: none;
                    content: '';
                    position: absolute;
                    border-radius: 50%;
                }

                &._ponto {
                    width: 1px;
                    height: 1px;
                    top: 28.1%;
                    left: 45.9%;
                    box-shadow: 1px 1px 60px 60px $cl-maximun-red;
                }

                &._african {
                    color: $cl-africa;
                    background: $cl-africa;
                }

                &._centralsouth-asian {
                    color: $cl-lesteasiatico;
                    background: $cl-lesteasiatico;
                }

                &._east-asian {
                    color: $cl-lesteasiatico;
                    background: $cl-lesteasiatico;
                }

                &._european {
                    color: $cl-europa;
                    background: $cl-europa;
                }

                &._jewish-diaspora {
                    color: $cl-judaica;
                    background: $cl-judaica;
                }

                &._middle-eastern {
                    color: $cl-orientemedio;
                    background: $cl-orientemedio;
                }

                &._new-world {
                    color: $cl-americas;
                    background: $cl-americas;
                }

                &._trace-results {
                    color: $cl-outros;
                    background: $cl-outros;
                }

                &._trace-results {}

                &._cluster {
                    box-shadow: 1px 1px 30px 40px;

                    &.__ashkenazi {
                        width: 0px;
                        height: 0px;
                        top: 33.4%;
                        left: 52%;
                        box-shadow: 1px 1px 40px 30px;

                        &:before {
                            display: none;
                        }
                    }

                    &.__asia-minor {
                        width: 0px;
                        height: 0px;
                        top: 41.9%;
                        left: 56%;
                        box-shadow: 1px 1px 27px 27px;
                    }

                    &.__british-isles {
                        width: 0px;
                        height: 0px;
                        top: 32.7%;
                        left: 46.4%;
                        box-shadow: 1px 1px 20px 20px;
                    }

                    &.__central-asia {
                        width: 0px;
                        height: 0px;
                        top: 45%;
                        left: 63.5%;
                        box-shadow: 1px 1px 25px 25px;
                    }

                    &.__east-central-africa {
                        width: 0px;
                        height: 40px;
                        top: 60%;
                        left: 55.6%;
                        box-shadow: 1px 1px 20px 20px;
                    }

                    &.__east-europe {
                        width: 0px;
                        height: 0px;
                        top: 37%;
                        left: 51.9%;
                        box-shadow: 1px 1px 30px 20px;
                    }

                    &.__finland {
                        width: 0px;
                        height: 10px;
                        top: 22.6%;
                        left: 54.1%;
                        box-shadow: 1px 1px 20px 20px;
                    }

                    &.__iberia {
                        width: 0px;
                        height: 0px;
                        top: 40.9%;
                        left: 45.8%;
                        box-shadow: 1px 1px 20px 20px;
                    }

                    &.__north-africa {
                        width: 10px;
                        height: 0px;
                        top: 45.8%;
                        left: 46.8%;
                        box-shadow: 1px 1px 20px 20px;
                    }

                    &.__north-and-central-america {
                        width: 1px;
                        height: 70px;
                        top: 31.3%;
                        left: 15%;
                        box-shadow: 1px 1px 25px 25px;
                        transform: rotateZ(-22deg);

                        &:before {
                            display: block;
                            width: 1px;
                            height: 70px;
                            top: 142.3%;
                            left: 5.1%;
                            box-shadow: 11px 1px 25px 25px;
                            transform: rotateZ(-26deg);
                        }
                    }

                    &.__northeast-asia {
                        width: 10px;
                        height: 0px;
                        top: 40.9%;
                        left: 77.5%;
                        box-shadow: 1px 1px 26px 23px;
                    }

                    &.__oceania {
                        width: 30px;
                        height: 0px;
                        top: 70%;
                        left: 82.4%;
                        box-shadow: 1px 1px 38px 35px;
                        transform: rotateZ(35deg);
                    }

                    &.__scandinavia {
                        width: 1px;
                        height: 0px;
                        top: 27%;
                        left: 49.8%;
                        box-shadow: 1px 1px 15px 15px;
                    }

                    &.__sephardic {
                        width: 0px;
                        height: 0px;
                        top: 40.9%;
                        left: 45.8%;
                        box-shadow: 1px 1px 20px 20px;
                    }

                    &.__siberia {
                        width: 20px;
                        height: 1px;
                        top: 26%;
                        left: 83%;
                        box-shadow: 1px 1px 30px 30px;
                    }

                    &.__south-america {
                        width: 1px;
                        height: 40px;
                        top: 63.4%;
                        left: 29.6%;
                        box-shadow: 1px 1px 50px 50px;
                    }

                    &.__south-central-africa {
                        width: 1px;
                        height: 40px;
                        top: 63.4%;
                        left: 29.6%;
                        box-shadow: 1px 1px 50px 50px;
                    }

                    &.__south-central-asia {
                        width: 1px;
                        height: 10px;
                        top: 55.7%;
                        left: 66.8%;
                        box-shadow: 1px 1px 20px 20px;
                    }

                    &.__southeast-asia {
                        width: 1px;
                        height: 1px;
                        top: 56.7%;
                        left: 74.8%;
                        box-shadow: 1px 1px 40px 50px;
                    }

                    &.__southeast-europe {
                        width: 25px;
                        height: 1px;
                        top: 41.2%;
                        left: 49.8%;
                        box-shadow: 1px 1px 20px 22px;
                    }

                    &.__west-africa {
                        width: 20px;
                        height: 40px;
                        top: 59.4%;
                        left: 50.3%;
                        box-shadow: 1px 1px 30px 30px;
                        transform: rotateZ(-30deg);

                        &:before {
                            display: block;
                            width: 1px;
                            height: 40px;
                            box-shadow: 20px 80px 27px 30px;
                            transform: rotateZ(-2deg);
                        }
                    }

                    &.__west-and-central-europe {
                        width: 1px;
                        height: 1px;
                        top: 35.8%;
                        left: 48.4%;
                        box-shadow: 1px 1px 20px 26px;
                    }

                    &.__west-middle-east {
                        width: 1px;
                        height: 1px;
                        top: 44.6%;
                        left: 56%;
                        box-shadow: 1px 1px 20px 26px;
                    }
                }
            }
        }

        &._mapa-zoom {
            //width: 4500px;

            .pin {
                transition: all 400ms ease-in-out 1100ms;

                &._cluster {
                    box-shadow: 1px 1px 30px 40px;

                    &.__ashkenazi {
                        width: 0px;
                        height: 20px;
                        top: 33.4%;
                        left: 52%;
                        box-shadow: 1px 1px 50px 60px;

                        &:before {
                            width: 1px;
                            height: 1px;
                            box-shadow: 60px 20px 50px 50px;
                        }
                    }

                    &.__asia-minor {
                        width: 0px;
                        height: 10px;
                        top: 42%;
                        left: 55.5%;
                        box-shadow: 1px 1px 50px 55px;

                        &:before {
                            display: block;
                            width: 1px;
                            height: 1px;
                            box-shadow: 60px 1px 50px 55px;
                        }
                    }

                    &.__british-isles {
                        width: 0px;
                        height: 0px;
                        top: 32.2%;
                        left: 46%;
                        box-shadow: 1px 1px 40px 40px;

                        &:before {
                            display: block;
                            width: 1px;
                            height: 1px;
                            box-shadow: 30px 40px 40px 40px;
                        }
                    }

                    &.__central-asia {
                        width: 0px;
                        height: 0px;
                        top: 45.5%;
                        left: 63.6%;
                        box-shadow: 1px 1px 50px 50px;
                    }

                    &.__east-central-africa {
                        width: 0px;
                        height: 20px;
                        top: 59.8%;
                        left: 55.5%;
                        box-shadow: 1px 1px 70px 70px;

                        &:before {
                            display: block;
                            width: 1px;
                            height: 1px;
                            box-shadow: 10px 130px 70px 70px;
                        }
                    }

                    &.__east-europe {
                        width: 0px;
                        height: 0px;
                        top: 37%;
                        left: 51.9%;
                        box-shadow: 1px 1px 80px 80px;

                        &:before {
                            display: block;
                            width: 1px;
                            height: 1px;
                            box-shadow: 60px 10px 80px 80px;
                        }
                    }

                    &.__finland {
                        width: 0px;
                        height: 0px;
                        top: 22.7%;
                        left: 54.1%;
                        box-shadow: 1px 1px 50px 50px;

                        &:before {
                            display: block;
                            width: 1px;
                            height: 1px;
                            box-shadow: 0px 60px 50px 50px;
                        }
                    }

                    &.__iberia {
                        width: 0px;
                        height: 0px;
                        top: 40.9%;
                        left: 45.5%;
                        box-shadow: 1px 1px 40px 40px;

                        &:before {
                            display: block;
                            width: 1px;
                            height: 1px;
                            box-shadow: 40px 0px 50px 50px;
                        }
                    }

                    &.__north-africa {
                        width: 10px;
                        height: 0px;
                        top: 45.7%;
                        left: 46.7%;
                        box-shadow: 1px 1px 50px 50px;

                        &:before {
                            display: block;
                            width: 1px;
                            height: 1px;
                            box-shadow: 70px 0px 50px 50px;
                        }
                    }

                    &.__north-and-central-america {
                        width: 1px;
                        height: 250px;
                        top: 31.3%;
                        left: 15.5%;
                        box-shadow: 1px 1px 70px 70px;
                        transform: rotateZ(-24deg);

                        &:before {
                            display: block;
                            width: 0px;
                            height: 180px;
                            top: 118.3%;
                            left: 56.1%;
                            box-shadow: 20px 0px 70px 70px;
                            transform: rotateZ(-24deg);
                        }
                    }

                    &.__northeast-asia {
                        width: 50px;
                        height: 0px;
                        top: 40%;
                        left: 77%;
                        box-shadow: 1px 1px 50px 50px;

                        &:before {
                            display: block;
                            width: 0px;
                            height: 0px;
                            box-shadow: 130px -60px 70px 70px;
                        }

                        &:after {
                            display: block;
                            width: 0px;
                            height: 0px;
                            box-shadow: -80px -20px 100px 70px;
                        }
                    }

                    &.__oceania {
                        width: 30px;
                        height: 1px;
                        top: 68.7%;
                        left: 82.2%;
                        box-shadow: 1px 1px 80px 80px;
                        transform: rotateZ(35deg);

                        &:before {
                            display: block;
                            width: 1px;
                            height: 1px;
                            box-shadow: 70px 0px 50px 50px;
                        }
                    }

                    &.__scandinavia {
                        width: 1px;
                        height: 11px;
                        top: 27.5%;
                        left: 49.6%;
                        box-shadow: 1px 1px 50px 50px;
                    }

                    &.__sephardic {
                        width: 0px;
                        height: 0px;
                        top: 40.9%;
                        left: 45.5%;
                        box-shadow: 1px 1px 40px 40px;

                        &:before {
                            display: block;
                            width: 1px;
                            height: 1px;
                            box-shadow: 40px 0px 50px 50px;
                        }
                    }

                    &.__siberia {
                        width: 50px;
                        height: 1px;
                        top: 28%;
                        left: 82%;
                        box-shadow: 1px 1px 90px 100px;
                    }

                    &.__south-america {
                        width: 10px;
                        height: 0px;
                        top: 65.4%;
                        left: 31%;
                        box-shadow: 1px 1px 90px 80px;

                        &:before {
                            display: block;
                            width: 1px;
                            height: 1px;
                            box-shadow: -40px 150px 110px 110px;
                        }

                        &:after {
                            display: block;
                            width: 1px;
                            height: 1px;
                            box-shadow: -110px -50px 110px 120px;
                        }
                    }

                    &.__south-central-africa {
                        width: 10px;
                        height: 0px;
                        top: 65.4%;
                        left: 31%;
                        box-shadow: 1px 1px 90px 80px;

                        &:before {
                            display: block;
                            width: 1px;
                            height: 1px;
                            box-shadow: -40px 150px 110px 110px;
                        }
                    }

                    &.__south-central-asia {
                        width: 1px;
                        height: 1px;
                        top: 56.9%;
                        left: 67.1%;
                        box-shadow: 1px 1px 60px 60px;
                    }

                    &.__southeast-asia {
                        width: 1px;
                        height: 1px;
                        top: 57.1%;
                        left: 74%;
                        box-shadow: 1px 1px 60px 60px;

                        &:before {
                            display: block;
                            width: 1px;
                            height: 1px;
                            box-shadow: 70px 40px 100px 120px;
                        }
                    }

                    &.__southeast-europe {
                        width: 25px;
                        height: 22px;
                        top: 40.4%;
                        left: 50.4%;
                        box-shadow: 1px 1px 30px 30px;

                        &:before {
                            display: block;
                            width: 1px;
                            height: 1px;
                            box-shadow: -40px 10px 30px 38px;
                        }
                    }

                    &.__west-africa {
                        width: 20px;
                        height: 140px;
                        top: 62.4%;
                        left: 50.8%;
                        box-shadow: 1px 1px 60px 60px;
                        -webkit-transform: rotateZ(-30deg);
                        transform: rotateZ(-30deg);

                        &:before {
                            display: block;
                            width: 1px;
                            height: 110px;
                            box-shadow: 40px 210px 60px 60px;
                            transform: rotateZ(-2deg);
                        }
                    }

                    &.__west-and-central-europe {
                        width: 25px;
                        height: 22px;
                        top: 35.5%;
                        left: 48.6%;
                        box-shadow: 1px 1px 50px 70px;
                    }

                    &.__west-middle-east {
                        width: 1px;
                        height: 52px;
                        top: 46.5%;
                        left: 55.6%;
                        box-shadow: 1px 1px 40px 60px;
                        transform: rotateZ(31deg);
                    }
                }
            }

            text {
                &._show-out {
                    opacity: 0;
                    transition: all 600ms ease;
                }

                &._show-in {
                    opacity: 1;
                    transition: all 600ms ease;
                }
            }
        }
    }
}

._partial-mapa-svg {
    text {
        font-family: $ff-mulish !important;
        font-weight: $fw-semibold;
    }

    .font-4,
    .font-3,
    .font-2 {
        //display: none;
        opacity: 0;

        &._show-out {
            display: none;
            opacity: 0;
            font-size: 14px !important;
            transition: all 600ms ease;
        }

        &._show-in {
            font-size: 3.8px !important;
        }
    }

    &._zoom-0 {
        .font-4,
        .font-3,
        .font-2 {
            &._show-in {
                font-size: 16px !important;
            }
        }
    }

    &._zoom-1 {
        .font-4,
        .font-3,
        .font-2 {
            &._show-in {
                font-size: 8px !important;
            }
        }
    }

    &._zoom-2 {
        .font-4,
        .font-3,
        .font-2 {
            &._show-in {
                font-size: 5.5px !important;
            }
        }
    }

    .font-4 {}

    #areas {
        >g {
            opacity: 0;
        }

        #Oriente_Médio_Oriental__x28_East_Middle_East_x29__1_ {
            path {
                fill: $cl-orientemedio !important;
            }
        }

        #Sefardita__x28_Sefardic_x29__1_ {
            path {
                fill: $cl-judaica !important;
            }
        }

        #Asquenaze__x28_Ashkenazi_x29__1_ {
            path {
                fill: $cl-judaica !important;
            }
        }

        #Ilhas_Britânicas__x28_British_Isles_x29__1_ {
            path {
                fill: $cl-europa !important;
            }
        }

        #Europa_Ocidental_e_Central__x28_West_and_Central_Europe_x29__1_ {
            path {
                fill: $cl-europa !important;
            }
        }

        #África_Central_Oriental__x28_East_Central_Africa_x29__1_ {
            path {
                fill: $cl-africa !important;
            }
        }

        #Leste_Europeu__x28_East_Europe_x29__2_ {
            path {
                fill: $cl-europa !important;
            }
        }

        #Finlândia__x28_Finland_x29__1_ {
            path {
                fill: $cl-europa !important;
            }
        }

        #_x2A_Native_American_1_ {
            path {
                fill: $cl-americas !important;
            }
        }

        #Norte_da_África__x28_North_Africa_x29__1_ {
            path {
                fill: $cl-orientemedio !important;
            }
        }

        #Nordeste_da_Ásia__x28_Northeast_Asia_x29__1_ {
            path {
                fill: $cl-lesteasiatico !important;
            }
        }

        #Escandinávia__x28_Scandinavia_x29__1_ {
            path {
                fill: $cl-europa !important;
            }
        }

        #Ásia_Central__x28_Central_Asia_x29__1_ {
            path {
                fill: $cl-lesteasiatico !important;
            }
        }

        #Ásia_Menor__x28_Asia_Minor_x29__1_ {
            path {
                fill: $cl-lesteasiatico !important;
            }
        }

        #África_Central_do_Sul__x28_South_Central_Africa_x29__1_ {
            path {
                fill: $cl-africa !important;
            }
        }

        #Sudeste_Asiático__x28_Southeast_Asia_x29__1_ {
            path {
                fill: $cl-lesteasiatico !important;
            }
        }

        #_x2A_Southern_Europe_1_ {
            path {
                fill: $cl-europa !important;
            }
        }

        #Ásia_Central_do_Sul__x28_South_Central_Asia_x29__1_ {
            path {
                fill: $cl-lesteasiatico !important;
            }
        }

        #África_Ocidental__x28_West_Africa_x29__2_ {
            path {
                fill: $cl-africa !important;
            }
        }

        #Península_Ibérica__x28_Iberia_x29__2_ {
            path {
                fill: $cl-europa !important;
            }
        }

        #Sudeste_Europeu__x28_Southeast_Europe_x29__1_ {
            path {
                fill: $cl-europa !important;
            }
        }

        #Oriente_Médio_Ocidental__x28_West_Middle_East_x29__1_ {
            path {
                fill: $cl-orientemedio !important;
            }
        }

        #Sibéria__x28_Siberia_x29__1_ {
            path {
                fill: $cl-lesteasiatico !important;
            }
        }

        #Oceania__x28_Oceania_x29__1_ {
            path {
                fill: $cl-lesteasiatico !important;
            }
        }

        #América_do_Norte_e_Central__x28_North_and_Central_America_x29__1_ {
            path {
                fill: $cl-americas !important;
            }
        }

        #América_do_Sul__x28_South_America_x29__1_ {
            path {
                fill: $cl-americas !important;
            }
        }
    }

}

.storyBtnMobile,
.feedBtnMobile,
.storyBtn,
.feedBtn {
    display: none;
}
._partial-share {
    display: flex;
    flex-direction: row;
    margin: 0;
    @include media-breakpoint-down(md) {
        flex-direction: column;
    }

    .share-results {
        width: 100%;
        word-break: inherit;
        margin: 3px 0;

    }

    h5 {
        @include texto-1;
        color: $cl-cinza3;
        font-size: 12px;
        margin-bottom: 5px;
        line-height: 30px;
    }

    .share {    
        margin-left: 15px;
        width: auto;
        @include media-breakpoint-down(md) {
            margin-left: 0px;
        }

        .justify-content-around {
            justify-content: start!important;
        }

        .at-share-btn-elements {
            display: flex;
            justify-content: space-between;
            padding: 0 10px;
        }

        .at-resp-share-element .at-share-btn {
            background: none !important;
            margin: 0;
            padding: 7px;

            .at-icon {
                transition: all 300ms ease;
            }

            &:hover {
                transform: translateY(0px) !important;

                .at-icon {
                    transition: all 300ms ease;
                    fill: #393939 !important;
                }
            }
        }

        a {
            padding: 0px;
            transition: all 300ms ease;
            margin-right: 16px;
            
            &:last-child {
                margin-right: 0;
            }

            &.share-facebook {
                color: #fff;
                border-radius: 100%;
                width: 24px;
                height: 24px;
                text-align: center;
                opacity: 1;

                &:hover {
                    opacity: 0.85;
                }
            }

            &.share-twitter {
                border-radius: 100%;
                color: #fff;
                border-radius: 100%;
                width: 24px;
                height: 24px;
                text-align: center;
                opacity: 1;

                &:hover {
                    opacity: 0.85;
                }
            }

            &.share-instagram {
                background-color: #F56F77;
                color: #fff;
                border-radius: 100%;
                width: 24px;
                height: 24px;
                margin-top: 0px;
                text-align: center;
                opacity: 1;

                &:hover {
                    opacity: 0.85;
                }
                i {
                    font-size: 27px;
                    line-height: 40px;
                }
            }

            &.share-whatsapp {
                color: #fff;
                border-radius: 100%;
                width: 24px;
                height: 24px;
                text-align: center;
                opacity: 1;

                &:hover {
                    opacity: 0.85;
                }
            }

            &.share-copy {
                color: #fff;
                border-radius: 100%;
                width: 24px;
                height: 24px;
                text-align: center;
                opacity: 1;

                &:hover {
                    opacity: 0.85;
                }
            }

            i {
                font-size: 15px;
                line-height: 40px;
            }

        }
        .hide-share-btns {
            display: none!important;
        }
        .instagram-box {
            display: none;
            background: #EDEDED;
            border-radius: 20px;
            max-height: 40px;
            justify-content: space-between;
            margin-left: 50px;
            opacity: 0;
            .img-download-box {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                .story-download,
                .feed-download {
                    display: flex;
                    flex-direction: row;
                    text-decoration: none;
                    p {
                        color: #333333;
                        font-weight: 600;
                        margin: 0 5px;;
                    }
                    img {
                        height: 18px;
                    }
                }
            }
            .close-share-instagram {
                color: #333333;
                margin-right: 10px;
            }
        }
    }
}

.share-and-print {
    display: flex;
    justify-content: space-between;

    .btn-print-ancestry-mobile {
        display: none;
    
        @include media-breakpoint-down(md) {
            display: flex;
        }
    
        @include media-breakpoint-down(lg) {
            display: flex;
        }
    }
}

._schedule-exams {
    table {
        background: #fff;
        border-radius: 6px;
        padding: 30px;
        margin: 0 0 20px 0;
        display: block;
        &.info-text {
            tr {
                border: none;
                td {
                    padding: 0;
                }
            }
        }
    }
    .title-color {
        font-family: $ff-mulish !important;
        font-weight: 600;
        font-size: 20px;
        line-height: 22px;
        color: #333;
        border: none;
        &.black {
            color: #2B2B2B;
        }
    }
    thead {
        th {
            padding: 0.75rem 0;
        }
    }
    tbody {
        display: block;
        tr {
            display: block;
            border-bottom: 1px solid #CCCCCC;
            td {
                border: none;
                padding: 0.75rem 0 0;

                ul {
                    padding: 0px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 18px;
                    color: #848484;

                    li {
                        list-style: none;
                        border: none;
                        color: #848484;
                        &.list-none {
                            list-style: none;
                            margin: 10px 0;
                        }
                        .sub-title-color {
                            cursor: pointer;
                            font-family: $ff-mulish !important;
                            font-weight: $fw-semibold;
                            font-size: 16px;
                            line-height: 22px;
                            color: #2B2B2B;
                            position: relative;
                            &.next-exams {
                                &::after {
                                    content: '';
                                    border: solid #4f4f4f;
                                    border-width: 0 3px 3px 0;
                                    display: inline-block;
                                    margin: 1px 24px;
                                    padding: 3px;
                                    transform: rotate(45deg);
                                    -webkit-transform: rotate(45deg);
                                }
                            }

                            a {
                                color: #4f4f4f;
                                text-decoration: none;
                                &:hover,
                                &.active {
                                    &::after {
                                        content: '';
                                        border: solid #4f4f4f;
                                        border-width: 0 3px 3px 0;
                                        display: inline-block;
                                        margin: 1px 11px;
                                        padding: 3px;
                                        transform: rotate(-45deg);
                                        -webkit-transform: rotate(-45deg);
                                    }
                                }
                            }
                        }


                        .first-exam {
                            font-family: $ff-mulish !important;
                            font-weight: $fw-semibold;
                            font-size: 16px;
                            line-height: 22px;
                            color: #4F4F4F;
                            position: relative;
                            margin-left: -10px;
                            list-style: none;
                            margin-top: 24px;
                        }

                        i {
                            margin-left: 20px;
                            transform: rotate(180deg);
                        }

                        ul {
                            margin-top: 10px;
                            padding: 0 10px;
                            display: none;
                            li {
                                list-style: disc;
                                margin: 24px 10px;
                            }
                        }
                        &.toggle {
                            .next-exams {
                                &:after {
                                    transform: rotate(-135deg);
                                    -webkit-transform: rotate(-135deg);
                                }
                            }
                            i {
                                transform: rotate(0deg);
                                margin-left: 0;
                                &.fa-chevron-up {
                                    margin-left: 20px;
                                }
                            }
                            ul {
                                display: block;
                                &.no-padding {
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.view {
    .schedule-exam,
    .container {
        .coluna-1 {
            ._partial-exame-menu {
                .border-hr {
                    margin: 10px;
                    border: 1px solid #c3c3c3;
                }
            }
        }
        .coluna-3 {
            background: transparent;
            border-radius: 6px;
            padding: 0px;
            margin: 0 0 20px 0;

            @include media-breakpoint-down(md) {
                margin: 0;
                padding: 0 16px;
            }
            
            @include media-breakpoint-down(lg) {
                margin: 0;
                padding: 0 16px;
            }
            
            &.full {
                width: 100%;
            }
            .await-txt {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background: #fff;
                border-radius: 6px;
                padding: 50px 0 0;
                p {
                    font-size: 14px;
                    line-height: 166.3%;
                    text-align: center;
                    color: #9C9C9C;
                    max-width: 400px;
                    width: 70%;
                    margin-top: 80px;
                }

                .no-margin {
                    margin-top: 30px;
                    text-align: center;
                    &.mail {
                        max-width: 600px;
                    }
                }
            }
        }
    }

    .schedule-exam {
        .coluna-3 {
            background: #fff;
            .await-txt {
                margin-top: 70px;
            }
        }
    }
}

.share-genera {
    .view {
        .coluna-3 {
            ._partial-perfil-ances {
                display: none!important;
            }
            .conteudo {
                margin-top: 0;
            }
        }
    }
}
$DNA_scale: 0.45; //divides '$el-size'
$el-size: 3vh;
$t: 2.1s;
$td: -0.89;

$c_a : rgb(255, 132, 144) rgb(255, 115, 124) rgb(245, 247, 249); // top / bottom / BG
$c2_a: rgb(255, 218, 178) rgb(255, 50, 155); // Fade-anim colors

$ease-circ: cubic-bezier(0.42 , 0, 0.58, 1);

.DNA_cont {
    display: inline-block;
    position: relative;
    transform: scale($DNA_scale);
    left: -8px;
    @media (max-width: 1023px) {
        top: 40px;
    }
}

.nucleobase {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    //  width: 1px; height: $el-size*2.75;
    //  background: transparent;
    //  box-shadow: $el-size*0.475 $el-size*-0.85 0 rgba(0,0,0,0.2);

    &:not(:last-child){
        margin-right: $el-size*1.62;
    }

    &:before, &:after {
        content: "";
        display: inline-block;
        width: $el-size;
        height: $el-size;
        border-radius: 50%;
        position: absolute;
    }

    @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
            // animation: animDotBar $t $ease-circ infinite;

            animation-delay: $i*($td*$t);
            &:before {
            // transform: translateY(-200%);

            animation: animBefore $t $ease-circ infinite;

            animation-delay: $i*($td*$t);

            background-color: nth($c_a, 1);
            }

            &:after {
            // transform: translateY(200%);

            animation: animAfter $t $ease-circ infinite;

            animation-delay: $i*($td*$t)  ;

            background-color: nth($c_a, 2);

            //  mix-blend-mode: multiply;
            }
        }
    }
}
  @keyframes animBefore {
    0% {
      top: $el-size *-2;
      z-index: 1;
    }

    25% {
      transform: scale(1.2) ;
      z-index: 1;
    }

    50% {
     // background-color: nth($c_a, 1) ;
      top: $el-size *2;
      z-index: -1;
    }

    75% {
      background-color: nth($c2_a, 1);
      transform: scale(0.8) ;
      z-index: -1;
    }

    100% {

      top: $el-size *-2;
      z-index: -1;
    }
  }

  @keyframes animAfter {
    0% {
      top: $el-size *2;
      z-index: -1;
    }

    25% {
      background-color: nth($c2_a, 2) ;
      transform: scale(0.8) ;
      z-index: -1;
    }

    50% {
    //  background-color: nth($c_a, 2) ;
      top: $el-size *-2;
      z-index: 1;
    }

    75% {
      transform: scale(1.2) ;
      z-index: 1;
    }

    100% {
      top: $el-size *2;
      z-index: 1;
    }
  }


  @keyframes superscript {
    0% {
      opacity: 0;
      transform: translateY(-1em);
    }
    100% {
      opacity: 1;
      transform: translateY(0em);
    }
  }
._partial-caracteristica-menu-grupo {
    .box-results {
        .list {
            li {
                &.active {
                    a {
                        color: $cl-maximun-red;
                    }

                    h5 {
                        --text-color: $cl-maximun-red;
                    }
                }

                a {
                    &:hover {
                        color: $cl-maximun-red;

                        h5 {
                            --text-color: $cl-maximun-red;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        margin-bottom: $sixteen-px;
    }

    @include media-breakpoint-down(lg) {
        margin-bottom: $sixteen-px;
    }

    @include media-breakpoint-down(xs) {
        margin: 0 auto;
        margin-top: 3rem;
        width: 358px;
    }

    @media (max-width: 400px) {
        width: 100%;
    }

    p {
        --text-weight: 600;
        transition: 200ms ease-out;
    }

    .list {
        &.health {
            li {
                padding: 0;

                h3 {
                    padding-left: 2px;
                }
            }
        }

        li {
            display: block;
            margin-bottom: $twenty-four-px;

            &:last-child {
                margin: 0;
            }

            @include media-breakpoint-down(md) {
                height: auto;
            }

            &:hover {
                &.blocked {
                    a {
                        cursor: default;
                    }
                }

                &.active {
                    a {
                        cursor: default;
                    }
                }

                a {
                    text-decoration: none;
                }

                h3 {
                    color: $cl-maximun-red;
                    transition: 200ms ease-in;
                }
            }

            &.blocked {
                h5 {
                    display: none;
                }

                h3 {
                    color: #adadad !important;

                    &:after {
                        display: none;
                    }

                    &:before {
                        background: #adadad !important;
                    }
                }
            }

            &.active {
                h5 {
                    --text-color: #D12E26;
                    margin-bottom: 8px;
                }
            }

            h3 {
                @include texto-1;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: .5px;
                font-weight: $fw-bold;
                color: $cl-gray-davys;
                margin: 0 0 8px;
                position: relative;
                transition: 200ms ease-out;
            }
        }
    }
    &.grouping {
        .box-results {
            background: transparent;
            padding: 0;
        }
        
        ul {
            .risk-level {
                position: relative;
                line-height: 25px;
                text-align: left;
                padding-left: 18px;
                margin: 0 0 16px;
                --text-size:1.2rem;
                span {
                    width: 12px;
                    height: 12px;
                    position: absolute;
                    top: 8px;
                    left: 0;
                    border-radius: 50%;
                }
            }
        }
    }
}

._partial-caracteristica-box-resultado {
    @include media-breakpoint-down(md) {
        ._partial-title-box-exemplo {
            width: calc(100% + 30px);
            height: 68px;
            margin: -15px 0 30px -15px;
        }
    }

    @include media-breakpoint-down(xs) {
        width: 358px;
        margin: 24px auto;
    }

    @media (max-width: 400px) {
        width: 100%;
    }

    .box-result {
        display: flex;
        justify-content: space-between;
        position: relative;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }

        @include media-breakpoint-down(lg) {
            flex-direction: column;
        }
    }

    .label {
        label {
            width: 18px;
            height: 18px;
            background: $cl-gray-platinum;
            color: $cl-silver-chalice;
            font-weight: $fw-bold;
            border-radius: 50%;
            font-size: 14px;
            display: flex;
            margin: 0 0 0 8px;
            justify-content: center;
            align-items: center;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .content-box {
        background: $cl-silver-cultured;
        border-radius: 6px;
        margin: 16px 0 0 0;
        padding: 8px 0;
        min-height: 159px;
    }

    .box-snp {
        width: 284px;
        margin-right: 16px;
       

        p{
             color: $graydark-genera !important;
        }

        @include media-breakpoint-down(md) {
            @include width-flex(100%);
        }

        @include media-breakpoint-down(lg) {
            @include width-flex(100%);
        }

        .rs {
            width: 284px;
            height: 60px;
            background: $red-genera;
            color: $cl-branco;
            font-size: 20px;
            letter-spacing: .25px;
            line-height: 25px;
            padding: 17px 0;
            border-radius: 6px;
            font-weight: $fw-bold;
            text-align: center;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
    
            @include media-breakpoint-down(lg) {
                width: 100%;
            }
        }

        .content-box {
            .row {
                line-height: 18px;
                justify-content: space-between;
                align-items: center;
                margin: 0;
                padding: 8px 16px;

                &:nth-child(even) {
                    background: $cl-gray-platinum;
                }

                label {
                    font-family: "Mulish";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 18px;
                    letter-spacing: 0.25px;
                    color: $cl-gray-jet;
                    margin: 0;
                    max-width: 146px;
                }

                .tag-genotipo {
                    p {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        line-height: 18px;
                        border-radius: 8px;
                        background: $red-genera;
                        color: #fff !important;
                        width: 34px;
                        height: 26px;
                    }
                }

                &._list {
                    flex-direction: row;
                    border: none;
                    align-items: baseline;

                    ul {
                        max-width: 106px;
                        text-align: right;
                        padding: 0;
                        margin: 0;

                        li {
                            list-style: none;
                        }
                    }
                }
            }
        }
    }

    .box-genotipo {
        width: 284px;

        @include media-breakpoint-down(md) {
            @include width-flex(100%);
            margin: 20px 0 0 0;
        }

        @include media-breakpoint-down(lg) {
            width: 100%;
        }

        .group-genotipos {
            display: flex;
            justify-content: space-between;

            .item {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 84px;
                height: 60px;
                background: $cl-silver-cultured;
                color: $cl-gray-jet;
                font-size: 14px;
                font-weight: $fw-bold;
                line-height: 18px;
                letter-spacing: .5px;
                border-radius: 6px;
                transition: all 400ms ease-in-out;

                @include media-breakpoint-down(md) {
                    width: 80px;
                }

                &:hover {
                    cursor: pointer;
                }

                &._ativo {
                    transition: all 400ms ease-in-out;
                }

                &._1._ativo,
                &._2._ativo,
                &._3._ativo {
                    background: $red-genera;
                    color: $cl-branco;
                }
            }
        }

        .content-box {
            &.markers {
                padding: 16px;
            }

            .point-markers {
                margin: 0 auto;
                position: relative;

                .group {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    margin: 1px auto 0;
                    padding: 0 20px;

                    .item {
                        background: $cl-gray-platinum;
                        width: 14px;
                        height: 14px;
                        border-radius: 10px;
                        transition: all 400ms ease-in-out;

                        &.ativo {
                            transition: all 400ms ease-in-out;
                        }

                        &._1._ativo {
                            background: $red-genera;
                        }

                        &._2._ativo {
                            background: $red-genera;
                        }

                        &._3._ativo {
                            background: $red-genera;
                        }
                    }
                }

                .marker {
                    width: 0;
                    height: 0;
                    border-left: 7px solid transparent;
                    border-right: 7px solid transparent;
                    border-bottom: 15px solid $cl-rosa2;
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: auto;
                    top: 15px;
                    transition: all .4s ease;
                }
            }

            .content {
                margin: 26px 0 0;
                text-align: center;
                position: relative;

                article {
                    width: 100%;
                    position: absolute;
                    opacity: 0;
                    transition: opacity 300ms ease 100ms;

                    &._ativo {
                        opacity: 1;
                        transition: opacity 300ms ease 100ms;
                    }
                }

                h5 {
                    font-family: 'Mulish';
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 18px;
                    letter-spacing: .25px;
                    height: 18px;
                    color: $cl-gray-davys;
                    margin-bottom: 8px;
                }

                h3 {
                    font-family: 'Mulish';
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: .25px;
                    color: $cl-gray-jet;
                    margin: 0;
                }
            }
        }
    }
}
.share-genera {
    ._partial-caracteristica-box-texto {
        &._principal {
            .titulo-mobile {
                .back-btn-mobile {
                    display: none!important;
                }
            }
        }
    }

}
._partial-caracteristica-box-texto {
    transition: all 600ms ease;

    .font-txt-feature{
        p{
            font-family: 'Dasa Sans', sans-serif;
        }
    }

    &:last-child {
        margin: 0;
    }

    &._principal {
       .back-btn-mobile {
            display: none;
        }
        @media (max-width: 1199px){ 
            .titulo-mobile {
                @include mobile-titulo-back();
                margin: 0 0 20px 0;
                .back-btn-mobile {
                    display: inherit;
                    position: fixed;
                    bottom: 4px;
                    left: 0;
                    z-index: 99999;
                }
            }
        }
        @include media-breakpoint-down(md) {
            background: none;

            ._partial-title-box-exemplo {
                margin-top: -32px;
            }

            .titulo-mobile {
                @include mobile-titulo-back();
                margin: 0 0 20px 0;
                .back-btn-mobile {
                    display: inherit;
                    position: fixed;
                    bottom: 4px;
                    left: 0;
                    z-index: 99999;
                }
            }
        }

        @include media-breakpoint-down(xs) {
            width: 358px;
            margin: 24px auto;
        }

        @media (max-width: 400px) {
            width: 100%;
        }

    }

    &._like_dislike {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        h5{
            color: $graydark-genera !important;
        }

        .like-dislike-btn-box {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            margin-left: 0px;
            min-width: 158px;
        }

        .like-dislike-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100px;
            height: 50px;
            margin-right: 16px;
            line-height: 24px;
            box-shadow: none;

            &:hover,
            &:focus {
                outline: none;
                opacity: .6;
            }

            &:disabled {
                color: #fff !important;
                background: $red-genera;
                border: 2px solid $red-genera;
                box-sizing: border-box;
                border-radius: 40px;
                opacity: 1;
            }
            
            &:last-child {
                margin: 0;
            }

            @include media-breakpoint-down(md) {
                width: 70px;
                height: 40px;
                padding: 0;
                margin-right: 8px;
             
                &:last-child {
                    margin: 0;
                }
            }

            @include media-breakpoint-down(lg) {
                width: 70px;
                height: 40px;
                padding: 0;
                margin-right: 8px;
             
                &:last-child {
                    margin: 0;
                }
            }

           
        }
    }

    &._footer {
        @include media-breakpoint-down(md) {
            margin-top: 0;
        }

        @include media-breakpoint-down(lg) {
            margin-top: 0;
        }

        @include media-breakpoint-down(xs) {
            width: 358px;
            margin: 0 auto;
            margin-bottom: 1.5rem;
        }

        @media (max-width: 400px) {
            width: 100%;
        }

        .glossary {
            margin: 0;
        }
    }

    article {

        &.references {
            p {
                margin: 0;
            }
        }

        .glossary {
            margin: 0;
        }

        @include media-breakpoint-down(md) {
            margin: 0;
        }

        p {
            font-family: 'Dasa Sans', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: .5px;
            color: $graydark-genera;
            margin: 0;
            word-break: break-word;
            text-align: start !important; // utilizado !important para sobrepor css inline do editor

            small {
                font-size: 12px;
                line-height: 20px;
            }
        }
    }

    figure {
        margin: 16px 0;

        img {
            width: 100%;
            @include border-radius;

            @include media-breakpoint-down(md) {
                margin-bottom: 20px;
            }
        }

        &.figure-assinatura{
           

            img{
                
                max-height: 240px;
                object-fit: cover;
            }
        }


    }
}

._caracteristica-share {
    ._partial-caracteristica-box-texto {
        &._principal {
            .titulo-mobile {
                padding-left: 0;

                &:before {
                    display: none;
                }
            }
        }
    }
}

.haplogrupo {
    display: flex;
    justify-content: center;
    margin-bottom: $sixteen-px;
    
    @include media-breakpoint-down(md) {
        margin-bottom: $forty-eight-px;
    }

    @include media-breakpoint-down(lg) {
        margin-bottom: $forty-eight-px;
    }
    
    .haplogrupo-is {
        padding: 0 16px 0 0;
    }

    ._partial-haplogrupo-box-map {
        margin: 0;
        min-height: 426px;

        &.share {
            min-height: 458px;
        }

        .gen-card-body {
            --card-body-padding-x: 0;
            --card-body-padding-y: 0;
        }
    
        figure {
            margin: 0;
    
            img {
                width: 100%;
                @include border-radius;
            }
        }
    }
}

._partial-haplogrupo-box-texto {
    min-height: 100%;

    h4 {
        @include texto-1;
        font-weight: $fw-bold;
    }

    figure {
        margin: 15px 0 0;

        img {
            width: 100%;
            @include border-radius;
        }
    }

    p {
        @include texto-1;
        margin: 15px 0 0;
    }
}

._partial-title-box-exemplo {
    width: calc(100% + 60px);
    height: 68px;
    margin: -30px 0 30px -30px;
    left: 0px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-image: linear-gradient(90deg, #f69831 3%, #f8562e 100%);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    padding: 24px 30px;

    h4 {
        @include texto-1;
        font-size: 16px;
        color: $cl-branco;
        font-weight: $fw-bold;
        margin: 0;
    }

    label {
        background: $cl-branco;
        color: #f69831;
        width: 17px;
        height: 17px;
        font-weight: 600;
        border-radius: 50%;
        font-size: 12px;
        display: flex;
        padding: 0px 0px 0 1px;
        margin: 1px 0 0 10px;
        justify-content: center;
        align-items: center;

        &:hover {
            cursor: pointer;
        }
    }

    @include media-breakpoint-down(md) {
        background: $cl-silver-cultured;
        height: auto;
        padding-bottom: 10px;

        h4 {
            color: $cl-cinza3;
        }

        label {
            background: $cl-cinza3;
            color: $cl-branco;
        }
    }
}

._partial-receba-resultado {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0 50px;

    h3 {
        @include texto-1;
        font-size: 16px;
        font-weight: $fw-bold;
        color: #2b2b2b;
        text-align: center;
        margin: 0 30px 0 0;
    }

    .btn {
        padding: 10px 60px;
        font-weight: bold;
        &:hover {
            color: $cl-branco;
            background: $cl-preto;
        }
    }
}

._linhagem {
    @include media-breakpoint-down(md) {
        .gen-card {
            &._partial-haplogrupo-box-texto {
                border: 1px solid #CCCCCC;
                margin: -10px 0 0;
                
                .gen-card-body {
                    .gen-text {
                        --text-size: 13px;
                    }
                }

                h4 {
                    cursor: pointer;
                }
        
                p,
                figure {
                    display: none;
                }
        
                &.active {
                    padding-bottom: 12px;
        
                    p,
                    figure {
                        display: block;
                    }
                }
            }
        }
    }
}

._partial-buy {
    position: fixed;
    bottom: -23px;
    left: 0;
    right: 0;
    margin: 5% auto;
    display: flex;
    max-width: 100%;
    opacity: 0;
    pointer-events: none;
    transition: bottom .2s ease, opacity .2s ease;
    width: 632px;
    height: 66px;
    z-index: 1000;

    @media (max-width: 991px){
        bottom: 0px;
        margin-bottom: 0;
    }

    .wrapper {
        display: flex;
        background: linear-gradient(91.11deg, #74285C 0.6%, #D12E26 99.6%);
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.16);
        border-radius: 15px 15px 0 0;
        margin: 0;
        max-width: 720px;
        border-radius: 6px;
        padding: 6px;
        width: 100%;

        .header {
            display: flex;
            align-items: center;
            flex-basis: 100%;
            color: #fff;
            padding: 16px 10px;
            text-decoration: none;

            @media (max-width: 991px){
                padding: 10px 10px;
            }

            h5 {
                font-family: $ff-cabin;
                font-size: 16px;
                font-weight: 400;
                margin: 0;
            }

            button {
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                display: flex;
                align-items: center;
                text-align: right;
                letter-spacing: 0.25px;
                color: #FFFFFF;
                background: none;
                box-shadow: none;
                border: none;
                outline: none!important;
            }

            > div {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-basis: 100%;
                padding: 0 5px;
            }
        }

        .btn {
            flex-basis: 50%;
            flex-grow: 1;
            margin: 0 8px;
            padding-left: 8px;
            padding-right: 8px;
        }
    }
}

.buy-active {
    ._partial-buy {
        opacity: 1;
        pointer-events: initial;
        z-index: 99999;
    }
}

#buyModal{
    background: linear-gradient(91.11deg, #74285C 0.6%, #D12E26 99.6%);
    z-index: 99999;

    .modal-dialog {
        width: 100%;
        max-width: 100%;
        margin: 20px auto;
    }
    .modal-content {
        background: none;
        border: 0;
        border-radius: 15px;
        box-shadow: none;
        padding: 6px;
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        @media (max-width: 991px){
            max-width: 400px;
            width: 95%;
            padding: 0;
            margin: 0;
            margin: 0 auto;
        }
    }

    .modal-header {
        border: 0;
        color: $cl-branco;
        display: block;
        position: relative;
        text-align: left;
        padding: 0 0 20px;

        h5 {
            font-size: 22px;
            font-weight: $fw-regular;
            padding-right: 30px;
        }

        p {
            font-weight: $fw-book;
            margin: 0;
        }

        .close {
            box-shadow: none;
            color: $cl-branco;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 40px;
            margin: 0;
            opacity: 1;
            outline: 0;
            padding: 0;
            position: absolute;
            right: 0;
            text-shadow: none;
            top: 0;
            width: 26px;
        }
    }

    .modal-body {
        border-radius: 15px 15px 0 0;
        display: flex;
        flex-direction: column;
        padding: 0;
        justify-content: space-between;

        .plan {
            border-width: 1px 0 0;
            cursor: pointer;
            padding: 0;
            background: #fff;
            flex-basis: 33%;
            box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
            border-radius: 6px;
            @media (max-width: 991px){
                margin-bottom: 16px;
            }
        }

        .custom-radio,
        .custom-checkbox {
            display: flex;
        }

        .custom-radio {
            .custom-control-input:checked ~ .custom-control-label::before {
                color: $cl-branco;
                border-color: $cl-maximun-red;
                background-color: $cl-branco;
            }

            .custom-control-input:checked ~ .custom-control-label::after {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4' fill='%23eb1f40'/%3e%3c/svg%3e");
            }

            .custom-control-label {
                color: $cl-amarelo;
                cursor: pointer;
                flex: 1;
                font-size: 12px;
                font-weight: $fw-semibold;
                padding-top: 4px;
                text-transform: uppercase;
            }
        }

        .plan-details {
            display: flex;
            flex-direction: column;
            padding: 0;

            .plan-features {
                color: $cl-texto;
                padding: 24px;
                flex: 3;

                h5 {
                    font-weight: bold;
                    font-size: 32px;
                    line-height: 40px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.25px;
                    color: #333333;
                }

                p {
                    margin: 0;
                }

                .resource {
                    margin-top: 20px;
                    ul {
                        margin: 5px 0 0;
                        list-style: none;
                        padding: 0;
                        li {
                            margin: 0px auto 20px;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 14px;
                            line-height: 20px;
                            color: #757575;

                            &::before {
                                padding-right: 20px;
                                --text-opacity: 1;
                                color: #06D755;
                                color: rgba(6, 215, 85, var(--text-opacity));
                                content: "\F00C";
                                font-family: "Font Awesome 5 Free";
                                font-weight: 900;
                                font-size: 1.05rem;
                                -webkit-font-smoothing: antialiased;
                                display: inline-block;
                                font-style: normal;
                                font-variant: normal;
                                text-rendering: auto;
                                line-height: 0px;
                            }
                            &.not-included {
                                color: #D3D3D3;
                                &::before {
                                    padding-right: 25px;
                                    --text-opacity: 1;
                                    color: rgba(117, 117, 117, 0.32);
                                    content: "\F00D";
                                    font-family: "Font Awesome 5 Free";
                                    font-weight: 900;
                                    font-size: 1.15rem;
                                    -moz-osx-font-smoothing: grayscale;
                                    -webkit-font-smoothing: antialiased;
                                    display: inline-block;
                                    font-style: normal;
                                    font-variant: normal;
                                    text-rendering: auto;
                                    line-height: 0px;
                                }
                            }
                        }
                    }
                }
            }
            .plan-price {
                position: relative;
                padding: 16px 24px 24px;
                border-bottom-right-radius: 6px;
                border-bottom-left-radius: 6px;
                height: 205px;
                min-height: 205px;
                background: #f7f7f7;
                flex: 2;

                small {
                    color: #4f4f4f;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.5px;
                    &.txt-coupon {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 18px;
                        display: flex;
                        align-items: center;
                        letter-spacing: 0.25px;
                        color: #565656;
                    }
                    &.coupon-upper {
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 18px;
                        display: flex;
                        align-items: center;
                        letter-spacing: 0.25px;
                        color: #D12E26;
                        margin-left: 3px;

                    }
                }

                big {
                    display: block;
                    line-height: 1.2em;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 32px;
                    color: #333333;
                }
                a#btn-buy {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    margin: 0 24px 24px;
                    background: #D12E26;
                    padding: 12px 16px 14px;
                    border-radius: 40px;
                    color: #fff;
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                }
            }
        }
    }

    .modal-footer {
        border: none;
        display: flex;
        justify-content: stretch;
        padding: 15px 4px;

        p {
            color: #fff;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.25px;
            justify-content: left;
        }

        a {
            color: #fff;
            text-decoration: underline;
        }

        .btn {
            flex: 1;
            font-size: 14px;
            font-weight: $fw-semibold;
            margin: 0 8px;
            padding-left: 8px;
            padding-right: 8px;
        }
    }

    @include media-breakpoint-up(lg) {
        .modal-header {
            h5 {
                font-weight: bold;
                font-size: 32px;
            }
        }

        .modal-body {
            align-items: stretch;
            flex-direction: row;

            .plan {
                align-items: stretch;
                border-width: 0 0 0 1px;
                display: flex;
                flex-direction: column;
                padding: 0;

                &:first-child {
                    border: 0;
                }

                .plan-details {
                    justify-content: space-between;
                    flex: 1;
                    flex-direction: column;
                }

                .plan-features,
                .plan-price {
                    flex: none;
                }

                .plan-features {
                    padding-bottom: 15px;
                }
            }
        }

        .modal-footer {

            .btn {
                flex: none;
                padding-left: 30px;
                padding-right: 30px;
            }
        }
    }
}

.modal-backdrop {
    z-index: 1;
    &.fade.show {
        opacity: 0.2;
    }
}
//Form crop modal
#cropForm {
    position: relative;
    .modal-content {
        .modal-body {
            h3 {
                font-family: Mulish;
                font-style: normal;
                font-weight: bold;
                font-size: 20px;
                line-height: 25px;
                display: flex;
                align-items: center;
                letter-spacing: 0.5px;
                color: #333333;
                margin-bottom: 16px;
            }
            button {
                border: none;
                box-shadow: none;
                padding: 0;
                margin: 16px 16px 0 0;
            }
        }
        .modal-footer {
            justify-content: left;
        }
    } 
}

#termsModal {
    .modal-content {
        background: linear-gradient(0deg, #f69831 1%, $cl-maximun-red 52%, #a65477 100%);
        border: 0;
        border-radius: 15px;
        box-shadow: none;
        max-width: 850px;
        padding: 6px;
        width: 100%;
    }

    .modal-header {
        border: 0;
        color: $cl-branco;
        display: block;
        padding: 20px;
        position: relative;
        text-align: center;

        h5 {
            font-family: $ff-cabin;
            font-size: 22px;
            font-weight: $fw-regular;
        }

        p {
            font-weight: $fw-book;
            margin: 0;
        }

        .close {
            box-shadow: none;
            color: $cl-branco;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 26px;
            margin: 5px;
            opacity: 1;
            outline: 0;
            padding: 0;
            position: absolute;
            right: 0;
            text-shadow: none;
            top: 0;
            width: 26px;
        }
    }

    .modal-body {
        background: #FFF;
        border-radius: 15px 15px 0 0;
        display: flex;
        flex-direction: column;
        padding: 0;

        .plan {
            border: 1px solid $cl-cinza4;
            border-width: 1px 0 0;
            cursor: pointer;
            padding: 10px;

            &:first-child {
                border: 0;
            }

            &:hover {
                background: rgba(173,173,173,.2);
            }
        }

        .custom-radio,
        .custom-checkbox {
            display: flex;
        }

        .custom-radio {
            .custom-control-input:checked ~ .custom-control-label::before {
                color: $cl-branco;
                border-color: $cl-maximun-red;
                background-color: $cl-branco;
            }

            .custom-control-input:checked ~ .custom-control-label::after {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4' fill='%23eb1f40'/%3e%3c/svg%3e");
            }

            .custom-control-label {
                color: $cl-amarelo;
                cursor: pointer;
                flex: 1;
                font-size: 12px;
                font-weight: $fw-semibold;
                padding-top: 4px;
                text-transform: uppercase;
            }
        }

        .plan-details {
            display: flex;
            padding: 10px 0 0 1.5rem;

            .plan-features {
                color: $cl-texto;
                flex: 3;

                h5 {
                    font-size: 18px;
                    font-weight: $fw-semibold;
                }

                p {
                    margin: 0;
                }

                .resource {
                    margin-top: 20px;
                    ul {
                        margin: 5px 0 0;
                        list-style: none;
                        padding: 0;
                        li {
                            margin: 0px auto 20px;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 14px;
                            line-height: 20px;
                            color: #757575;

                            &::before {
                                padding-right: 20px;
                                --text-opacity: 1;
                                color: #06D755;
                                color: rgba(6, 215, 85, var(--text-opacity));
                                content: "\F00C";
                                font-family: "Font Awesome 5 Free";
                                font-weight: 900;
                                font-size: 1.05rem;
                                -webkit-font-smoothing: antialiased;
                                display: inline-block;
                                font-style: normal;
                                font-variant: normal;
                                text-rendering: auto;
                                line-height: 0px;
                            }
                            &.not-included {
                                color: #D3D3D3;
                                &::before {
                                    padding-right: 25px;
                                    --text-opacity: 1;
                                    color: rgba(117, 117, 117, 0.32);
                                    content: "\F00D";
                                    font-family: "Font Awesome 5 Free";
                                    font-weight: 900;
                                    font-size: 1.15rem;
                                    -moz-osx-font-smoothing: grayscale;
                                    -webkit-font-smoothing: antialiased;
                                    display: inline-block;
                                    font-style: normal;
                                    font-variant: normal;
                                    text-rendering: auto;
                                    line-height: 0px;
                                }
                            }
                        }
                    }
                }
            }
            .plan-price {
                color: $cl-roxo;
                flex: 2;

                small {
                    color: $cl-texto;
                    display: block;
                    font-size: 12px;
                    font-weight: $fw-semibold;
                }

                sup {
                    font-size: 20px;
                    top: -0.7em;
                }

                big {
                    display: block;
                    font-size: 36px;
                    font-weight: $fw-semibold;
                    line-height: 1.2em;
                }
            }
        }

        .plan-roxo {
            .custom-control-label {
                color: $cl-roxo;
            }
        }

        .plan-rosa {
            .custom-control-label {
                color: $cl-maximun-red;
            }
        }
    }

    .modal-footer {
        background: #FFF;
        border-radius: 0 0 15px 15px;
        display: flex;
        justify-content: stretch;
        padding: 15px 4px;

        .btn {
            flex: 1;
            font-size: 14px;
            font-weight: $fw-semibold;
            margin: 0 8px;
            padding-left: 8px;
            padding-right: 8px;
        }
    }

    @include media-breakpoint-up(lg) {
        .modal-dialog {
            max-width: 850px;
            width: 850px;
        }

        .modal-header {
            padding: 30px 50px;

            h5 {
                font-size: 36px;
            }
        }

        .modal-body {
            align-items: stretch;
            flex-direction: row;

            .plan {
                align-items: stretch;
                border-width: 0 0 0 1px;
                display: flex;
                flex-direction: column;
                flex: 1;
                padding: 20px;

                &:first-child {
                    border: 0;
                }

                .plan-details {
                    justify-content: space-between;
                    flex: 1;
                    flex-direction: column;
                }

                .plan-features,
                .plan-price {
                    flex: none;
                }

                .plan-price {
                    big {
                        font-size: 40px;
                    }
                }

                .plan-features {
                    padding-bottom: 15px;
                }
            }
        }

        .modal-footer {
            justify-content: center;

            .btn {
                flex: none;
                padding-left: 30px;
                padding-right: 30px;
            }
        }
    }
    .modal-body {
        padding: 30px;

        h3 {
            font-size: 16px;
            padding: 0 0 30px;
        }

        .custom-checkbox {
            padding-bottom: 30px;

            .custom-control-label::before,
            .custom-control-label::after {
                bottom: 0;
                margin: auto 10px;
                top: 0;
            }

            .custom-control-label::before {
                border: 2px solid #747474;
                height: 20px;
                width: 20px;
            }

            .custom-control-label::after {
                background-size: 60%;
                height: 24px;
                margin: auto 8px;
                width: 24px;
            }

            .custom-control-input:checked ~ .custom-control-label::before {
                color: $cl-branco;
                border-color: $cl-maximun-red;
                background-color: $cl-maximun-red;
                height: 26px;
                margin: auto 7px;
                width: 26px;
            }

            .custom-control-label {
                color: #212529;
                cursor: pointer;
                font-size: 14px;
                padding-left: 30px;
                text-transform: none;
            }
        }
    }

    .modal-footer {
        border-top: 0;
    }
}

._partial-exame-notification {
    align-items: center;
    background: #fff;
    border-radius: 6px;
    color: #757575;
    display: inherit;
    justify-content: center;
    margin: 0px 0 20px;
    min-height: 105px;
    padding: 70px 40px;
    text-align: center;

    p {
        margin: 0;
        padding: 0;
    }

    .btn {
        margin-left: 20px;
        padding: 6px 20px;
    }
}

.search-reatives-msg {
    background: #ffffff;
    border-radius: 6px;
    padding: 16px 16px 0;
    max-height: 107px;
    margin: 0 0 10px 0;
    border: 2px solid #CE2E26;

    @media screen and (min-width: 992px)  {
        max-width: 333px;
        margin-top: 0;
    }


    p {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.25px;
        color: #333333;
        margin: -5px 0 0 0;
    }

    .second-line {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        max-height: 35px;

        a {
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.25px;
            text-decoration-line: underline;
            color: #333333;
        }
    }
}

._partial-doencas-geneticas-box {
    @include box-branco;
    padding: 30px;
    margin: 0 0 20px 0;

    h2 {
        @include texto-1;
        font-size: 16px;
        font-weight: $fw-bold;
        margin: 0 0 10px 0;
    }

    p {
        @include texto-1;
        margin: 20px 0 0;
    }

    &._partial-doencas-geneticas-box-identificadas,
    &._partial-doencas-geneticas-box-nao-identificadas {
        h2 {
            color: #D90053;
        }

        ul {
            margin: 15px 0;
            padding: 0;

            &:last-child {
                margin-bottom: 0;
            }

            li {
                list-style: none;
                padding: 2px 0;

                a {
                    border-radius: 16px;
                    color: #2B2B2B;
                    display: block;
                    font-weight: bold;
                    padding: 8px 12px;
                    text-decoration: none;

                    &.active,
                    &:hover {
                        background: #DADADA;
                    }
                }
            }
        }

        .btn {
            background: #D90053;
            border-radius: 50px;
            color: #FFF;
            padding: 15px 5px;
            width: 100%;
        }
    }

    &._partial-doencas-geneticas-box-identificadas {
        border: 2px solid #D90053;
        padding: 26px;
    }

    &._partial-doencas-geneticas-box-o-que-fazer {
        background: #7A7A7A;
        color: #FFFFFF;

        h2,
        p {
            color: #FFFFFF;
        }

        .btn {
            background: #FFFFFF;
            border-radius: 50px;
            color: #7A7A7A;
            margin: 15px 0 0;
            padding: 15px 45px;
        }
    }

    &.small {
        h2,
        p {
            font-size: 9px;
        }

        h2 {
            font-weight: normal;
            margin: 0;
        }

        h2 + p {
            margin-top: 0;
        }
    }

    ._partial-doencas-geneticas-resultado-detalhes {
        @include box-branco;
        background: #E6E6E6;
        color: #4F4F4F;
        margin: 15px 0 0;
        padding: 30px;

        table {
            width: 100%;

            tr {
                td {
                    border-bottom: 1px solid #CFCFCF;
                    font-weight: bold;
                    padding: 8px 0;

                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }
    }
}

//Initials & Avatar

._partial-box-results {
    margin: 0 0 30px 0;
    text-align: center;
    padding: 0;
    span {
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
    }
    .initial-avatar-col {
        width: 64px;

        @media (max-width: 991.98px) {
            border-radius: 0;
            padding: 10px 10px 20px 13px;
            vertical-align: top;
        }
        img {
            border-radius: 50%;
            width: 48px;
            height: 48px;
        }
        .initials {
            height: 52px;
            width: 52px;
            background-color: #CDCDCD;
            border-radius: 50%;
            display: inline-block;
            p {
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                padding: 10px 5px;
                text-align: center;
                color: #333333;
            }
        }
    }
}

.empty-results {
    line-height: 150px;
}

._partial-perfil-ances {
    .circle-progress {
        .progress-value {
            .initials {
                width: 70px;
                height: 70px;
                background-color: #CDCDCD;
                border-radius: 50%;
                display: inline-block;
                p {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    padding: 14px 5px;
                    text-align: center;
                    color: #333333;
                }
            }
        }
    }
}
.coluna-2 {
    ._partial-perfil-ances {
        .row-1 {
            h4 {
                margin-top: 30px;
                font-size: 18px;
                word-break: break-word;
            }
        }
    }
}

.radio-custom {
    opacity: 0;
    position: absolute;

    & + .radio-custom-label:before {
        content: '';
        background: #fff;
        border: 2px solid #717171;
        display: inline-block;
        vertical-align: middle;
        width: 16.5px;
        height: 16px;
        padding: 2px;
        margin-right: 10px;
        text-align: center;
    }

    &:disabled {
        cursor: no-drop;
    
        & + .radio-custom-label:before {
            border: 2px solid #696969 !important;
            color: #696969 !important;
            cursor: no-drop;
        }
        
        & + .radio-custom-label {
            cursor: no-drop;
        }
    }

    &:checked + .radio-custom-label:before {
        background-color: #717171;
        box-shadow: inset 0 0 0 2px #fff;
    }

    &:checked:disabled + .radio-custom-label:before {
        background-color: #696969;
        box-shadow: inset 0 0 0 2px #fff;
    }
}

.radio-custom, .radio-custom-label {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
}

.radio-custom-label {
    position: relative;
}

//Upsell 
._partial-exame-menu.menu-main-category {
    .h3.upsell-h3 {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        color: #ababab;
    }
    .gen-card-packages {
        min-width: 254px;

        p {
            &.upsell-package {
                color: #D12E26;
                font-size: 12px;
                font-weight: 800;
                line-height: 16px;
                letter-spacing: 1.5px;
                text-align: left;
            }
            &.upsell-title {
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
            }
            &.upsell-description {
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: left;                
            }
        }

        a {
          &.gen-btn {
              display: inline-block;
              font-family: "Mulish",sans-serif;
              font-size: 1rem;
              line-height: 1;
              &.gen-btn-link {
                  padding-left: 0;
              }
              &::before {
                  display: none;
              }
          }  
        }
    }
}

.assinatura-content{

    ._partial-caracteristica-box-resultado{
        margin-bottom: $twenty-four-px !important;
    }

    .box-result{

        @media screen and (max-width:1330px) and
        (min-width:1200px)
        {
            flex-wrap: wrap;
            gap: 16px;
            .box-snp,.box-genotipo{
                width: 100%;
            }

        }
        @media screen and (max-width:1200px) and
        (min-width:992px)
        {
            .box-genotipo{
                margin-top: 16px;
            }

        }

      

    }
}

.icon-question{
    width: 18px;
    height: 18px;
    background: transparent;
    color: $cl-silver-chalice;
    font-weight: $fw-bold;
    border-radius: 50%;
    border: 3px solid $cl-gray-platinum;
    font-size: 14px;
    display: flex;
    margin: 0 0 3px 8px;
    justify-content: center;
    align-items: center;

    &:hover {
        cursor: pointer;
    }
}


.assinatura-content{

    .additional-information{
        .gen-text-info{
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 24px;
        }

        table tr th label {
            &.text-normal{
                text-transform: none !important;
                font-size: 12px !important;
            }
        }
    }


}


//assinatura content cards
.assinatura-content-cards{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    width: 100% !important;

    @include media-breakpoint-down(xs) {
       padding: 0 !important;
        width: 358px !important;
    }

    @media screen and (max-width: 400px){
        width: 100% !important;
    }

    .content-group-cards{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        gap: 24px;
        align-items: center;
        
        .prs-card{
            max-width: 302px;
            min-width: 302px;
            min-height: 274px;
            max-height: 274px;
            margin: 0px !important;

            @media screen and (min-width: 1340px){
                .prs-risk-details{
                    margin-bottom: 0 !important;
                }
            }

            @media screen and (max-width: 1100px){
                min-height: 291px;
                max-height: 291px;
            }

            @include media-breakpoint-down(xs) {
                max-width: 100%;
                min-width: 100%;
                
            }

            .prs-name{

                &.prs-title{
                    padding-left: 0px !important;
                    font-family: 'Dasa Sans', sans-serif !important;
                }

                .title-with-mark::before{
                    content: '';
                    position: absolute;
                    left: 15px;
                    margin-top: 3px;
                    width: 12px;
                    height: 12px;
                    border-radius: 100%;
                    background: $blue-genera;

                }

            }

            .market-analyzed-number{
                p{
                    font-size: 32px;
                    font-weight: 700;
                    line-height: 40px;
                    color: $cl-gray-jet;
                    margin-bottom: 2rem;
                    font-family: 'Dasa Sans', sans-serif !important;
                }
            }

            .market-analyzed-text{
                p{
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 13.8px;
                    color: $cl-gray-jet;
                    font-family: 'Dasa Sans', sans-serif !important;
                }
            }

            .prs-disclaimer-short{
                align-items: center;
                span{
                    font-weight: 600 !important;
                    font-size: $fourteen-px;
                }
            }

        }


    }

}

#partial_like{

    @include media-breakpoint-down(xs) {
        width: 358px;
        margin: 0 auto;
    }

    @media (max-width: 400px) {
        width: 100%;
    }
}

._partial-caracteristica-box-texto {
    .references{
        p {
            a{
                text-decoration: underline;
            }
        }
    }
}

.font-txt-feature,.font-txt-feature-title{
    --text-font-family: 'Dasa Sans', sans-serif;
    --text-color: #262626 !important;
    color: $graydark-genera !important;
}

.font-txt-feature{
    font-size: 1rem;
}

#snp-code{
    &.text-white{
        p{
            color: #fff !important;
        }

    }
}