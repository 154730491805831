//Forms and inputs

.form-genera {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    .form-group,
    .form-col {
        padding: 0;
        margin-top: 16px;
        margin-bottom: 0;
        min-height: 45px;
        position: relative;

        .full-width {
            width: 100%;
        }
        select,
        input[type=email],
        input[type=number],
        input[type=password],
        input[type=search],
        input[type=tel],
        input[type=text] {
            color: #565656;
            background: #f7f7f7;
            border-radius: 4px 4px 0 0;
            border: 1px solid #f7f7f7;
            font-size: .875rem;
            padding: 22px 1rem 1rem 10px;
            font-weight: 700;
            letter-spacing: .5px;
            height: 55px;
            width: 100%;
        }
        select:focus ~ .floating-label,
        select:not(:focus):valid ~ .floating-label,
        input:focus ~ .floating-label,
        input:not(:focus):valid ~ .floating-label{
            top: 5px;
            bottom: 10px;
            left: 14px;
            font-size: 11px;
            opacity: 1;
            color: #ababab;
            font-weight: 600;
        }
        input:not(:placeholder-shown) {
            padding: 1.5rem 1rem 0.5rem;
        }
        select:invalid ~ .floating-label,
        .floating-label {
            position: absolute;
            pointer-events: none;
            left: 13px;
            top: 17px;
            font-size: .875rem;
            font-weight: 700;
            letter-spacing: .5px;
            color: #565656;
            transition: .2s ease all;
        }

        .has-error {
            select,
            input[type=email],
            input[type=number],
            input[type=password],
            input[type=search],
            input[type=tel],
            input[type=text] {
                border-bottom: 1px solid #D12E26;
            }
        }

        label {
            font-family: Mulish;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #333;
        }

        .policy {
            line-height: 2.2;
        }
    }

    .gen-input {
        .gen-input-group {
            .gen-input-labels-group {
                small {
                    &.gen-text-primary {
                        --text-color: #d12e26;
                    }
                }
            }
        }
    }
    
    hr +.form-group {
        margin-top: 0px;
    }
}


.ipts-genera {
    input {
        background: #e4e4e4;
        border-radius: 4px 4px 0 0;
        border: none;
        border-bottom: 1px solid $cl-silver-chalice;
        width: 100%;
        height: 48px;
        padding: 13px 14px 7px;
        font-weight: bold;
        font-size: 14px;
        color: #333;
    }

    input:focus ~ .floating-label,
    input:not(:focus):valid ~ .floating-label{
        top: 0px;
        left: 13px;
        font-size: 11px;
        opacity: 1;
        color: $cl-gray-davys;
        font-weight: 600;
    }

    input:invalid ~ .floating-label,
    .floating-label {
        position: absolute;
        pointer-events: none;
        left: 13px;
        top: 17px;
        font-size: 14px;
        transition: .2s ease all;
        font-weight: 700;
        color: #333;
    }
}

#form-register {
    &.form-genera {
        .gen-input {
            width: 100%;
        }

        img {
            &.info-label {
                opacity: 0.6;
                margin: 20px 10px;

                &:hover {
                    cursor: pointer;
                    opacity: 1;
                }
            }
        }
    }
}

.new-form-genera {
    width: 100%;

    hr {
        height: 0;
        margin: 16px 0;
        background: #c1c1c1;
    }

    .row-1 {
        text-align: left;
        margin-bottom: 16px;

        h1 {
            font-family: Mulish;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 30px;
            display: flex;
            align-items: center;
            letter-spacing: 0.25px;
            color: #333333;
            transform: none!important;
            margin: 0 0 16px;
        }

        h3 {
            font-family: Mulish;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            display: flex;
            align-items: center;
            letter-spacing: 0.5px;
            color: #333333;
        }

        .detalhe {
            @include bar-gradient();
        }
    }

    .row-2 {
        .btn {
            width: 100%;
            height: 46px;
        }

        ._form-padrao .form-group label {
            margin: 0 0 5px 0;
            text-align: center;
            width: 100%;

            &.gender {
                min-width: 77px;
                margin: 0 16px 0 0 !important;
                border: 0;
            }

            &.label-tooltip {
                width: auto;
                cursor: pointer;
                line-height: 0;
                margin: 0;
            }
        }

        ._form-padrao {
            .form-group {
                input:focus ~ .floating-label,
                input:not(:focus):valid ~ .floating-label{
                    top: 0px;
                    bottom: 10px;
                    left: 14px;
                    font-size: 11px;
                    opacity: 1;
                    color: #565656;
                    font-weight: 600;
                }
                input:invalid ~ .floating-label,
                .floating-label {
                    position: absolute;
                    pointer-events: none;
                    left: 13px;
                    top: 13px;
                    font-size: 14px;
                    font-weight: 700;
                    color: #4f4f4f;
                    transition: .2s ease all;
                }
                &.actions {
                    width: 400px;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    background: transparent;
                    margin-bottom: 0 !important;
                    min-height: 46px;
                    button,
                    a {
                        height: 46px;
                        flex-grow: 1;
                        flex-shrink: 1;
                        flex-basis: 0;
                        text-align: center;
                        @include media-breakpoint-down(md) {
                            margin: 0!important;
                        }
    
                    }
                    button {
                        background: $cl-maximun-red;
                        border-radius: 40px;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 0%;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        justify-content: center;
                        color: #FFFFFF;
                        box-shadow: none;
                        @include media-breakpoint-down(md) {
                            margin-right: 10px!important;
                        }
                    }
                    a {
                        background: transparent;
                        border: 2px solid #333333;
                        box-sizing: border-box;
                        border-radius: 40px;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 100%;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        justify-content: center;
                        color: #333333;
                        &:hover {
                            background: #4f4f4f;
                            color: #fff;
                        }
                        @include media-breakpoint-down(md) {
                            margin-left: 10px;
                        }
                    }
                }
            }

            .floating-label {
                &.cpf {
                    top: 0;
                    left: 15px;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 180%;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.5px;
                    color: #4B4B4B;
                }
                &.gender-fix {
                    top: -15px;
                    left: 0;
                    font-weight: 600;
                    font-size: 11px;
                    line-height: 180%;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.5px;
                    color: #4B4B4B;
                    text-transform: capitalize;
                }
            }
        }

        .btn-default-red {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            width: 169px;
            max-width: 169px;
            height: 46px !important;
            left: 0px;
            top: 0px;
        }

        .btn-default-cinza {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            width: 102px;
            max-width: 102px;
            height: 46px;
            left: 185px;
            top: 0px;
        }

        .label-info {

            label {
                display: flex;
                padding: 0 1px 0 0;
                margin: 0 0 0 16px !important;
                justify-content: flex-start;
                align-items: center;
            }

            p {
                margin: 0 !important;
                min-width: 76px;
            }
        }
    }

    .row-3 {
        display: flex;
        flex-direction: column;

        div.d-flex {
            justify-content: left;
            padding: 0;
            margin-bottom: 16px;
        }

        a {
            font-family: Mulish;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.25px;
            text-decoration-line: underline;
            color: #333333;
        }
    }
    .form-group {
        padding: 0;
        margin-bottom: 15px;
        height: 45px;
        &.flex-row {
            display: flex;
            flex: inherit;
            align-items: center;
        
        }
        label {
            text-align: left!important;
        }
        .gray-no-bg {
            margin-left: 16px;
        }
    }
    .form-control {
        background: #E4E4E4;
        border-radius: 4px 4px 0px 0px;
        border-bottom: 1px solid #ABABAB;
        padding: 13px 14px 7px;
        font-weight: bold;
        font-size: 14px;
        color: #333;
    }
}


//Buttons, links e anchors
a, 
button {
    &.default-red-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 188px;
        max-width: 100%;
        padding: 0 25px;
        height: 40px;
        background: $cl-maximun-red !important;
        border-radius: 40px;
        border: none;
        box-shadow: none;
        font-weight: bold;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.25px;
        color: #FFFFFF;
        &:hover {
            text-decoration: underline;
        }
        &:focus {
            outline: none;
        }
    }

    &.default-red-btn-no-bg {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 188px;
        max-width: 188px;
        height: 40px;
        background: #fff;
        border-radius: 40px;
        border: none;
        box-shadow: none;
        font-weight: bold;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.25px;
        color: $cl-maximun-red;
        &:hover {
            text-decoration: underline;
        }
        &:focus {
            outline: none;
        }
        &.new-cep {
            margin: 288px auto 0;
        }
    }
    
    &.default-gray-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 102px;
        max-width: 188px;
        height: 46px;
        background: #ffffff;
        border-radius: 40px;
        border: 2px solid #333333;
        box-shadow: none;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.25px;
        color: #333333;
        &:hover {
            text-decoration: underline;
            background-color: #333333;
            color: #ffffff;
        }
        &:focus {
            outline: none;
        }
    }
    &.gray-no-bg {
        font-weight: bold;
        font-size: 16px;
        line-height: 28px;
        color: #333333;
        text-align: center;
        text-decoration: underline;


        &.bottom-zero {
            position: absolute;
            bottom: 0;
            border: none;
            box-shadow: none;
            background: none;

            @media (min-width: 991px) {
                bottom: 20px;
            }
            &.no-bottom {
                position: relative;
                margin-bottom: 20px;
            }
        }
        &.small {
            font-size: 11px;
        }
    }
    &.a-back {
        position: absolute;
        top: 10px;
        left: 30px;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.5px;
        color: #333333;
        z-index: 1;
        &:hover {
            text-decoration: underline;
        }
        &::before {
            content: '';
            background: url(../img/new-register/a-back-btn.svg) no-repeat center center;
            position: absolute;
            left: -14px;
            top: 0;
            margin: auto 0;
            width: 7px;
            height: 29px;
            z-index: 1;        
        }
    }
}

.gray-link {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: .5px;
    line-height: 18px;
    color: $cl-gray-jet;
    text-decoration: underline;

    @include media-breakpoint-down(md) {
        font-size: 16px;
        line-height: 24px;
        text-decoration: underline;
        margin-bottom: 8px;
    }
    
    @include media-breakpoint-down(lg) {
        font-size: 16px;
        line-height: 24px;
        text-decoration: underline;
        margin-bottom: 8px;
    }
}

.text-underline {
    text-decoration: underline;
}

.btn-genera-large {
    min-width: 165px;
    max-height: 155px;
}

.gen-btn {
    &.gen-btn-link {
        &.text-underline {
            text-decoration: underline;
        }

        &.hover-underline {
            &:hover {
                text-decoration: underline;
            }
        }
    }
}