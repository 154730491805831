/* Base Styles -------------------- */
figure {
    margin: 0;
}

body {
    background: #f0f0f0;
    display: flex;
    flex-direction: column;
    font-family: $ff-mulish;
    font-weight: $fw-medium;
    font-size: 13px;
    justify-content: stretch;
    min-height: 100vh;
    &._logout {
        #content-main {
            background: transparent;
        }
    }
}


#content-main {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: stretch;
    margin-top: 0;
    @include media-breakpoint-down(md) {
        margin-top: 0px;
    }
    background: #f7f7f7;
    
    @media print {
        -webkit-print-color-adjust: exact; 
        padding-top: 35px;
    }

    &._logout-content {
        margin-top: 0;
    }

    &.print {
        padding-top: 0;
    }

    &.first {
        margin-top: -32px;

        @media print {
            margin-top: 0;
        }

        &.prs {
            @media print {
                margin-top: -32px;
            }   
        }
    }
}

.progress {
    @include border-radius;
    background: #EFEFEF;
    height: 16px;

    .progress-bar {
        @include border-radius;
        background: $cl-maximun-red;

        &._rosa {
            background: $cl-maximun-red;
        }

        &._amarelo {
            background: $cl-amarelo;
        }

        &._roxo {
            background: $cl-roxo;
        }

        &._vermelho {
            background: #b81832
        }

        &._african {
            color: $cl-africa;
            background: $cl-africa;
        }

        &._centralsouth-asian {
            color: $cl-lesteasiatico;
            background: $cl-lesteasiatico;
        }

        &._east-asian {
            color: $cl-lesteasiatico;
            background: $cl-lesteasiatico;
        }

        &._european {
            color: $cl-europa;
            background: $cl-europa;
        }

        &._jewish-diaspora {
            color: $cl-judaica;
            background: $cl-judaica;
        }

        &._middle-eastern {
            color: $cl-orientemedio;
            background: $cl-orientemedio;
        }

        &._new-world {
            color: $cl-americas;
            background: $cl-americas;
        }

        &._trace-results {
            color: $cl-outros;
            background: $cl-outros;
        }
    }
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
button,
.uneditable-input:focus {
    border-color: $cl-cinza4;
    box-shadow: 0 0px 0px $cl-cinza4 inset, 0 0 8px $cl-cinza4;
    outline: 0 none;
}

.stripe-loading-indicator {
    height: 5px !important;

    .stripe,
    .stripe-loaded {
        height: 5px !important;
        background: $cl-maximun-red !important;
        border-radius: 10px !important;
    }
}


.img-desktop,
.sec-desktop {
    @include media-breakpoint-down(md) {
        display: none;
    }
}

.img-mobile,
.sec-mobile {
    display: none!important;
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.tooltip {
    opacity: 1!important;
    @include box-branco;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 20px 0px;
    // left: 180px!important;
    // top: -20px!important;
    margin: 10px 0;
    
    .arrow {
        display: none;
    }

    .tooltip-inner {
        background: #fff;
        @include texto-1;
        font-size: 12px;
        max-width: 300px;
    }
}

.loader {
    background: rgba(0, 0, 0, .2);
    border-radius: 6px;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    transition: all 600ms ease 100ms;
    top: 0;
    z-index: 1000;

    > div {
        background: #FFF;
        border-radius: 50%;
        bottom: 0;
        height: 2.5rem;
        left: 0;
        margin: auto;
        padding: .25rem;
        position: absolute;
        right: 0;
        top: 0;
        width: 2.5rem;
    }

    .spinner-border {
        border-color: $cl-maximun-red transparent $cl-maximun-red $cl-maximun-red;
    }

    &._hide {
        opacity: 0;
        pointer-events: none;
    }
}

.top-20 {
    top: 20px;
}

.map-debug {
    border: 2px solid red;
    border-radius: 50%;
    display: none;
    left: 56%;
    height: 20px;
    margin: -10px 0 0 -10px;
    position: absolute;
    top: 46%;
    width: 20px;
}

#print-iframe {
    border: 0;
    display: block;
    left: 0;
    height: 100px;
    position: absolute;
    top: 0;
    width: 100%;
}

.mb-6{
    margin-bottom: 1.5rem;
}
