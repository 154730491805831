.view._notfound {
  background: url('#{$path-image}/bg_notfound.jpg') no-repeat center center;
  background-size: cover;
  color: $cl-texto;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  >.container {
    background: transparent;
    min-height: initial;
  }

  h1 {
    font-size: 140px;
    font-weight: 100;
  }

  h2 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  a {
    color: $cl-maximun-red;
    text-decoration: underline;
  }

  @media (max-width: 1200px) {
    h1 {
      font-size: 70px;
    }

    h2 {
      font-size: 18px;
    }
  }
}
