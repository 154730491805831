#footer {
    background: $cl-gray-jet;
    position: relative;

    &.change-password {
        min-height: 393px;

        @include media-breakpoint-down(md) {
            @include width-flex(100%);
            min-height: 393px;
        }
    }

    .sac-contact {
        color: #eb1f40;
    }
    .container {
        background: none;

        @include media-breakpoint-down(md) {
            @include width-flex(100%);
        }
        
    }

    .content {
        padding-bottom: 0;
        padding-top: 50px;

        @include media-breakpoint-down(md) {
            padding: 30px 20px;
        }

        @media (max-width: 480px) {
            max-width: 100%;
            padding-left: 20px;
            padding-right: 20px;
        }

        .logo {

            @include media-breakpoint-down(md) {
                margin: 0 0 30px 0;
            }

            img {
                &.img-mobile {
                    @include media-breakpoint-down(md) {
                        display: block!important;
                    }
                }
            }
        }

        .box-right {
            display: flex;
            flex-direction: column;
            justify-content: center;

            ul {
                padding: 0;
                margin: 0;

                li {
                    display: block;
                    margin: 5px 0px 5px 50px;
                    color: $cl-cinza4;
                    font-size: 13px;

                    @include media-breakpoint-down(md) {
                        text-align: right !important;
                        margin: 12px 0;
                    }

                    a {
                        font-family: Mulish;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 18px;
                        display: flex;
                        align-items: center;
                        letter-spacing: 0.5px;
                        color: #F7F7F7;

                        img {
                            margin: 0 10px 0 0;
                        }
                    }
                }
            }

            .links {
                display: flex;

                @include media-breakpoint-down(md) {
                    flex-direction: column;
                }

                li {
                    a {
                        padding-bottom: 10px;
                        border-bottom: 4px solid transparent;
                        font-weight: 700;
                        transition: all 300ms ease-out;

                        &:hover {
                            text-decoration: none;
                            border-color: $cl-maximun-red;
                            transition: all 300ms ease-out;
                        }
                    }
                }
            }

            .contato {
                display: flex;
                flex-direction: column;
                margin: 55px 0 0 0;
                max-width: 300px;

                &.share {
                    margin: 0;
                }

                li {
                    text-align: left;

                    img {
                        margin: 0 12px 0 0;
                    }
                }
            }
        }

        .footer-head {
            margin-bottom: 256px;
            @include media-breakpoint-down(md) {
                flex-direction: column;
                .box-right {
                    ul li {
                        text-align: left!important;
                        margin: 12px 0;
                    }
                }
            }
        }

        .copyright {
            padding: 24px 0 40px;
            align-items: center;
            @include media-breakpoint-down(md) {
                padding: 24px 0 0;
            }
            &:before {
                content: "";
                width: 100%;
                height: 1px;
                display: block;
                background: linear-gradient(90deg,#74285C 0%, #D12E26 100%);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
            }
            p {
                font-family: Mulish;
                font-style: normal;
                font-weight: 600;
                font-size: 11px;
                line-height: 14px;
                display: flex;
                align-items: center;
                letter-spacing: 0.5px;
                color: #F7F7F7;
                margin-bottom: 0;
            }
            .social-icons {
                @include media-breakpoint-down(md) {
                    display: none;
                }
                display: flex;
                justify-content: flex-end;
                
                .icon {
                    margin-right: 1rem;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        .social-mobile {
            margin-bottom: 24px;
            display: none;

            @include media-breakpoint-down(md) {
                display: inherit;
            }
            .social-icons {
                display: flex;
                
                .icon {
                    margin-right: 1rem;
                }
            }
        }
    }

    &._logout {
        ._login {
            display: none !important;
        }

        .container {
            padding: 40px 15px 0;

            @media (max-width: 768px) {
                max-width: 350px;
                padding: 30px 20px;
            }
        }
    }
}
