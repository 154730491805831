._form-material {}

._form-padrao {
    .form-group {
        margin: 0 0 20px 0;

        label {
            @include texto-1;
            color: #4f4f4f;
            font-size: 14px;
            font-weight: $fw-bold;
            margin: 0 0 10px 15px;
        }

        .form-control {
            background: #dadada;
            color: #4f4f4f;
            border-radius: 40px;
            border: none;
            font-size: 14px;
            height: 46px;
            padding: 7px 14px;
        }
    }

    .check-relatives {
        padding: 0;
        margin-bottom: 1rem;

        .custom-control {
            padding-left: 0;

            &.custom-checkbox {
                .radio-custom-label {
                    width: 95%;
                    margin: 0;
                }
            }
        }
    }
}

.btn {
    color: $cl-branco;
    border-radius: 25px;
    font-size: 14px;
    font-weight: $fw-semibold;
    padding: 6px 30px;

    &.btn-form {
        background: $cl-maximun-red;
    }
    &.btn-cinza-border {
        border: 2px solid $cl-texto;
        color: $cl-texto;
        &:hover {
            background: $cl-cinza4;
            border-color: $cl-cinza4;
            color: $cl-branco;
        }
    }
    &.btn-cinza {
        background: $cl-texto;
        &:hover {
            color: $cl-branco;
            background: $cl-maximun-red;
        }
    }
    &.btn-rosa {
        background: $cl-maximun-red;
        color: $cl-branco;
        &:hover {
            background: $cl-rosa2;
            color: $cl-branco;
        }
    }
    &.btn-rosa-light {
        background: $cl-branco;
        color: $cl-maximun-red;
        border: 1px solid $cl-maximun-red;
        &:hover {
            color: $cl-rosa2;
            background: $cl-branco;
        }
    }
    &.btn-link {
        box-shadow: none;
        color: #2B2B2B;
    }

    &.btn-lg {
        padding: 12px 30px;
    }
}
.btn-voltar-view {
    background: #dadada;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: -90px;
    opacity: .8;
    transition: all 300ms ease;

    @include media-breakpoint-down(md) {
        top: -75px;
        left: 0px;
    }

    &:hover {
        opacity: 1;
        transition: all 300ms ease;
    }

    img {
        transform: rotateZ(-90deg);
    }
}
