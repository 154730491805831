body {
    background: #e5e5e5;
}
._logout {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 0 -2px;
    background-repeat: no-repeat;
    &._logout-bg-asia-central {
        background-image: url('#{$path-image}/login-background/asia-central.webp');
    }
    &._logout-bg-costa-da-mina-bg {
        background-image: url('#{$path-image}/login-background/costa-da-mina-bg.webp');
    }
    &._logout-bg-euro-ocidental-central {
        background-image: url('#{$path-image}/login-background/euro-ocidental-central.webp');
    }
    &._logout-bg-levante-bg {
        background-image: url('#{$path-image}/login-background/levante-bg.webp');
    }
    &._logout-bg-magrebe {
        background-image: url('#{$path-image}/login-background/magrebe.webp');
    }
}
