//New Menu Mobile
.container {
    &.menu-mobile {
        position: relative;
        margin: 0 auto 20px;
        flex: 0 0 300px;
        border-radius: 6px;
        @media (max-width: 480px) {
            width: 90%;
            max-width: 432px;
        }
        @media (min-width: 481px) and (max-width: 991px) {
            max-width: 320px;
            width: 90%;
        }
        @media (min-width: 992px) {
            max-width: 410px;
        }

        .box-schedule-results {
            padding: 16px;
            background: $cl-branco;
            border-radius: 6px;
            
            p {
                position: relative;
                display: flex;
                align-items: center;
                align-content: center;
                font-family: Mulish;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: .5px;
                color: $cl-gray-jet;
                margin: 0;
                padding-left: 16px;
    
                &:before {
                    content: '';
                    position: absolute;
                    opacity: 1;
                    background: $cl-maximun-red;
                    left: 0;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    z-index: 0;
                }
            }

            a {
                &:hover {
                   color: $cl-gray-jet; 
                   text-decoration: none;
                }
            }
        }

        &.menu-mobile-assinatura{
            .gen-card{
                margin-bottom: 24px;
            }
            .gen-card:last-child{
                margin-bottom: 0px;
            }
            .active-subscription{
                margin-top: 0;
                background-color: $purple-genera;

                .gen-text,p{
                    color: $cl-white !important;
                }

                .active-subscription-button{
                    background-color: $red-genera;
                    color: $cl-white;
                    border: none;
                    box-shadow: none;
                    padding: 8px 12px;
                    border-radius: 24px;
                    font-family: Mulish;
                    font-weight: 700;
                }
            }
        }
    }
}
.navbar-toggler {
    box-shadow: none;
    border: 0;
    outline: none;
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
    position: fixed;
    bottom: 35px;
    right: 15px;
    z-index: 999999;
    @media print {
        display: none!important;
    }

    &:active,
    &:hover,
    &:focus {
        outline: none;
        --background: #d12e26!important;
        --border-color: #d12e26!important;
    }
    &.share {
        display: none;
    }

    > img {
        position: relative;
        left: 5px;
    }

    > span {
        position: relative;
        right: 5px;     
    }
}

button.menu-mobile-dhl-hide {
    display: none;
}
#header {
    background: #eee;
    position: relative;
    height: auto;
    z-index: 2;

    &.ancestry-new-map {
        height: 705px;

        @include media-breakpoint-down(lg) {
            height: 495px;
        }
    }

    @media (max-width: 991px) {
        padding: 0;

        &.ancestry-new-map {
            height: 430px;
        }
    }

    &.header-no-desc {
        height: auto;
        padding: 0;
        .full-banner-image {
            display: none!important;
            .no-description {
                .cover-description {
                    display: none;
                }
            }
        }
    }

    &.share-header {
        .header-actions {
            i {
                display: none;
            }
            .my-perfil {
                &.open {
                    display: none !important;
                }
            }
        }
    }
    .header-main-container {
        background: #fff;
    }
    .container {
        background: none;

        @include media-breakpoint-down(md) {
            @include width-flex(100%);
        }
    }

    .content {
        height: 96px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media-breakpoint-down(md) {
            padding: 0 20px;
            height: 64px;
        }

        @media (max-width: 480px) {
            max-width: 100%;
        }

        .logo {
            img {
                width: 140px;

                @include media-breakpoint-down(md) {
                    width: 105px!important;
                }
            }
        }
        .show-pefil-menu {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0 0 0 15px;
            color: #fff;
            .perfil {
                padding-right: 16px;
                margin: 16px 0;

                @include media-breakpoint-down(md) {
                    padding-right: 15px;
                    margin: 12px 0;
                }
            }
            .fa-chevron-down{
                &:before {
                    color: #333;
                }
            }
        }
        .my-perfil {
            display: none;
            flex-direction: column;
            justify-content: center;
            padding: 0 20px 0 0;
            &.open {
                display: flex;
                position: absolute;
                flex-direction: column;
                align-items: flex-start;
                width: 200px;
                right: 0px;
                top: 70px;
                background: #fff;
                padding: 16px;
                border-radius: 5px;
                box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.16);
                z-index: 1;
            }
            a{
                padding: 0 0 16px;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 18px;
                letter-spacing: 0.25px;
                border-radius: 5px;
                color: $graydark-genera;
                font-family: 'Dasa Sans', sans-serif;
                &:last-child {
                    padding: 0;
                } 
                img {
                    margin-right: 8px;
                }
            }

            form{
                img {
                    margin-right: 8px;
                }
                button{
                    padding: 0 0 16px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 18px;
                    letter-spacing: .25px;
                    border-radius: 5px;
                    border: none;
                    box-shadow: none;
                    color: $graydark-genera;
                    background-color: inherit;
                    font-family: 'Dasa Sans', sans-serif;
                }
            }

        }

        .perfil {
            img {
                border-radius: 50%;
                width: 58px;
                height: 58px;
                margin-top: 1px;

                @include media-breakpoint-down(md) {
                    width: 52px;
                    height: 52px;
                }
            }

            .initials {    
                width: 64px;
                height: 64px;
                background-color: #cdcdcd;
                border-radius: 50%;
                display: inline-block;
                @include media-breakpoint-down(md) {
                    width: 40px;
                    height: 40px;
                }
                p {
                    font-style: normal;
                    font-weight: 600;
                    line-height: 45px;
                    font-size: 20px;
                    padding: 8px;
                    text-align: center;
                    color: #333333;

                    @include media-breakpoint-down(md) {
                        line-height: 25px;
                        font-size: 15px;
                    }
                }
            }
        }
    }

    &._logout {
        background: transparent;
        height: inherit;
        padding-bottom: 0;

        > .container {
            @media (max-width: 767px) {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 77px;
            }
        }

        &:after {
            bottom: inherit;
            top: 0;
        }

        .logo {
            img {
                width: 162px;
            }
        }
    }

    &.dhl-header {
        background: #fff;
        padding-bottom: 0;
        z-index: 99999;
        @include media-breakpoint-down(md) {
            height: inherit;
        }

        .container {
            width: 100%;
            max-width: 1280px;
        }
        > .container {
            @media (max-width: 767px) {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 77px;
            }
        }

        .content {
            background: #fff;
            .perfil {
                .initials {
                    @media (max-width: 991px) {
                        width: 40px;
                        height: 40px;

                        p {
                            line-height: 25px;
                            font-size: 16px;
                        }
                    }
                }
            }
            .show-pefil-menu {
                padding: 0px 0px 0px 16px;
                .perfil {
                    padding-right: 10px;
                }
                .fa-chevron-down{
                    &:before {
                        color: #333;
                    }
                }
            }
        }

        &:after {
            bottom: inherit;
            top: 0;
        }

        .logo {
            img {
                width: 162px;
            }
        }
    }

    .header-actions {
        display: flex;
        position: relative;
        flex-direction: column;
    }
    //Banner, descrição e mapa
    .full-banner-image {
        width: 100%;
        min-height: 480px;
        display: flex;
        margin: auto;
        position: relative;
        top: 0;
        left: 0;
        z-index: -1;

        @media print {
            display: block;
            min-height: 250px;
            margin: 0 !important;
        }
        
        .exams-cover {
            display: flex;
            width: 100%;
            max-width: 1280px;
            margin: 48px auto;
            padding: 0 26px;

            &.isPacient {
                @include media-breakpoint-down(lg) {
                    img {
                        max-height: 450px;
                    }
                }

                @include media-breakpoint-down(md) {
                    overflow-x:  hidden;
                    min-width: 100%;
                    flex-direction: column;
                    padding: 0;
                    margin-top: -63px;

                    .cover-container {
                        padding: 0 1rem;
                        margin: -86px auto 16px;
                    }

                    img {
                        position: initial;
                        width: 176%;
                        margin-left: -64%;
                        max-height: 450px;
                    }
                }

                @media print {
                    margin: auto;

                    .cover-container {
                        padding: 0 26px;
                        margin: 22px auto auto;
                    }

                    img {
                        position: absolute;
                        width: 100%;
                        margin: 0 auto;
                        max-height: auto;
                    }
                }
            }

            @include media-breakpoint-down(lg) {
                margin: 60px 0;
                max-width: 520px;
            }

            @include media-breakpoint-lg2 {
                width: 90%;
                max-width: 100%;
            }

            @include media-breakpoint-down(md) {
                width: 100%;
                margin: 60px 0;
            }

            @media print {
                max-height: 200px !important;
                margin: auto !important;
                padding: 0 10px;
            }

            &.isPacient {
                @media print {
                    max-height: 100% !important;
                }
            }

            .cover-container {
                @media print {
                    min-width: 100%!important;
                    padding: 0 26px;
                    margin-top: 30px;
                }
            }
            &.share {
                width: auto;
            }
            img {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                z-index: -1;
                -o-object-fit: cover;
                object-fit: cover;
                max-width: 1280px;
                margin: 0 auto;
            }
            .cover-description {
                position: relative;
                display: flex;
                flex-direction: column;
                width: 100%;
                max-width: 630px;
                min-width: 630px;
                max-height: 336px;
                min-height: 250px;
                border: none;
                word-break: break-word;
                
                @include media-breakpoint-down(md) {
                    max-height: 520px;
                    min-width: 0;
                }

                @include media-breakpoint-down(lg) {
                    max-height: 660px;
                    min-width: 0;
                }

                @media print {
                    --card-body-padding-x: 1rem;
                    --card-body-padding-y: 1rem;
                }   

                .gen-card-body {
                    @media print {
                        --card-body-padding-x: 0;
                        --card-body-padding-y: 0;
                    }
                }
                
                .title-and-share {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 16px;
                    
                    @media print {
                        margin-bottom: 5px;
                    }
                }

                .cover-share-desktop {
                    display: flex;
                    margin-top: 16px;   
                    @include media-breakpoint-down(md) {
                        flex-direction: column;
                        .share.share-desktop {
                            margin-top: 16px;
                        }
                    }             
                }

                .share {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    &.share-desktop {
                        width: 145px;
                    }

                    .btn-print-mobile {
                        display: none;
    
                        @include media-breakpoint-down(md) {
                            display: flex;
                        }
                    
                        @include media-breakpoint-down(lg) {
                            display: flex;
                        }

                        .btn-print {
                            background: none;

                            @include media-breakpoint-down(md) {
                                display: flex;
                            }
                        
                            @include media-breakpoint-down(lg) {
                                display: flex;
                            }

                            i {
                                margin: 0;

                                svg path {
                                    fill: #fff;
                                }
                            }
                        }
                    }
                    
                    a {
                        margin: 0;
                    }
                    img {                        
                        width: 24px;
                        height: 24px;
                        position: relative;
                        z-index: 0;
                    }
                }
                h2 {
                    line-height: 30px;
                    letter-spacing: 0.25px;
                    margin: 0;

                    @media print {
                        font-size: 20px !important;
                    }
                }

                p {
                    width: 100%;
                    margin: 0;

                    &.cover-share-results {
                        width: auto;
                        margin-right: 16px;
                    }

                    @media print {
                        --text-size: 11px;
                    }

                    @media (max-width: 992px) {
                        overflow: hidden;
                        --text-size: 12px;
                    }
                    @media (min-width: 992px) {

                        /* width */
                        &::-webkit-scrollbar {
                            width: 9px;
                            background-color: #E8E8E8;
                        }
                        
                        /* Track */
                        &::-webkit-scrollbar-track {
                            box-shadow: inset 0 0 5px grey; 
                            border-radius: 10px;
                        }
                        
                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: #333333; 
                            border-radius: 10px;
                        }
                        
                        /* Handle on hover */
                        &::-webkit-scrollbar-thumb:hover {
                            background: #333333; 
                        }
                    }
                }
                .cover-links {
                    margin: 16px 0 0;
                    align-items: center;
                    a {
                        margin: 0;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 18px;
                        letter-spacing: 0.5px;
                        color: #333;
                        align-items: center;

                        &.desk {
                            @include media-breakpoint-down(md) {
                                display: none;
                            }
                        }
                        img {
                            width: 24px;
                            height: 24px;
                            position: relative;
                            margin-left: 8px;
                            z-index: 0;
                        }                  
                    }
                }
                .add-more-text {
                    opacity: 0;
                }
                .link {
                    display: none;
                    position: absolute;
                    width: 100%;
                    height: 150px;
                    background: linear-gradient(178.34deg, rgba(255, 255, 255, 0) 1.41%, #ffffff 77.52%);
                    border-radius: 6px;
                    bottom: 0;
                    left: 0px;
                    align-items: flex-end;
                    font-weight: 600;
                    padding: 10px 15px;
                    font-size: 16px;
                    letter-spacing: 0.25px;
                    color: #333333;
                    text-decoration: none;
                    @media (max-width: 992px) {
                        display: flex;
                    }
                }
            }
        }

        .assinatura-cover{
            display: flex;
            width: 100%;
            max-width: 100%;
            margin: 48px auto;
            padding: 0 26px 0 90px ;
            

            @include media-breakpoint-down(lg) {
                margin: 40px auto;
                max-width: 630px;
                padding: 0;
            }

            @include media-breakpoint-lg2 {
                max-width: 100%;
                padding: 0 26px 0 3rem ;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;
                margin: 40px auto;
            }
            @include media-breakpoint-down(xs) {
                width: 100%;
                max-width: 100%;
                padding: 0 1rem;
                justify-content: center;
            }

            @media print {
                max-height: 200px !important;
                margin: auto !important;
                padding: 0 10px;
            }

            .cover-container {
                @media print {
                    min-width: 100%!important;
                    padding: 0 26px;
                    margin-top: 30px;
                }
            }

            img {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                z-index: -1;
                -o-object-fit: cover;
                object-fit: cover;
                max-width: 100%;
                margin: 0 auto;
            }
            .cover-description {
                position: relative;
                display: flex;
                flex-direction: column;
                width: 100%;
                max-width: 630px;
                min-width: 630px;
                max-height: 336px;
                min-height: 250px;
                border: none;
                word-break: break-word;
                
                @include media-breakpoint-down(md) {
                    max-height: 520px;
                    min-width: 0;
                }

                @include media-breakpoint-down(lg) {
                    max-height: 660px;
                    min-width: 0;
                }
                @include media-breakpoint-down(xs) {
                    width: 358px;
                }

                @media screen and (max-width:400px){
                    width: 100%;
                }

                @media print {
                    --card-body-padding-x: 1rem;
                    --card-body-padding-y: 1rem;
                }   

                .gen-card-body {
                    @media print {
                        --card-body-padding-x: 0;
                        --card-body-padding-y: 0;
                    }
                }
                
                .title-and-share {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 16px;
                    
                    @media print {
                        margin-bottom: 5px;
                    }
                }

                .cover-share-desktop {
                    display: flex;
                    margin-top: 16px;   
                    @include media-breakpoint-down(md) {
                        flex-direction: column;
                        .share.share-desktop {
                            margin-top: 16px;
                        }
                    }             
                }

                .share {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    &.share-desktop {
                        width: 145px;
                    }

                    .btn-print-mobile {
                        display: none;
    
                        @include media-breakpoint-down(md) {
                            display: flex;
                        }
                    
                        @include media-breakpoint-down(lg) {
                            display: flex;
                        }

                        .btn-print {
                            background: none;

                            @include media-breakpoint-down(md) {
                                display: flex;
                            }
                        
                            @include media-breakpoint-down(lg) {
                                display: flex;
                            }

                            i {
                                margin: 0;

                                svg path {
                                    fill: #fff;
                                }
                            }
                        }
                    }
                    
                    a {
                        margin: 0;
                    }
                    img {                        
                        width: 24px;
                        height: 24px;
                        position: relative;
                        z-index: 0;
                    }
                }

                
                h2 {
                    line-height: 30px;
                    letter-spacing: 0.25px;
                    margin: 0;
                    color: $graydark-genera !important;

                    @media print {
                        font-size: 20px !important;
                    }
                }

                p {
                    width: 100%;
                    margin: 0;
                    color: $graydark-genera !important;
                    line-height: $twenty-four-px;

                    &.cover-share-results {
                        width: auto;
                        margin-right: 16px;
                    }

                    @media print {
                        --text-size: 11px;
                    }

                    @media (max-width: 992px) {
                        overflow: hidden;
                        --text-size: 12px;
                    }
                    @media (min-width: 992px) {

                        /* width */
                        &::-webkit-scrollbar {
                            width: 9px;
                            background-color: #E8E8E8;
                        }
                        
                        /* Track */
                        &::-webkit-scrollbar-track {
                            box-shadow: inset 0 0 5px grey; 
                            border-radius: 10px;
                        }
                        
                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: #333333; 
                            border-radius: 10px;
                        }
                        
                        /* Handle on hover */
                        &::-webkit-scrollbar-thumb:hover {
                            background: #333333; 
                        }
                    }
                }
                .cover-links {
                    margin: 16px 0 0;
                    align-items: center;
                    a {
                        margin: 0;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 18px;
                        letter-spacing: 0.5px;
                        color: #333;
                        align-items: center;

                        &.desk {
                            @include media-breakpoint-down(md) {
                                display: none;
                            }
                        }
                        img {
                            width: 24px;
                            height: 24px;
                            position: relative;
                            margin-left: 8px;
                            z-index: 0;
                        }                  
                    }
                }
                .add-more-text {
                    opacity: 0;
                }
                .link {
                    display: none;
                    position: absolute;
                    width: 100%;
                    height: 150px;
                    background: linear-gradient(178.34deg, rgba(255, 255, 255, 0) 1.41%, #ffffff 77.52%);
                    border-radius: 6px;
                    bottom: 0;
                    left: 0px;
                    align-items: flex-end;
                    font-weight: 600;
                    padding: 10px 15px;
                    font-size: 16px;
                    letter-spacing: 0.25px;
                    color: #333333;
                    text-decoration: none;
                    @media (max-width: 992px) {
                        display: flex;
                    }
                }
               
            }
        }

        @media (max-width: 991px) {
            .block-map {
                position: absolute;
                top: 0px;
                width: 100%;
                height: 400px;
                padding: 0;
                display: flex;
                flex-direction: row;
                .map-cover {
                    position: absolute;
                    top: 0;
                    background: rgba(0, 0, 0, 0.5);
                    width: 100%;
                    height: 400px;
                    margin-top: -20px;
                    z-index: 9999;
                }
                .enable-map {
                    width: 48px;
                    height: 32px;
                    background: #FFFFFF;
                    border-radius: 3px;
                    display: flex;
                    z-index: 99999;
                    margin-left: 15px;
                }
                .enable-map-text {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    letter-spacing: 0.25px;
                    color: #FFFFFF;
                    margin: 5px;
                    height: 18px;
                    z-index: 99999;
                }
                .form-check.form-check-inline {
                    margin: auto;
                }
            }
        }
        @media (min-width: 992px) {
            .block-map {
                display: none;
            }
        }
        .ancestry-map {
            position: relative;
            width: 100%;
            max-width: 100%;
            padding: 0;
            margin: 0 auto;
            @media (max-width: 768px) {
                margin: 0 auto;
            }

            .position-zoom-btn {
                position: relative;
                width: 100%;
                height: 100px;
                max-width: 1280px;
                padding: 0px;
                margin: 20px auto;
            }
            .box-buttons {
                position: absolute;
                z-index: 9999;
                top: 5%;
                right: 3.5%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 64px;
                height: 98px;
                background: #ffffff;
                border-radius: 6px;
                button {
                    border-radius: none;
                    margin: auto 0;
                    box-shadow: none;
                    padding: 0;
                }
            }
        }
    }

    .alert {
        margin: 16px 0 0 0;
    }
}

.header-print {
    background: $cl-silver-cultured;
    position: relative;
    height: auto;
    z-index: 1001;

    @media (max-width: 991px) {
        padding: 0;

        &.ancestry-new-map {
            height: 430px;
        }
    }

    .user-name {
        h5 {
            line-height: 18px;
            margin: 0 24px 0 0;
        }
    }

    &.new {
        margin-top: 10px;
    }
    
    .full-banner-image {
        min-height: 350px !important;
        
        @media print {
            min-height: 250px !important;
        }
    }

    .exams-cover {
        margin: 25px auto;

        @media print {
            margin: auto;
        }
    }

    .my-perfil {
        display: none;
    }
    
    .header-main-container {
        background: #fff;
    }

    .container {
        background: none;

        @include media-breakpoint-down(md) {
            @include width-flex(100%);
            max-width: 350px;
        }
    }

    #header {
        padding: 0;
    }

    .content {
        height: 75px;
        min-height: 75px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media-breakpoint-down(md) {
            padding: 0 20px;
            height: 64px;
        }

        @media (max-width: 480px) {
            max-width: 100%;
        }

        .logo {
            img {
                width: 140px;

                @include media-breakpoint-down(md) {
                    width: 105px!important;
                }
            }
        }  

        .user-name {
            h5 {
                line-height: 18px;
                margin: 0 9px 0 0;
                --text-size: 14px;
            }
        }
    }  
    //Banner, descrição e mapa
    .full-banner-image {
        width: 100%;
        min-height: 480px;
        display: flex;
        margin: auto;
        position: relative;
        top: 0;
        left: 0;
        z-index: -1;
        
        @media print {
            display: block;
            margin: 0 !important;
            min-height: 200px;
            max-height: 200px;
        }
        
        .exams-cover {
            display: flex;
            width: 100%;
            max-width: 1280px;
            margin: 48px;
            padding: 0 !important;

            @include media-breakpoint-down(lg) {
                min-width: 100%;
                margin: 0 0px;
                margin-top: 60px;
                margin-bottom: 50px;
                justify-content: center;
                max-width: 310px;
            }

            @include media-breakpoint-lg2 {
                width: 90%;
                max-width: 100%;
            }

            @include media-breakpoint-down(md) {
                width: 100%;
                margin: 0 0px;
                margin-top: 60px;
                margin-bottom: 50px;
            }

            @media print {
                max-height: 200px !important;
                margin: -25px auto auto !important;
            }
            
            &.print {
                margin: 25px auto;
                @media print {
                    margin: 0 auto auto !important;
                    padding: 20px 0 0 !important;
                }

                .title-and-share {
                    @media print {
                        margin-bottom: 5px !important;
                    }
                }

                #new-page-title {
                    @media print {
                        --text-size: 20px;
                    }
                }

                #new-page-text {
                    @media print {
                        --text-size: 11px;
                    }
                }
            }

            .cover-container {
                @include media-breakpoint-down(md) {
                    width: 310px;
                }

                @media print {
                    width: 100% !important;
                    padding: 0 26px;
                }
            }
            &.share {
                width: auto;
            }
            img {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                z-index: -1;
                -o-object-fit: cover;
                object-fit: cover;
            }
            .cover-description {
                position: relative;
                display: flex;
                flex-direction: column;
                width: 100%;
                max-width: 630px;
                min-width: 630px;
                max-height: 336px;
                min-height: 250px;
                border: none;
                
                @include media-breakpoint-down(md) {
                    max-height: 520px;
                    padding: 16px;
                    min-width: 0;
                }

                @include media-breakpoint-down(lg) {
                    max-height: 520px;
                    padding: 16px;
                    min-width: 0;
                }

                @media print {
                    max-width: 604.36px !important;
                    min-width: 604.36px !important;
                    max-height: 200px !important;
                    min-height: 190px !important;
                }

                .gen-card-body {
                    @media print {
                        --card-body-padding-x: 0;
                        --card-body-padding-y: 0;    
                    }
                }
                
                .title-and-share {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    
                    @media print {
                        margin-bottom: 0;
                    }

                    h2 {
                        @media print {
                            margin-bottom: 8px;
                            --text-size: 20px;
                        }
                    }
                }

                h3 {
                    font-family: $ff-mulish;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 30px;
                    letter-spacing: 0.25px;
                    color: #333333;
                    margin: 0;
                }

                p {
                    font-family: $ff-mulish;
                    width: 100%;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    letter-spacing: .5px;
                    color: #565656;
                    margin: 0;

                    @media print {
                        font-size: 11px;
                    }

                    @media (max-width: 992px) {
                        overflow: hidden;
                    }
                    @media (min-width: 992px) {

                        /* width */
                        &::-webkit-scrollbar {
                            width: 9px;
                            background-color: #E8E8E8;
                        }
                        
                        /* Track */
                        &::-webkit-scrollbar-track {
                            box-shadow: inset 0 0 5px grey; 
                            border-radius: 10px;
                        }
                        
                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: #333333; 
                            border-radius: 10px;
                        }
                        
                        /* Handle on hover */
                        &::-webkit-scrollbar-thumb:hover {
                            background: #333333; 
                        }
                    }
                }

                .link {
                    display: none;
                    position: absolute;
                    width: 100%;
                    height: 150px;
                    background: linear-gradient(178.34deg, rgba(255, 255, 255, 0) 1.41%, #ffffff 77.52%);
                    border-radius: 6px;
                    bottom: 0;
                    left: 0px;
                    align-items: flex-end;
                    font-weight: 600;
                    padding: 10px 15px;
                    font-size: 16px;
                    letter-spacing: 0.25px;
                    color: #333333;
                    text-decoration: none;
                    @media (max-width: 992px) {
                        display: flex;
                    }
                }
            }
        }
    }
}

.navbar-collapse {
    padding: 20px 0 0;
    z-index: 99999;

    @include media-breakpoint-up(md) {
        padding: 14px 0 0 0;
    }

    @include media-breakpoint-down(lg) {
        padding: 14px 0 0 0;

        &.show + #content-main {
            display: none;
        }
    }

    @include media-breakpoint-up(lg) {
        padding: 14px 0 0 0;
        .container {
            max-width: 410px;
        }
    }

    @include media-breakpoint-up(xl) {
        display: none;
    }
}
