._pagination {
  display: flex;
  justify-content: center;

  .pagination {
    justify-content: center;
    flex-wrap: wrap;
    margin: 30px 0 0;

    li {
      padding: 6px;

      @media (max-width: 991.98px) {
        padding: 0;
      }

      span,
      a {
        display: block;
        color: $cl-texto;
        border-radius: 16px;
        font-weight: 400;
        line-height: 32px;
        min-width: 32px;
        min-height: 32px;
        text-align: center;
        text-decoration: none;
        @media (max-width: 991.98px) {
          min-height: 24px;
          min-width: 24px;
          text-align: center;
          text-decoration: none;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.25px;
          color: #333333;
        }
      }

      a {
        &:hover {
          background: $cl-cinza2;
        }
      }

      &.active {
        span,
        a {
          background: $cl-maximun-red;
          color: $cl-branco;
          font-weight: 600;
          @media (max-width: 991.98px) {
            background: #bdbdbd;
            color: #333333;
          }
        }
      }

      &.disabled {
        span,
        a {
          color: $cl-cinza4;
        }
      }
    }
  }

  &.bp {
    .pagination {
      margin: 0;

      @include media-breakpoint-down(md) {
        align-items: center;
      }

      @include media-breakpoint-down(lg) {
        align-items: center;
      }

      li {
        a,
        span {
          font-size: 16px;
          line-height: 24px;
          text-decoration: underline;
          letter-spacing: 0.5px;
          color: $cl-gray-davys;
          margin-right: 4px;

          &:hover {
            background: none;
          }
        }

        &.active {
          span {
            background: none;
            font-weight: bold;
            text-decoration: none;
            color: $cl-gray-davys;
          }
        }

        &:first-child {
          margin-right: 10px;
        }

        &:last-child {
          margin-left: 10px;
        }

        &:first-child,
        &:last-child {
          width: 40px;
          height: 40px;
          background: $cl-maximun-red;
          border-radius: 50px;
          text-decoration: none;
          color: $cl-branco;
          line-height: 18px;

          a {
            font-size: 0;
            text-decoration: none;
            color: $cl-branco;
            width: 12px;
            height: 12px;
            min-width: 12px;
            min-height: 12px;
            margin: auto;
            line-height: 30px;

            &::before {
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              content: "\f105";
              font-size: 18px;
              line-height: 26px;
              margin-left: 2px;
            }
          }

          span {
            font-size: 0;
            text-decoration: none;
            color: $cl-branco;
            line-height: 28px;
            width: 12px;
            height: 12px;
            min-width: 12px;
            min-height: 12px;
            margin: 0 auto;

            @include media-breakpoint-down(md) {
              line-height: 40px;
            }

            @include media-breakpoint-down(lg) {
              line-height: 40px;
            }

            &::before {
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              content: "\f104";
              font-size: 18px;
            }
          }
        }

        &:first-child {
          a {
            &::before {
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              content: "\f104";
              font-size: 18px;
              line-height: 26px;
              margin-left: 2px;

              @include media-breakpoint-down(lg) {
                line-height: 40px;
              }
            }
          }
        }

        &:last-child {
          &.disabled {
            opacity: 0.5;
            span {
              &::before {
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                content: "\f105";
                font-size: 18px;
              }
            }
          }
          a {
            &::before {
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              content: "\f105";
              font-size: 18px;
              line-height: 26px;
              margin-left: 2px;

              @include media-breakpoint-down(lg) {
                line-height: 40px;
              }
            }
          }
        }
      }
    }
  }
}