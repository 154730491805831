/* Custom Mixins -------------------- */
@mixin gradiente-linear($cor1, $cor2) {
    background: $cor1;
    background: -moz-linear-gradient(left, $cor1 0%, $cor2 100%);
    background: -webkit-linear-gradient(left, $cor1 0%, $cor2 100%);
    background: linear-gradient(to right, $cor1 0%, $cor2 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$cor1, endColorstr=$cor2, GradientType=1);
}

@mixin border-radius {
    border-radius: 6px;
}

@mixin texto-1 {
    font-size: 13px;
    color: $cl-texto;
    font-weight: 400;
    text-decoration: none solid rgb(79, 79, 79);
    line-height: 20px;
}

@mixin box-branco {
    background: $cl-branco;
    @include border-radius;
    padding: 20px;
}

@mixin width-flex($px) {
    width: $px;
    max-width: $px;
    flex: 0 0 $px;
    padding: 0 16px;
}

@mixin transition {
    transition: all 300ms ease-in-out;

    &:hover {
        transition: all 300ms ease-in-out;
    }
}

@mixin bar-gradient() {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#a65477+0,eb1f40+48,f69831+100 */
    background: rgb(166, 84, 119);
    /* Old browsers */
    background: -moz-linear-gradient(left, rgba(166, 84, 119, 1) 0%, rgba(235, 31, 64, 1) 48%, rgba(246, 152, 49, 1) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(166, 84, 119, 1) 0%, rgba(235, 31, 64, 1) 48%, rgba(246, 152, 49, 1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(166, 84, 119, 1) 0%, rgba(235, 31, 64, 1) 48%, rgba(246, 152, 49, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a65477', endColorstr='#f69831', GradientType=1);
    /* IE6-9 */
    content: '';
    width: 80%;
    margin: 15px 10%;
    height: 4px;
    border-radius: 20px;
}

@mixin mobile-titulo-back() {
    position: relative;
    padding: 0 0 0 0px;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.25px;
    color: #333333;
}

// Botão com tamanho dinamico
@mixin dynamic-gray-btn($width) {
    display: flex !important;
    flex-direction: column !important;
    padding: 0px !important;
    width: $width !important;
    max-width: $width !important;
    height: 46px !important;
    left: 185px !important;
    top: 0px;
}