._store {
    .hidden {
        display: none;
    }

    form {
        width: 100%;
    }

    .payment-methods {
        menu {
            margin: 0;
            padding: 0;
        }

        .payment-methods .payment-method {
            padding-top: 1rem;
        }
    }

    .box {
        background-color: #fff;
        padding: 1rem;
        border-radius: .5rem;
    }

    .mt-4 {
        margin-top: 1rem!important;
    }

    table {
        text-align: left;
        width: 100%;

        tbody:first-child tr:first-child td:first-child,
        tbody:first-child tr:first-child th:first-child,
        thead tr:first-child td:first-child,
        thead tr:first-child th:first-child {
            border-top-left-radius: .5rem;
        }

        tbody:first-child tr:first-child td:last-child,
        tbody:first-child tr:first-child th:last-child,
        thead tr:first-child td:last-child,
        thead tr:first-child th:last-child {
            border-top-right-radius: .5rem;
        }

        tbody:last-child tr:last-child td:first-child,
        tbody:last-child tr:last-child th:first-child,
        tfoot:last-child tr:last-child td:first-child,
        tfoot:last-child tr:last-child th:first-child {
            border-bottom-left-radius: .5rem;
        }

        tbody:last-child tr:last-child td:last-child,
        tbody:last-child tr:last-child th:last-child,
        tfoot:last-child tr:last-child td:last-child,
        tfoot:last-child tr:last-child th:last-child {
            border-bottom-right-radius: .5rem;
        }

        &.cart-resume td,
        &.cart-resume th {
            vertical-align: top;
        }
    
        tr {
            td,
            th {
                background-color: #fff;
                border-bottom-width: 1px;
                border-color: #ececec;
                padding: .5rem;
            }
        }

        @media (min-width: 768px) {
            tr {
                td,
                th {
                    padding: 1rem;
                }
            }
        }

        thead th {
            background-color: #454545;
            color: #fff;
            border-bottom-left-radius: 0!important;
            border-bottom-right-radius: 0!important;
        }
    }
}
