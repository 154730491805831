//Custom variables

// Descriptive color variables

// Functional color variables
$cl-cinza2: #e0e0e0;
$cl-cinza3: #2b2b2b;
$cl-cinza4: #adadad;
$cl-cinza5: #e6e7e8;
$cl-cinza6: #FEFEFE;
$cl-texto: #4f4f4f;
$cl-texto2: #363636;
$cl-texto3: #666666;
$cl-rosa2: #d11b3a;
$cl-rosa3: #b81832;
$cl-amarelo: #f69831;
$cl-roxo: #782861;
$cl-preto: #000;
$cl-branco: #fff;
$cl-vermelho: #b81832;

// new colors
$cl-maximun-red: #D12E26;
$cl-gray-jet: #333333;
$cl-gray-davys: #565656;
$cl-gray-platinum: #EAE8E8;
$cl-orange-mandarin: #EB6D3B;
$cl-white: #FFFFFF;
$cl-silver-chalice: #ABABAB;
$cl-silver-cultured: #F7F7F7;

// nova Identidade visual genera 01/09/2024
$graydark-genera: #262626;
$red-genera: #D40A57;
$purple-genera: #441539;
$orange-genera: #f46522;
$magenta-genera: #c550d6;
$green-genera: #2BAD3D;
$blue-genera: #00B6D8;
$yellow-genera: #F57900;


$cl-europa: #D12E26;
$cl-judaica: #f69831;
$cl-orientemedio: #782861;
$cl-africa: #6fb529;
$cl-lesteasiatico: #b8336a;
$cl-americas: #ff4d80;
$cl-outros: #002642;

$cl-yellow-munsel: #EBCA1F;
$cl-cyan-process: #1FAEEB;

// PX to REM
$eight-px: .5rem;
$fourteen-px: .875rem;
$sixteen-px: 1rem; 
$eighteen-px: 1.125rem;
$twenty-px: 1.25rem;
$twenty-four-px: 1.5rem;
$twenty-five-px: 1.5625rem;
$twenty-eight-px: 1.75rem;
$thirty-px: 1.875rem;
$forty-px: 2.5rem;
$forty-eight-px: 3rem;

// Font stacks
$ff-open-sans: 'Open Sans', sans-serif;
$ff-roboto: 'Roboto', sans-serif;
$ff-cabin: 'Cabin';
$ff-mulish: 'Mulish', sans-serif;
$ff-dasa: 'Dasa Sans', sans-serif;

$fw-book: 100;
$fw-regular: 300;
$fw-medium: 400;
$fw-semibold: 600;
$fw-bold: 700;

// Asset paths
$path-image:  '../img';
$path-fonts:  '../fonts';

@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Cabin:400,500&display=swap');
@import url('https://alma-assets.dasa.com.br/fonts/woff/DasaSans-Regular.woff');

@font-face {
    font-family: 'Font Awesome 5 Brands';
    font-style: normal;
    font-weight: normal;
    font-display: auto;
    src: url('#{$path-fonts}/fontawesome-free/fa-brands-400.eot');
    src: url('#{$path-fonts}/fontawesome-free/fa-brands-400.eot?#iefix') format("embedded-opentype"),
    url('#{$path-fonts}/fontawesome-free/fa-brands-400.woff2') format("woff2"),
    url('#{$path-fonts}/fontawesome-free/fa-brands-400.woff') format("woff"),
    url('#{$path-fonts}/fontawesome-free/fa-brands-400.ttf') format("truetype"),
    url('#{$path-fonts}/fontawesome-free/fa-brands-400.svg#fontawesome') format("svg");
}
@font-face {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url('#{$path-fonts}/fontawesome-free/fa-regular-400.eot');
    src: url('#{$path-fonts}/fontawesome-free/fa-regular-400.eot?#iefix') format("embedded-opentype"),
    url('#{$path-fonts}/fontawesome-free/fa-regular-400.woff2') format("woff2"),
    url('#{$path-fonts}/fontawesome-free/fa-regular-400.woff') format("woff"),
    url('#{$path-fonts}/fontawesome-free/fa-regular-400.ttf') format("truetype"),
    url('#{$path-fonts}/fontawesome-free/fa-regular-400.svg#fontawesome') format("svg");
}
@font-face {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 900;
    font-display: auto;
    src: url('#{$path-fonts}/fontawesome-free/fa-solid-900.eot');
    src: url('#{$path-fonts}/fontawesome-free/fa-solid-900.eot?#iefix') format("embedded-opentype"),
    url('#{$path-fonts}/fontawesome-free/fa-solid-900.woff2') format("woff2"),
    url('#{$path-fonts}/fontawesome-free/fa-solid-900.woff') format("woff"),
    url('#{$path-fonts}/fontawesome-free/fa-solid-900.ttf') format("truetype"),
    url('#{$path-fonts}/fontawesome-free/fa-solid-900.svg#fontawesome') format("svg");
}
@font-face {
    font-family: 'Dasa Sans';
    src: url('https://alma-assets.dasa.com.br/fonts/eot/DasaSans-Bold.eot');
    src: url('https://alma-assets.dasa.com.br/fonts/eot/DasaSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('https://alma-assets.dasa.com.br/fonts/woff/DasaSans-Bold.woff') format('woff'),
        url('https://alma-assets.dasa.com.br/fonts/woff2/DasaSans-Bold.woff2') format('woff2');
    font-weight: 700;
}
@font-face {
    font-family: 'Dasa Sans';
    src: url('https://alma-assets.dasa.com.br/fonts/eot/DasaSans-Semibold.eot');
    src: url('https://alma-assets.dasa.com.br/fonts/eot/DasaSans-Semibold.eot?#iefix') format('embedded-opentype'),
        url('https://alma-assets.dasa.com.br/fonts/woff/DasaSans-Semibold.woff') format('woff'),
        url('https://alma-assets.dasa.com.br/fonts/woff2/DasaSans-Semibold.woff2') format('woff2');
    font-weight: 600;
}
@font-face {
    font-family: 'Dasa Sans';
    src: url('https://alma-assets.dasa.com.br/fonts/eot/DasaSans-Medium.eot');
    src: url('https://alma-assets.dasa.com.br/fonts/eot/DasaSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('https://alma-assets.dasa.com.br/fonts/woff/DasaSans-Medium.woff') format('woff'),
        url('https://alma-assets.dasa.com.br/fonts/woff2/DasaSans-Medium.woff2') format('woff2');
    font-weight: 500;
}
@font-face {
    font-family: 'Dasa Sans';
    src: url('https://alma-assets.dasa.com.br/fonts/eot/DasaSans-Regular.eot');
    src: url('https://alma-assets.dasa.com.br/fonts/eot/DasaSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('https://alma-assets.dasa.com.br/fonts/woff/DasaSans-Regular.woff') format('woff'),
        url('https://alma-assets.dasa.com.br/fonts/woff2/DasaSans-Regular.woff2') format('woff2');
    font-weight: 400;
}
@font-face {
    font-family: 'Dasa Sans';
    src: url('https://alma-assets.dasa.com.br/fonts/eot/DasaSans-Light.eot');
    src: url('https://alma-assets.dasa.com.br/fonts/eot/DasaSans-Light.eot?#iefix') format('embedded-opentype'),
        url('https://alma-assets.dasa.com.br/fonts/woff/DasaSans-Light.woff') format('woff'),
        url('https://alma-assets.dasa.com.br/fonts/woff2/DasaSans-Light.woff2') format('woff2');
    font-weight: 300;
}