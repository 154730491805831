.circle-progress {

    .region-progress {
        position: absolute;
    }

    .progress {
        background: none;
        position: relative;
        width: 104px;
        height: 104px;
    }

    .progress::after {
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .region-progress {
        .progress::after {
            display: none;
        }
    }

    .progress>span {
        width: 50%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        z-index: 1;
    }

    .progress .progress-left {
        left: 0;
    }

    .progress .progress-bar {
        width: 100%;
        height: 100%;
        background: none;
        border-width: 5px;
        border-style: solid;
        position: absolute;
        top: 0;
        border-radius: inherit;

        &.border-primary {
            border-color: $cl-maximun-red !important;
        }

        &._african {
            border-color: $cl-africa !important;
        }

        &._centralsouth-asian {
            border-color: $cl-lesteasiatico !important;
        }

        &._east-asian {
            border-color: $cl-lesteasiatico !important;
        }

        &._european {
            border-color: $cl-europa !important;
        }

        &._jewish-diaspora {
            border-color: $cl-judaica !important;
        }

        &._middle-eastern {
            border-color: $cl-orientemedio !important;
        }

        &._new-world {
            border-color: $cl-americas !important;
        }

        &._trace-results {
            border-color: $cl-outros !important;
        }
    }

    .progress .progress-left .progress-bar {
        left: 100%;
        border-top-right-radius: 80px;
        border-bottom-right-radius: 80px;
        border-left: 0;
        -webkit-transform-origin: center left;
        transform-origin: center left;
    }

    .progress .progress-right {
        right: 0;
    }

    .progress .progress-right .progress-bar {
        left: -100%;
        border-top-left-radius: 80px;
        border-bottom-left-radius: 80px;
        border-right: 0;
        -webkit-transform-origin: center right;
        transform-origin: center right;
    }

    .progress .progress-value {
        position: absolute;
        top: 0;
        left: 0;

        img {
            border-radius: 50%;
            width: 94px;
            height: 94px;
        }
    }
}