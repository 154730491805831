@import 'bootstrap/scss/bootstrap';
// @import 'bootstrap/bootstrap-user-variables';
// @import 'bootstrap/bootstrap-reboot';
// @import 'bootstrap/bootstrap';
// @import 'bootstrap/bootstrap-grid';
@import 'cropperjs/cropper.min';
@import 'fontawesome-free/css/all';
@import 'switcher/switcher';
@import 'tiny-slider/tiny-slider';

.cropper-container {
  margin: 0 auto;
  height: 460px;
  width: 460px;

  @media (max-width: 1200px) {
    height: 280px;
    width: 280px;
  }
}

.img-crop img {
  max-width: 100%;
}

.cropper-view-box,
.cropper-face {
  border-radius: 50%;
}
