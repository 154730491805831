//Buttons, links e anchors

a,
button {
    &.csv-download {
        border: none;
        box-shadow: none;
        padding: 0;
        width: 70px;
        height: 70px;
        &:hover {
            text-decoration: underline;
        }
        &:focus {
            outline: none;
        }
        img {
            max-width: 35px;
        }
    }

    &.default-red-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        padding: 0 50px;
        height: 50px;
        background: $cl-maximun-red;
        border-radius: 40px;
        border: none;
        box-shadow: none;
        font-weight: bold;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.25px;
        color: #FFFFFF;
        &:hover {
            text-decoration: underline;
        }
        &:focus {
            outline: none;
        }
        &.new-cep {
            margin: 288px auto 0;
        }
    }

    &.default-red-btn-no-bg {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 188px;
        max-width: 188px;
        height: 40px;
        background: #fff;
        border-radius: 40px;
        border: none;
        box-shadow: none;
        font-weight: bold;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.25px;
        color: $cl-maximun-red;
        &:hover {
            text-decoration: underline;
        }
        &:focus {
            outline: none;
        }
        &.new-cep {
            margin: 288px auto 0;
        }
    }

    &.default-gray-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 102px;
        max-width: 188px;
        height: 46px;
        background: #ffffff;
        border-radius: 40px;
        border: 2px solid #333333;
        box-shadow: none;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.25px;
        color: #333333;
        &:hover {
            text-decoration: underline;
            background-color: #333333;
            color: #ffffff;
        }
        &:focus {
            outline: none;
        }
    }
    &.gray-no-bg {
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        color: #333333;
        text-align: center;
        text-decoration: underline;
        text-decoration: underline;
        border: none;
        box-shadow: none;
        background: none;

        &.bottom-zero {
            position: absolute;
            bottom: 0;
            border: none;
            box-shadow: none;
            background: none;

            @media (min-width: 991px) {
                bottom: 20px;
            }
            &.no-bottom {
                position: relative;
                margin-bottom: 20px;
            }
        }
    }
    &.button-link {
        font-family: Mulish;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: 0.25px;
        text-decoration-line: underline;
        color: $cl-gray-jet;
        text-decoration: underline;
        text-decoration: underline;
        border: none;
        box-shadow: none;
        background: none;

        &:hover {
            background: transparent;
            color: #333;
        }
    }

    &.a-back {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.5px;
        color: $cl-gray-jet;
        z-index: 1;

        i {
            margin-right: 6px;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}

.view {
    .dhl,
    .box-coleta {
        .accordion-list {
            width: 100%;
            position: relative;

            .accordion-list-item {
                cursor: pointer;
                position: relative;
                background: $cl-silver-cultured;
                margin: 1rem 0;
                padding-bottom: 1rem;
                border-bottom: 1px solid rgba(22, 22, 22, 0.12);

                &:first-child {
                    margin: 0 0 1rem 0;
                }

                .accordion-box-title {
                    display: flex;
                    justify-content: space-between;

                    @include media-breakpoint-down(md) {
                        gap: .5rem;
                    }

                    h5 {
                        line-height: 150%;
                        --text-size: 1rem;
                        --text-color: #363636;
                        --text-weight: 500;
                        margin: 0;
                    }
                }
    
                .ni-minus {
                    &:before {
                        content: '';
                        display: block;
                        background-image: url('/themes/genera/assets/img/arrow.svg'); 
                        background-position: center;
                        width: 24px; 
                        height: 24px;
                        transform: rotateZ(92deg);
                        transition: all .3s ease-in-out;
                    }
                }
                
                .ni-plus {
                    &:before {
                        content: '';
                        display: block;
                        background-image: url('/themes/genera/assets/img/arrow.svg'); 
                        background-position: center;
                        width: 24px; 
                        height: 24px;
                        transform: rotateZ(269deg);
                        transition: all .3s ease-in-out;
                    }
                }
    
                .accordion-desc {
                    cursor: text;
                    margin-top: .5rem;

                    p {
                        text-align: left;
                        margin: 0;
                        --text-color: #666666;
                        --text-letter-spacing: 0;
                        line-height: 170%;
                        
                        @include media-breakpoint-down(md) {
                            --text-letter-spacing: .5px;
                        }

                        a, a:hover {
                            text-decoration: underline;
                            color: #D12E26;
                            z-index: 99999;
                            position: relative;
                        }
                    }
                }
            }
        }
    } 
}
//images dhl
img {
    &.new-register {
        width: 80px;
        height: 80px;
    }
}

//display flexbox

.flex-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* display 3 */
.carousel-inner {
    .carousel-item-right.active,
    .carousel-item-next {
        transition: all 0.8s ease-in;
    }
}

.carousel-control-prev {
    left: -18%;
    top: 25px;
    height: 160px;
    opacity: 1;
    display: none;

    @include media-breakpoint-down(md) {
        left: -21.5%;
    }

    @include media-breakpoint-down(md) {
        left: -21.5%;
    }

    @include media-breakpoint-lg2() {
        left: -16.7%;
    }
}

.carousel-control-next {
    right: -16%;
    top: 25px;
    opacity: 1;
    display: none;

    @include media-breakpoint-down(md) {
        right: -12.5%;
    }

    @include media-breakpoint-down(md) {
        right: -12.5%;
    }
    
    @include media-breakpoint-lg2() {
        right: -8%;
    }
}

//extend bootstrap to dhl carousel

@include media-breakpoint-down(md) {
    .carousel-inner {
        .carousel-item {
            > div {
                display: none;
            }
            > div:first-child {
                display: block;
            }
        }
        .carousel-item.active,
        .carousel-item-next,
        .carousel-item-prev {
            display: block;
        }
    }
    .carousel-control-prev {
        left: -24px;
        top: 3px;
        height: 160px;
        opacity: 1;
        display: none;
    }
    .carousel-control-next {
        right: -24px;
        top: 3px;
        height: 160px;
        opacity: 1;
        display: none;
    }
}

@include media-breakpoint-down(lg) {
    .carousel-inner {
        .carousel-item {
            > div {
                display: none;
            }
            > div:first-child {
                display: block;
                margin: auto;
            }
        }
        .carousel-item.active,
        .carousel-item-next,
        .carousel-item-prev {
            display: block;
        }
    }

    .carousel-control-prev {
        left: -8px;
        top: 3px;
        height: 160px;
        opacity: 1;
        display: none;
    }
    .carousel-control-next {
        right: -8px;
        top: 3px;
        height: 160px;
        opacity: 1;
        display: none;
    }
}

.carousel-inner {
    .carousel-item {
        &.active {
            display: flex;
        }
        .carousel-item-next,
        .carousel-item-prev {
            display: flex;
        }
        .edit-after {
            width: 100% !important;
        }
    }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
    display: none;
}

// ALERTS

.alert-danger {
    background: #D12E26;
    color: #ffff;
}

.alert-success {
    background: linear-gradient(91.15deg, #317B34 -21.75%, #2DC528 118.2%);
    color: #fff;
}

.alert-genera {
    background: linear-gradient(91.15deg, #74285C -21.75%, #D12E26 118.2%);
    color: #fff;
    margin-bottom: 0;
    border-radius: 0;
    border: none;
    font-family: 'Mulish', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: .5px;
}

.box-btn-return-back {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 100%;
    margin-top: 1rem;

    .btn-return-back {
        width: 50%;
        display: flex;
        justify-content: space-around;

        @include media-breakpoint-down(md) {
            justify-content: flex-start;
            margin-left: 1rem;
        }

        @include media-breakpoint-down(md) {
            justify-content: flex-start;
            margin-left: 1rem;
        }
    }
}
