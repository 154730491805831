

.tooltip-cover {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999;
}

.tooltip-header {
    padding: 16px;

    @include media-breakpoint-down(md) {
        max-width: 97%;
    }
}

.tooltip-text {
    padding: 0;
    max-width: 444px !important;
    text-align: start;
    font-size: 14px !important;
    letter-spacing: 0.5px;

    .title {
        font-weight: 700;
        margin-bottom: 5px;
    }
}

//MGM
.mgm-code, .active-subscription {
    margin-top: 16px;

    .input-fake {
        border: 1px solid #D4D4D4;
        border-radius: 4px;
        padding: 13px 15px;
        .mgm-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            p {
                font-family: 'Mulish';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.5px;
                color: #333333;
                margin-bottom: 0;
            }
            input {
                display: none;
            }
            img {
                cursor: pointer;
            }
        }
    }
    .mgm-whatsapp {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 12px 0 16px;
        padding: 12px 16px;
        gap: 8px;
        width: 211px;
        height: 42px;
        background: #008E1F;
        border-radius: 40px;
        cursor: pointer;
        p {
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            color: #fff;
            margin-bottom: 0;
        }
    }
    .mgm-rules {
        color: #565656;
        small {
            margin-top: 16px;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 600;
            font-size: 11px;
            line-height: 14px;
        }
    }
    .copy-btn {
        border: none;
        box-shadow: none;
        background: none;
        outline: none;
    }
}

.view {

    .new-form {
        //get form css
        @import 'genera-components/_forms';
    }

    &._linhagem {
        position: relative;
        z-index: 2;

        &._prs {
            &.print {
                z-index: 99999;
            }
        }

        .maternal-lineage-content,
        .paternal-lineage-content {

            .groups-lineage {
                display: flex;
                flex-wrap: wrap;
                margin: -20px 0 0;

                .col-lg-4 {
                    padding: 16px 16px 0 0;

                    &:nth-child(3n) {
                        padding: 16px 0 0;

                        @include media-breakpoint-down(md) {
                            padding: 0 16px 0 0;
                        }
                
                        @include media-breakpoint-down(lg) {
                            padding: 0 16px 0 0;
                        }
                    }

                    @include media-breakpoint-down(md) {
                        padding: 0 16px 0 0;
                    }
            
                    @include media-breakpoint-down(lg) {
                        padding: 0 16px 0 0;
                    }
                }
            }

            margin-top: -32px;
            padding-top: 0;

            @include media-breakpoint-down(md) {
                margin-top: 0;
            }
    
            @include media-breakpoint-down(lg) {
                margin-top: 0;
            }
        }
        
        .prs-content {
            padding: 0 26px 48px 26px;
            margin: -88px auto 0;
            
            @include media-breakpoint-down(md) {
                padding-top: 24px;
                margin: 0 auto;
            }
            
            @include media-breakpoint-down(lg) {
                padding-top: 24px;
                margin: 0 auto;
            }
        }

        .prs-disease-content {
            margin-top: -32px;
            padding: 0 26px 48px 26px;

            @include media-breakpoint-down(md) {
                margin-top: 0;
                padding: 24px 26px 48px 26px;
            }
            
            @include media-breakpoint-down(lg) {
                margin-top: 0;
                padding: 24px 26px 48px 26px;
            }
        }
    }

    #container-prs {
        width: calc(100% + 25px);
        min-height: 100%;
        background: none;
        display: block;
        position: relative;

        @include media-breakpoint-down(md) {
            width: 100%;
            flex-direction: column-reverse;
            clear: both;
        }

        @include media-breakpoint-down(lg) {
            width: 100%;
            max-width: 100%;
            flex-direction: column-reverse;
            clear: both;
        }
    }

    #container-prs-print {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: auto;
        min-height: 100%;
        background: none;
        position: relative;
        padding: 0;
        margin-top: 26px;

        @media print {
            margin-top: -15px;
        }

        div.d-flex {
            width: 100%;
        }

        @include media-breakpoint-down(md) {
            width: 100%;
            flex-direction: column-reverse;
            clear: both;
        }

        @include media-breakpoint-down(lg) {
            width: 100%;
            max-width: 360px;
            flex-direction: column-reverse;
            clear: both;
        }

        @media print {
            @page {
                size: auto;   /* auto is the initial value */
            }

            padding: 0;
        }

        .prs-print {
            width: 95%;

            &.colum-3 {
                &.print {
                    margin-top: -5px;
                }

                .prs-cards {
                    .prs-card {
                        width: calc(33% - 16px) !important;
                        max-width: calc(33% - 16px);
                        height: 300px !important;
    
                        &:nth-child(3n+0) {
                            margin-right: 0px;
                        }
        
                        &:nth-child(4n+0) {
                            margin-right: 16px;
                        }

                        .barOverflow {
                            width: 245px;
                            height: 122px;
                        }

                        .bar {
                            width: 245px;
                            height: 245px;
                        }

                        .prs-3th-card {
                            max-height: 215px;
                        }
                    }
                }

                .prs-half-circle {
                    height: 195px;
                }

                .what-to-do {
                    width: calc(100% + 25px);
                    min-width: 0;
                    max-width: 1015px;
                    margin: 16px 0 0;
                    padding: 0;
                    
                    @media print {
                        max-width: 246mm;
                        padding: 16px;
                        margin: 16px 26px 0 26px;
                        display: flex;
                        justify-content: flex-start;
                        min-height: 315px;
                    }

                    .what-to-do-txt {
                        width: 672px;
                        min-width: 672px;
                        min-height: 344px;

                        &.print {
                            width: 100%;
                            min-width: 1015px;

                            @media print {
                                max-width: 248mm;
                                height: 315px;
                                min-height: 315px;
                                margin: 0 9px 0 0;
                                padding: 0;
                                min-width: 0;
                            }
                        }
                    }
                }

                .red-box-results {
                    width: 327.19px;

                    @media print {
                        width: 294.18px;
                        max-height: 315px;
                        margin: 0;
                    }
                }

                .additional-information {
                    width: 100%;
                    max-width: 1015px;

                    @media print {
                        max-width: 246mm;
                        padding: 0px;
                        margin: 16px 26px 0 26px;
                        filter: none;
                    }
                    
                    .gen-card-body {
                        @media print {
                            --card-body-padding-x: 1rem;
                            --card-body-padding-y: 1rem;
                        }
                    }

                    .txt-additional-information {
                        h3 {
                            margin-bottom: 5px;
                        }
                    }

                    .table-row {
                        height: 100% !important;
                    }
                }

                .table-row {
                    @media print {
                        margin: 0 !important;
                        height: 140px !important;
                    }
                }
                
                .bibliographic-reference {
                    width: 100%;
                    max-width: 1015px;
                    min-height: 184px;
                    
                    @media print {
                        width: 246mm;
                        max-width: 246mm;
                        min-width: 246mm;
                        margin: 16px 26px 0 26px;
                    }
                }
            }

            .prs-cards {
                width: calc(100% + 15px);
                max-width: 275mm;

                @media print {
                    width: 990px !important;
                    padding: 0 8px 0 25px;
                    margin: 0;
                    gap: 16px;
                }

                .prs-card {
                    z-index: 99999;
                    max-width: calc(25% - 16px);

                    @media print {
                        width: calc(25% - 16px) !important;
                        max-width: calc(25% - 16px);
                        max-height: 282px;
                    }

                    &:nth-child(3n+0) {
                        margin-right: 16px;

                        @media print {
                            margin: 0;
                        }
                    }
    
                    &:nth-child(4n) {
                        margin-right: 0;
                        margin-top: 0;
                    }

                    &:nth-child(8n),
                    &:nth-child(12n),
                    &:nth-child(16n),
                    &:nth-child(18n),
                    &:nth-child(22n) {
                        margin-top: 16px;
                        
                        @media print {
                            margin-top: 0;
                        }
                    }

                    .barOverflow {
                        width: 210px;
                        height: 105px;
                        
                        @media print {
                            width: 180px;
                            height: 88px;
                        }
                    }

                    .bar {
                        width: 210px;
                        height: 210px;

                        @media print {
                            width: 175px;
                            height: 175px;
                        }
                    }

                    .disease_prs_high_risk,
                    .disease_prs_medium_risk,
                    .disease_prs_low_risk {
                        img {
                            display: none;
                        }
                    }
                }
            }

            .icon-arrow-right {
                display: none;
            }
        }

        &.prs-print-detail {
            .colum-3 {
                .prs-cards {
                    .prs-card {
                        &:nth-child(3n+0) {
                            margin: 0;
                        }
                    }
                }
            }
        }

        .prs-description {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-radius: 6px;
            background-color: $cl-white;
            padding: 24px;
            margin: 195px 0 24px 0;

            @media print {
                max-width: 248mm;
                margin: 80px 26px 0 26px;
            }

            p {
                font-family: Mulish;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0.5px;
                text-align: left;
                margin: 0;
            }
        }
    }

    >.container {
        padding-top: 16px;
        padding-bottom: 15px;
        min-height: 100%;
        background: none;
        display: block;
        position: relative;

        @include media-breakpoint-down(md) {
            flex-direction: column-reverse;
            clear: both;
            padding-top: 0;
            margin-top: -32px;
        }

        @include media-breakpoint-down(lg) {
            flex-direction: column-reverse;
            clear: both;
            padding-top: 24px;
        }

        &.schedule-content,
        &.feature-content,
        &.diseases-content {
            padding: 0;
            margin-top: -32px;

            @include media-breakpoint-down(md) {
                padding-top: 16px;
                margin-top: 0;
                max-width: 660px;
            }
    
            @include media-breakpoint-down(lg) {
                padding-top: 16px;
                margin-top: 0;
            }
        }

        &.feature-content {
            margin-bottom: 20px;
            padding-top: 0;
            margin-top: -32px;

            @include media-breakpoint-down(lg) {
                margin: -60px auto 20px;
                align-items: center;
                gap: 16px;
            }
            @include media-breakpoint-down(xs) {
                gap: 0;
            }
        }
    }

    .title-coluna {
        @include texto-1;
        color: #757575;
        margin-bottom: 14px;
        margin-top: 0;
    }

    .coluna-1 {
        margin: 0 15px 0 0 !important;

        @include width-flex(308px);

        @include media-breakpoint-down(lg) {
            display: none;
        }

        @media (max-width: 767px) {
            @include width-flex(90%);
            margin: 0 auto;
        }

        background: #FFFFFF;
        border-radius: 6px;
        padding: 24px;

        &.menu {
            
            padding: 0;
            background: none;

            .box-schedule-results {
                padding: 24px;
                background: $cl-branco;
                border-radius: 6px;
                width: 100%;
                margin-bottom: 0;

                p {
                    position: relative;
                    display: flex;
                    align-items: center;
                    align-content: center;
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    letter-spacing: .5px;
                    color: $graydark-genera;
                    margin: 0;
                    padding-left: 1.3rem;
        
                    &:before {
                        content: '';
                        position: absolute;
                        opacity: 1;
                        background: $red-genera;
                        left: 0;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        z-index: 0;
                    }
                }

                a {
                    text-decoration: none;
                    &:hover {
                       color: $cl-gray-jet; 
                       text-decoration: none;
                    }
                }
            }

            .menu-assinatura{
                display: flex;
                justify-content: center;
                flex-direction: column;
                gap: 24px;
                align-items: center;
                width: 100%;
                height: auto;


                .gen-card {
                    width: 100%;
                }

                .mgm-code{
                    margin-top: 0;
                }

                .active-subscription{
                    margin-top: 0;
                    background-color: $purple-genera;

                    .gen-text,p{
                        color: $cl-white !important;
                    }

                    .active-subscription-button{
                        background-color: $red-genera;
                        color: $cl-white;
                        border: none;
                        box-shadow: none;
                        padding: 8px 12px;
                        border-radius: 24px;
                        font-family: Mulish;
                        font-weight: 700;
                    }

                }

            }
        }
    }

    .coluna-2 {
        margin: 0px 15px 0 0;
        @include width-flex(300px);
        opacity: 1;
        transition: all 600ms ease;
        padding: 0;

        @include media-breakpoint-down(md) {
            display: block;        
        }

        @include media-breakpoint-down(lg) {
            display: grid !important;
        }

        @media (max-width: 1500px) {
            @include width-flex(300px);
        }

        @include media-breakpoint-down(md) {
            @include width-flex(100%);
            margin: 0 auto;

            .title-coluna {
                display: none;
            }
        }

        @media (max-width: 767px) {
            @include width-flex(100%);
            margin: 0 auto;
        }

        .title-coluna {
            opacity: 0.7;
        }
        ._partial-regiao-collapse {
            @include media-breakpoint-down(md) {
                margin: $sixteen-px 0;
            }

            @include media-breakpoint-down(lg) {
                margin: $sixteen-px 0;
            }
            @include media-breakpoint-down(xs) {
               width: 358px;
               margin: $sixteen-px auto;
            }

            @media (max-width: 400px) {
                width: 100%;
            }

        }
        .minutrade-banner {
            @include width-flex(300px);
        }
    }

    .minutrade-banner {
        display: none;
        margin-bottom: 10px;

        @include media-breakpoint-down(md) {
            display: block;        
        }

        @include media-breakpoint-down(lg) {
            display: block;
        }
    }

    .coluna-3 {
        margin: 0px 0px 0 0;
        position: relative;
        transition: all 600ms ease-in-out;
        width: calc(100% - 616px);

        &.full {
            width: calc(100% - 316px);
        }

        @media (max-width: 1500px) {
            width: calc(100% - 630px);

            &.full {
                width: calc(100% - 316px);
            }
        }

        @include media-breakpoint-between(md, lg) {
            width: calc(100% - 316px);
        }

        @include media-breakpoint-down(md) {
            @include width-flex(100%);
            margin: 0;

            &.full {
                width: 100%;
            }

            .title-coluna {
                display: none;
            }
        }

        @media (max-width: 767px) {
            width: 100%;

            &.full {
                width: 100%;
            }
        }

        .title-coluna {
            opacity: 0.7;
        }

        ._partial-perfil-ances {
            justify-content: space-between;

            @include media-breakpoint-down(md) {
                margin: 0;
            }

            @include media-breakpoint-down(lg) {
                margin: 0;
                max-width: 345px;
            }
        }

        ._partial-regiao-collapse {
            display: none;
           

            @include media-breakpoint-down(xs) {
                width: 358px;
                margin: $sixteen-px auto;
            }

            @media (max-width: 400px) {
                width: 100%;
            }

        }

        .conteudo {
            margin: 0;
            min-height: 400px;
            transition: all 600ms ease;

            &.subregion {
                @include media-breakpoint-down(lg) {
                    max-width: 345px;
                    margin-top: 1rem;
                }
                
                .content {
                    h3 {
                        display: flex;
                        align-items: center;
                        line-height: 25px;
                        height: 32px;

                        @include media-breakpoint-down(md) {
                            padding: 0;
                        }
            
                        @include media-breakpoint-down(lg) {
                            padding: 0;
                        }
                    }

                    article {
                        margin: 0;

                        h2 {
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 24px;
                            letter-spacing: 0.5px;
                            margin: 0 0 16px 0;
                        }

                        p {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 24px;
                            color: $cl-gray-davys;
                            margin-bottom: 32px;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }

                    .carousel {
                        figure {
                            margin-bottom: 16px;

                            &.img-subregion-desktop {
                                @include media-breakpoint-down(md) {
                                    display: none;
                                }
    
                                @include media-breakpoint-down(lg) {
                                    display: none;
                                }
                            }

                            &.mobile {
                                display: none;

                                @include media-breakpoint-down(md) {
                                    display: block;
                                }
    
                                @include media-breakpoint-down(lg) {
                                    display: block;
                                }
                            }
                        }
                    }

                    .description {
                        h3 {
                            @include media-breakpoint-down(md) {
                                font-weight: 700;
                                font-size: 16px;
                                line-height: 24px;
                                letter-spacing: .5px;
                            }

                            @include media-breakpoint-down(lg) {
                                font-weight: 700;
                                font-size: 16px;
                                line-height: 24px;
                                letter-spacing: .5px;
                            }
                        }

                        p {
                            margin-bottom: 16px;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }

            @include media-breakpoint-down(md) {
                display: none;
                margin-top: 40px;
                position: relative;
            }

            @include media-breakpoint-down(lg) {
                display: none;
                margin-top: 40px;
                position: relative;
            }

            .content {
                display: none;

                >h4 {
                    @include texto-1;
                    font-size: 16px;
                    font-weight: $fw-bold;
                    margin: 0 0 20px 0;

                    @include media-breakpoint-down(md) {
                        margin: 0;
                        width: 90%;
                        font-size: 20px;
                        padding: 0px 0 20px 0px;
                    }
                }

                .back-btn-mobile {
                    display: none;
                    @include media-breakpoint-down(md) {
                        display: inherit;
                        position: fixed;
                        bottom: 4px;
                        left: 0;
                        z-index: 99999;
                    }
                }
                article {
                    @include texto-1;
                    margin: 20px 0;

                    h1,
                    h2,
                    h3,
                    h4,
                    h5 {
                        @include texto-1;
                        margin: 0 0 20px 0;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 28px;
                        /* identical to box height */

                        letter-spacing: 0.25px;

                        color: #333333;
                    }

                    @media (max-width: 1200px) {
                        margin: 0;
                    }
                    p {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 28px;
                        text-align: start !important; // utilizado !important para sobrepor css inline do editor
                        letter-spacing: 0.5px;
                        color: #8B8B8B;
                    }
                }
            }

            figure {
                img {
                    width: 100%;
                    @include border-radius;

                    @media (max-width: 1200px) {
                        opacity: 1;
                    }
                }
            }
        }

        .exame-menu {
            display: none;
            float: left;
            margin: 20px 0;
            opacity: 0;
            width: 300px;
        }

        .pub-genera-skin {
            display: none;
            opacity: 0;
            width: 300px;
            margin: 40px 0 0;
        }
    }

    .coluna-2-unic {
        width: 100%;
        max-height: 560px;
    }

    .colum-prs-print {
        width: 100% !important;
    }

    .coluna-2-3, .assinatura-content-cards {
        margin: 0;
        opacity: 1;
        transition: all 600ms ease;
        width: calc(100% - 255px);

        @media (max-width: 1999px) {
            &.share {
                padding: 0;
            }
        }
        @media (max-width: 768px) {
            &.share {
                padding: inherit;
            }
        }

        @media print {
            width: 100% !important;
            margin: 0 !important;
        }

        .no-padding {
            padding: 0;
        }

        .box-lineage {
            display: flex;
            flex-direction: row;
            background: #f7f7f7;
            border-radius: 6px;
            padding: 20px 20px 0 20px;
            margin-bottom: 20px;
            min-height: 100%;

            @include media-breakpoint-down(md) {
                flex-direction: column;
                padding-top: $sixteen-px;
                margin-bottom: $forty-eight-px;
            }
            
            @include media-breakpoint-down(lg) {
                flex-direction: column;
                padding-top: $sixteen-px;
                margin-bottom: $forty-eight-px;
            }

            .img-lineage {
                @media (min-width: 991px) {
                    min-width: 301px;
                }
                img {
                    max-width: 100%;
                    width: 301px;
                    @media (max-width: 991px) {
                        max-width: 100%;
                        width: 100%;
                    }
                }
            }
            .text-lineage {
                margin-left: 30px;

                @media (max-width: 991px) {
                    margin-left: 0px;
                }

                h5 {
                    line-height: $twenty-px;

                    @include media-breakpoint-down(md) {
                        margin-top: 1rem;
                    }

                    @include media-breakpoint-down(lg) {
                        margin-top: 1rem;
                    }
                }

                p {
                    line-height: $twenty-px;
                }
            }
        }

        @media (max-width: 1500px) {
            width: calc(100% - 210px);
        }

        @include media-breakpoint-down(lg) {
            width: 100%;
            padding: 0 15px;

            .title-coluna {
                display: none;
            }
        }

        .prs-cards, .content-group-cards{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            

            @media print {
                justify-content: flex-start !important;
            }

            @include media-breakpoint-down(md) {
                width: 100%;
                justify-content: center;
            }

            @include media-breakpoint-down(lg) {
                width: 100%;
                justify-content: center;
                flex-wrap: wrap;
                gap: 24px;

                @media print {
                    gap: 0px;
                }
            }

            .prs-card {
                position: relative;
                max-width: 291px;
                width: 100%;
                padding: 24px;
                margin: 15px 15px 0 0;

                .width-fixed{
                    width: 60px;
                    text-align: center;
                    margin: 0 auto;
                }
                
                .gen-text {
                    --text-color: #333;
                    --text-font-family: 'Dasa Sans', sans-serif;
                }

                .gen-card-body {
                    --card-body-padding-x: 0;
                    --card-body-padding-y: 0;
                }

                &:nth-child(-n+3) {
                    margin: 0 15px 0 0;

                    @include media-breakpoint-down(md) {
                        margin: 15px 0 0;
                    }

                    @include media-breakpoint-down(lg) {
                        margin: 0;
                    }

                    @media print {
                        margin: 0;
                    }
                }

                &:first-child {
                    margin: 0 15px 0 0;

                    @include media-breakpoint-down(md) {
                        margin: 0;
                    }

                    @media print {
                        margin: 0;
                    }
                }

               

                @include media-breakpoint-down(lg) {
                    width: 100%;
                    margin: 0;
                    list-style: none;
                }

                @include media-breakpoint-down(md) {
                    width: 100%;
                    margin: 16px 0 0;
                    list-style: none;
                    max-width: 100%;
                    width: 358px;
                }

                @media screen and (max-width: 400px){
                    width: 100%;
                }


                @media print {
                    width: 32.09%;
                    margin-right: 0;
                    box-shadow: none;
                    filter: none;
                }

                &:nth-child(3n+0) {
                    margin-right: 0;
                }

                a {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: Mulish;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    letter-spacing: .5px;
                    color: #333333;
                    text-decoration: none;
                    max-width: 345px;

                    @media print {
                        max-width: 245px;
                    }
                }

                .prs-name {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-weight: bold;
                    padding-left: 8px;
                    margin-bottom: 4px;
                    min-height: 18px;

                    a {
                        display: flex;
                        justify-content: flex-start; 
                    }

                    &.disease_prs_high_risk {
                        h5 {
                            --text-size: 14px;
                            font-size: $sixteen-px !important;
                            line-height: 24px;
                            text-decoration: none;
                            max-width: 219px;

                            @media print {
                                --text-size: 0.6rem;
                            }
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            left: 15px;
                            
                            width: 12px;
                            height: 12px;
                            border-radius: 100%;
                            background: $red-genera;
                        }

                        label {
                            img {
                                width: 14px;
                                height: 14px;
                            }
                        }
                    }

                    &.disease_prs_medium_risk {
                        h5 {
                            --text-size: 13px;
                            font-size: $sixteen-px !important;
                            line-height: 24px;
                            text-decoration: none;
                            max-width: 215px;

                            @media print {
                                --text-size: 0.6rem;
                            }
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            margin-left: -20px;
                            width: 13px;
                            height: 13px;
                            border-radius: 100%;
                            background: $yellow-genera;
                        }

                        label {
                            img {
                                width: 14px;
                                height: 14px;
                            }
                        }
                    }


                    &.disease_prs_low_risk {
                        h5 {
                            --text-size: 13px;
                            font-size: $sixteen-px !important;
                            line-height: 24px;
                            text-decoration: none;
                            max-width: 215px;

                            @media print {
                                --text-size: 0.6rem;
                            }
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            margin-left: -20px;
                            width: 13px;
                            height: 13px;
                            border-radius: 100%;
                            background: $blue-genera;
                        }

                        label {
                            img {
                                width: 14px;
                                height: 14px;
                            }
                        }
                    }
                }

                .prs-disclaimer-short {
                    display: flex;
                    justify-content: flex-start;

                    .caption {
                        --text-weight: 400;
                        line-height: 133%;

                        &.small-text{
                            font-size: 12px !important;
                        }

                    }

                    @media print {
                        height: auto;
                    }
                }

                .prs-disclaimer-short,
                .prs-brazilian-average {
                    @media (min-width: 1080px) and (max-width: 1340px) {
                        margin-bottom: 5px;
                    }

                    p {
                        @media (min-width: 1080px) and (max-width: 1340px) {
                            --text-size: 10px;
                        }
                        
                        @media print {
                            --text-size: 8px;
                        }
                    }
                    
                    a {
                        justify-content: flex-start;
                    }
                }
                .prs-risk-details {
                    margin: 24px 0;
                    

                    &.prs-risk-details-assinatura{
                        margin-top: 10px;

                        .prs-risk-info{
                            margin: 24px 0;
                        }

                    }

                    .prs-risk-info {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .prs-risk-level {
                            --text-size: 16px;
                            --text-weight: 700;
                            line-height: 150%;
                            margin: 6px 0 2px 0;
                        }

                        hr {
                            width: 128px;
                            margin: 8px 0;
                            border-top: 2px solid #E6E6E6;
                        }

                        .prs-risk-percent {
                            --text-size: 32px;
                            --text-letter-spacing: -1px;
                            --text-color: #161616;
                            line-height: 125%;
                            margin: 0;
                        }

                        .prs-risk-txt-between {
                            --text-size: 12px;
                            --text-weight: 500;
                            line-height: 133%;
                            margin: 0;
                        }
                    }
                }
                .prs-half-circle {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 195px;

                    @media print {
                        height: 130px;
                    }

                    .progress-bar {
                        position: relative;
                        text-align: center;
                        background: #fff;
                        color: #000;

                        @media print {
                            width: 240px;
                        }
                    }

                    .barOverflow{
                        position: relative;
                        overflow: hidden;
                        width: 235px;
                        height: 116px;
                        margin: auto;
                    }
                    
                    .bar{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 235px;
                        height: 235px;
                        border-radius: 50%;
                        box-sizing: border-box;
                        border: 8px solid #EAE8E8;
                        transform: rotate(45deg);

                        @media print {
                            width: 185px;
                            height: 185px;
                        }

                        &.disease_prs_high_risk {
                            border-bottom-color: #D12E26;
                            border-right-color: #D12E26;
                        }

                        &.disease_prs_medium_risk {
                            border-bottom-color: $cl-yellow-munsel;
                            border-right-color: $cl-yellow-munsel;
                        }

                        &.disease_prs_low_risk {
                            border-bottom-color: $cl-cyan-process;
                            border-right-color: $cl-cyan-process;
                        }
                    }
                }
                .prs-brazilian-average {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    text-align: left;
                    height: 28px;
                    
                    p {
                        max-width: 180px;
                        --text-size: 12px;
                        --text-weight: 400;
                        line-height: 133%;
                    }

                    .text-description {
                        width: 184px;
                        height: 28px;
                    }

                    .icon-arrow-right {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        align-content: center;
                        width: 24px;
                        height: 24px;
                        margin-top: 2px;

                        img {
                            position: absolute;
                            width: 24px;
                            height: 24px;
                            margin-top: 1px;
                        }
                    }
                }
                .prs-risk {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .big-percent {
                        --text-size: 24px;
                    }
                }
                //realation risk card
                .li-risk-chart {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 100%;
                    max-height: 225px;

                    &.li-risk-chart-assinatura{
                        min-height: 205px;
                    }

                    .realation-risk {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        padding: 0;
                        list-style: none;
                        margin-top: 61px;
    
                        li {
                            position: relative;
                            flex-grow: 1;
                            text-align: center;
                            display: flex;
                            flex-direction: column;
                            margin-right: 10px;
    
                            &:last-child {
                                margin-right: 0;
                            }

                            &.left-low {
                                width: 100%;
                                max-width: 64px;
    
                                .progress-relation-risk-low {
                                    min-width: 64px;
                                    
                                }
                            }
    
                            &.right-high {
                                width: 100%;
                                max-width: 64px;
    
                                .progress-relation-risk-high {
                                    min-width: 56.26px;
                                }
                            }
    
                            &.center-medium {
                                max-width: 103px;
                                width: 100%;
                            }
    
                            .dot-position {
                                position: absolute;
                                width: 8px;
                                height: 8px;
                                border-radius: 10px;
                                top: 2.5px;

                                .line {
                                    position: absolute;
                                    top: -19px;
                                    left: 40%;
                                    width: 1px;
                                    height: 20px;
                                    background-color: #333;
                                    z-index: 1;
                                    overflow: hidden;
                                  }
    
                                .initials,
                                figure,
                                img {
                                    position: absolute;
                                    margin-left: auto;
                                    margin-right: auto;
                                    left: -20px;
                                    right: 0;
                                    top: -66px;
                                    width: 48px;
                                    height: 48px;
                                    line-height: 48px;
                                    border-radius: 100%;
                                    background: #cdcdcd;
    
                                    img,
                                    p {
                                        width: 48px;
                                        height: 48px;
                                        border-radius: 100%;
                                    }
                                }
                            }
    
                            span {
                                width: 100%;
                                min-width: 103px;
                                height: 12px;
                                background: #EAE8E8;
                                border-radius: 8px;
                                margin-bottom: 11px;
                            }
                        }
                    }
                }
                
                //3th prs card
                .prs-3th-card {
                    height: calc(100% - 57.59px);
                    max-height: 225px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    ul.genetic-factor {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        align-content: center;
                    }
                    ul {
                        list-style: none;
                        padding: 0;
                        .double-circle {
                            position: relative;
                            display: flex;
                            width: 119px;
                            height: 119px;
                            border: 8px solid #EAE8E8;
                            border-radius: 100%;

                            .fst-circle, 
                            .fst-circle-print {
                                display: flex;
                                vertical-align: middle;
                                align-items: center;
                                max-width: 119px;
                                width: 119px;
                                height: 119px;
                                position: absolute;
                                top: -7px;
                                left: -7px;
                                border-radius: 100%;
                                z-index: 2;
                                canvas {
                                    width: 100%!important;
                                    height: inherit!important;
                                }
                                strong {
                                    position: absolute;
                                    margin: auto;
                                    left: 0;
                                    right: 0;
                                    text-align: center;
                                    font-weight: 700;
                                    font-style: normal;
                                    font-size: 24px;
                                    line-height: 30px;
                                    letter-spacing: .25px;
                                    align-items: center;
                                    text-align: center;
                                    color: #333333;
                                }
                            }
                            .snd-circle,
                            .snd-circle-print {
                                width: 119px;
                                height: 119px;
                                position: absolute;
                                top: -7px;
                                left: -7px;
                                border-radius: 100%;
                                z-index: 1;
                                canvas {
                                    width: 100%!important;
                                    height: inherit!important;
                                }
                            }
                        }

                        .circle-tags {
                            position: relative;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 16px;
                            color: #565656;
                            width: 100px;

                            > ul {
                                > li {
                                    position: relative;
                                    margin-bottom: 16px;

                                    &:last-child {
                                        margin-bottom: 0;
                                    }

                                    &.fst {
                                        &:before {
                                            content: '';
                                            width: 12px;
                                            height: 12px;
                                            border-radius: 10px;
                                            background: #333;
                                            position: absolute;
                                            top: 4px;
                                            left: -20px;
                                        }
                                    }
                                    &.snd {
                                        &:before {
                                            content: '';
                                            width: 12px;
                                            height: 12px;
                                            border-radius: 10px;
                                            background: $cl-silver-chalice;
                                            position: absolute;
                                            top: 4px;
                                            left: -20px;
                                        }
                                    }
                                    &.trd {
                                        &:before {
                                            content: '';
                                            width: 12px;
                                            height: 12px;
                                            border-radius: 10px;
                                            background: #EAE8E8;
                                            position: absolute;
                                            top: 4px;
                                            left: -20px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.prs-3th-card-assinatura{
                        margin: 15px 0;

                    }

                   
                   
                }
            }
        }

        &.detail {
            .prs-cards {
                @include media-breakpoint-down(md) {
                    gap: 0;
                }

                @media (min-width: 700px) and (max-width: 1199px) {
                    flex-direction: column;
                    align-items: center;
                    gap: 24px;
                }

               .prs-card {
                    width: 32.09%;
                    max-width: 32.09%;

                    @include media-breakpoint-down(md) {
                        width: 358px;
                        max-width: 100%;
                        margin-bottom: 24px;
                        min-height: 282px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    @media (min-width: 700px) and (max-width: 1199px) {
                        margin-bottom: 0;
                    }
               } 
            }
        }
    }

    .content-continue-print {
        .coluna-2-3 {
            margin-top: 48px !important;
        }
    }

    .what-to-do {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: calc(100% + 25px);
        margin: 24px 0 0 0;

         @include media-breakpoint-down(lg) {
            max-width: 325px;
            margin: 0;
            width: 100%;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
            width: 358px;
            margin: auto;
            
        }

        .what-to-do-txt {
            margin-right: 14px;
            max-width: 66%;
            min-width: 66%;

            @include media-breakpoint-down(md) {
                min-width: 100%;
                max-width: 100%;
                width: 358px;
                margin: 0;
            }
            
            @media (min-width: 992px) and (max-width: 1199px) {
                min-width: 100%;
                max-width: calc(100% + 25px);
                margin-top: 24px;
                margin-right: 0;
            }

            h3 {
                line-height: 25px;
                margin-bottom: 16px;
                color: $graydark-genera !important;
                font-family: 'Dasa Sans', sans-serif !important;

                @media print {
                    margin-bottom: 5px;
                }
            }

            ul {
                list-style: none;
                padding: 0;

                @media print {
                    margin: 0;
                }
            }
        }
        .red-box-results {
            width: 32.25%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include media-breakpoint-down(md) {
                width: 100%;
                margin: 16px auto 0 auto;
            }
            
            @include media-breakpoint-down(lg) {
                width: 100%;
                margin: 16px auto 0 auto;
            }

            h3 {
                --text-weight: 600;
                margin-bottom: 16px;

                @media print {
                    --text-size: 18px;
                    margin-bottom: 5px;
                }
            }

            p {
                @media print {
                    --text-size: 12px;
                    margin-bottom: 5px;
                }
            }

            a {
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.25px;
                color: #FFFFFF;
            }

            b {
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.25px;
                color: #FFFFFF;
            }
        }
    }

    .additional-information {
        width: calc(100% + 25px);
        max-width: calc(100% + 25px);
        margin: 24px 0px;

        @include media-breakpoint-down(md) {
            width: 100%;
            max-width: 358px;
            margin: 16px auto 0 auto;
        }

        @media (min-width: 700px) and (max-width: 1199px) {
            max-width: 358px;
            width: 100%;
            margin: 0 auto 0 auto;
        }

        .txt-additional-information {
            width: 100%;

            h3{
                color: $graydark-genera !important;
                font-family: 'Dasa Sans', sans-serif !important;
            }

            > p {
                color: $graydark-genera !important;
                font-size: 1rem !important;
                font-family: 'Dasa Sans', sans-serif !important;

                a {
                    font-style: normal;
                    font-weight: 700;
                    text-decoration: underline;
                    font-size: 1rem;
                    letter-spacing: 0.5px;
                    color: #757575;
                }
            }
        }

        .table-row {
            overflow-x: auto;
            overflow-y: hidden;
            width: 100%;
            height: 193px;
            @media (max-width: 991.98px) {
                height: inherit;
            }
            table {
                border: none;
                border-collapse: collapse;
                border-spacing: 0;
                width: 100%;

                tr {
                    padding: 10px 0;

                    &:nth-child(even) {
                        background: #F7F7F7;
                    }

                    &:nth-child(odd) {
                        background: #FFF;
                    }

                    th {
                        label {
                            font-family: Mulish;
                            font-size: 11px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 14px;
                            letter-spacing: 0.5px;
                            text-align: center;
                            text-decoration: none;
                            color: #333333 !important;
                            cursor: pointer;
                            position: relative;
                            vertical-align: top;
                            margin-bottom: 0;
                            text-transform: uppercase;
                        }

                        &:nth-child(1),
                        &:nth-child(7) {
                            width: 108px;
                            min-width: 108px;
                        }

                        &:nth-child(2) {
                            width: 119px;
                            min-width: 119px;
                        }

                        &:nth-child(3) {
                            width: 129px;
                            min-width: 129px;
                        }

                        &:nth-child(4) {
                            width: 73px;
                            min-width: 73px;

                            label {
                                cursor: default;
                            }
                        }

                        &:nth-child(5) {
                            width: 73px;
                            min-width: 73px;
                        }

                        &:nth-child(6) {
                            width: 83px;
                            min-width: 83px;
                        }

                        &:nth-child(8) {
                            width: 100px;
                            min-width: 100px;

                            label {
                                cursor: default;
                            }
                        }
                    }

                    td {
                        text-align: center;
                        border: none;
                        padding: 12px 0 13px;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 11px;
                        letter-spacing: 0.5px;
                        color: #333333;
                        vertical-align: text-top;

                        label {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 11px;
                            line-height: 14px;
                            text-align: center;
                            letter-spacing: 0.5px;
                            color: #333333!important;
                            margin: 10px 0;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
        .read-more-table {
            width: 100%;
            margin-top: 16px;

            p {
                cursor: pointer;
                font-weight: 700;
                font-style: normal;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: .5px;
                color: #333333;
                margin: 0;
            }

            @media (max-width: 991.98px) {
                display: none;
            }
        }
        .calculate-result {
            width: 100%;
            margin-top: 16px;

            a {
                cursor: pointer;
                font-weight: 700;
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: .5px;
                color: $graydark-genera;
                margin: 0;
                text-decoration: underline;
            }
        }
    }

    .bibliographic-reference {
        width: 100%;
        max-width: 1015px;

        @include media-breakpoint-down(md) {
            max-width: 325px;
            width: 100%;
            margin: 16px auto auto auto;
        }

        @media (min-width: 700px) and (max-width: 1199px) {
            max-width: 325px;
            width: 100%;
            margin: 0 auto 60px auto;
            filter: none;
        }

        @media print {
            width: 100%;
            max-width: 246mm;
            padding: 0px;
            margin: 16px 26px 0 26px;
        }

        h3 {
            width: 100%;
            line-height: 25px;
            word-break: break-word;
            color: $graydark-genera !important;
            font-family: 'Dasa Sans', sans-serif !important;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            line-height: 24px;
            letter-spacing: 0.5px;
            color: $graydark-genera;
            margin: 0;
            word-break: break-word;
            font-family: 'Dasa Sans', sans-serif !important;

            a {
                font-style: normal;
                font-weight: 400;
                text-decoration: underline;
                font-size: 1rem;
                letter-spacing: 0.5px;
                color: $graydark-genera;
            }
        }
    }

    &._prs {
        .coluna-2-3 {
            @include media-breakpoint-down(md) {
                width: 100%;
                padding: 0 0px;
            }

            @include media-breakpoint-down(lg) {
                width: 100%;
                padding: 0 0px;
            }
        }
    }

    .card-filter-prs {
        width: 100%;
        padding: 0;
        display: flex;
        justify-content: center;

        @include media-breakpoint-down(md) {
            padding: 0;
            width: 100%;
            justify-content: center;
            margin: 0;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            padding: 0 16px;
            margin: 0;
            width: 100%;
            justify-content: end;
            max-width: 100%;
        }

        .filter-prs {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            align-content: center;
            margin-left: 50px;
            position: relative;
            max-width: 1228px;
            width: 100%;
            height: 40px;
            margin: 0 0 16px 0;
            padding-right: 2px;

            @include media-breakpoint-down(md) {
                max-width: 280px;
                padding: 0 16px;
            }
            
            @include media-breakpoint-down(lg) {
                max-width: 280px;
                padding: 0;
            }

            #select-prs-filter {
                width: 220px;
                height: 40px;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.25px;
                color: #333333;
            }

            .custom-select {
                position: relative;
                float: right;
                background: #FFFFFF;
                box-sizing: border-box;
                border: none;
                border-radius: 4px;
                width: 220px;
                height: 40px;
                font-size: 14px;
                line-height: 25px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 11px 8px 11px 28px;
                border: 1px solid $cl-silver-chalice;

                &.selected-all {
                    &::before {
                        content: '';
                        position: absolute;
                        margin-left: -20px;
                        width: 12px;
                        height: 12px;
                        border-radius: 100%;
                        background: $cl-maximun-red;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        margin-left: -11px;
                        width: 12px;
                        height: 12px;
                        border-radius: 100%;
                        background: $yellow-genera;
                    }

                    &.circle-all .select-selected::before {
                        content: '';
                        position: absolute;
                        width: 12px;
                        height: 12px;
                        border-radius: 100%;
                        left: 26px;
                        z-index: 2;
                        background: $blue-genera;
                    }

                    .select-selected {
                        margin-left: 20px;
                    }
                }

                &.selected-high {
                    &::before {
                        content: '';
                        position: absolute;
                        margin-left: -20px;
                        width: 12px;
                        height: 12px;
                        border-radius: 100%;
                        background: $red-genera;
                    }
                }

                &.selected-medium {
                    &::before {
                        content: '';
                        position: absolute;
                        margin-left: -20px;
                        width: 12px;
                        height: 12px;
                        border-radius: 100%;
                        background: $yellow-genera;
                    }
                }

                &.selected-low {
                    &::before {
                        content: '';
                        position: absolute;
                        width: 12px;
                        height: 12px;
                        border-radius: 100%;
                        left: 8px;
                        z-index: 2;
                        background: $blue-genera;
                    }
                }

                @include media-breakpoint-down(md) {
                    padding-right: 5px;
                }

                @include media-breakpoint-down(lg) {
                    padding-right: 5px;
                    width: 100%;
                }

                .hide-button,
                .hide-link {
                    display: none;
                }

                select {
                    display: none;
                }

                .select-selected {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    font-family: Mulish !important;
                    font-size: 14px;
                    line-height: 18px;
                    letter-spacing: .5px;
                    border-radius: 4px;

                    &:after {
                        position: absolute;
                        z-index: 9999;
                        content: "";
                        top: 15px;
                        right: 7px;
                        width: 12px;
                        height: 7px;
                        background: url('#{$path-image}/arrow-bottom.svg') no-repeat;
                    }

                    &.select-arrow-active{
                        &:after {
                            border-color: transparent transparent #fff transparent;
                        }
                    }
                }
            }

            .select-items div,
            .select-selected {
                color: #333;
                font-weight: 700;
                text-align: center;
                cursor: pointer;
                user-select: none;
            }

            .select-items {
                position: absolute;
                background-color: #fff;
                top: 35px;
                left: -1px;
                right: 0;
                z-index: 99;
                border-radius: 4px;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border: 1px solid $cl-silver-chalice;
                border-top: 0;
                width: calc(100% + 2px);

                &::before {
                    content: '';
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    border-radius: 100%;
                    top: 11px;
                    left: 26px;
                    z-index: 2;
                    background: $blue-genera;
                }

                &.no-before {
                    &::before {
                        display: none;
                    }
                }

                div {
                    padding: 10px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-left: 30px;
                    height: 34px;

                    &:nth-child(1) {
                        padding-left: 50px;

                        &::before {
                            content: '';
                            position: absolute;
                            margin-left: -42px;
                            width: 12px;
                            height: 12px;
                            border-radius: 100%;
                            background: $red-genera;
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            margin-left: -33px;
                            width: 12px;
                            height: 12px;
                            border-radius: 100%;
                            background: $yellow-genera;
                        }
                    }
                }

                div[data-select-risk$="aumentado"] {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-left: 28px;
                    height: 34px;
                    line-height: 18px;
                    letter-spacing: .5px;

                    &::before {
                        content: '';
                        position: absolute;
                        margin-left: -20px;
                        width: 12px;
                        height: 12px;
                        border-radius: 100%;
                        background: $red-genera;
                    }
                }

                div[data-select-risk$="padrão"],
                div[data-select-risk$="predeterminado"] {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-left: 28px;
                    height: 34px;
                    line-height: 18px;
                    letter-spacing: .5px;

                    &::before {
                        content: '';
                        position: absolute;
                        margin-left: -20px;
                        width: 12px;
                        height: 12px;
                        border-radius: 100%;
                        background: $yellow-genera;
                    }
                }

                div[data-select-risk$="reduzido"],
                div[data-select-risk$="reducido"] {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-left: 28px;
                    height: 34px;
                    line-height: 18px;
                    letter-spacing: .5px;

                    &::before {
                        content: '';
                        position: absolute;
                        margin-left: -20px;
                        width: 12px;
                        height: 12px;
                        border-radius: 100%;
                        background: $blue-genera;
                    }
                }

                &.select-hide {
                    display: none;
                }
            }

            .select-items div:hover,
            .same-as-selected {
                background-color: #ccc;
            }
        }
    }

    &.__mapa-ampliado {
        @include media-breakpoint-up(xl) {
            //DESKTOP

            .coluna-1,
            .coluna-2 {
                opacity: 0;
                width: 0px;
                max-width: 0px;
                overflow: hidden;
                transition: all 600ms ease-in-out;
                margin: 0;
                height: 50vh;
            }

            .coluna-3 {
                width: 100%;
                position: relative;

                .title-coluna {
                    opacity: 0;
                }

                ._partial-regiao-collapse {
                    background: rgba(247, 247, 247, .9);
                    display: block;
                    transition: all 600ms ease-in-out;
                    opacity: 1;
                    position: absolute;
                    z-index: 999;
                    width: 300px;
                    top: 0px;
                    left: 15px;
                }

                ._partial-mapa {
                    height: 700px;
                    transition: all 600ms ease-in-out;
                }

                .conteudo {
                    width: calc(100% - 316px);
                    float: right;
                }
            }
        }

        .exame-menu {
            display: block !important;
            opacity: 1 !important;
            transition: opacity 600ms ease 200ms;
        }

        .pub-genera-skin {
            display: block !important;
            opacity: 1 !important;
            transition: opacity 600ms ease 200ms;
        }
    }

    &._sample-collection {
        width: 100%;

        @include media-breakpoint-down(md) {
            min-height: 560px;
            overflow: hidden;
        }
        
        @include media-breakpoint-down(lg) {
            min-height: 560px;
            overflow: hidden;
        }

        .alerts {
            margin-top: 0;
            margin-bottom: -71px;

            @include media-breakpoint-down(md) {
                margin-top: 65px;
                margin-bottom: -61px;
            }

            @include media-breakpoint-down(lg) {
                margin-top: 65px;
                margin-bottom: -61px;
            }
        }

        > .container {
            margin-top: 17px;
            padding: 0;

            @include media-breakpoint-down(md) {
                margin-top: 1rem;
            }

            @include media-breakpoint-down(lg) {
                margin-top: 1rem;
            }

            &.first-page {
                margin-top: 110px;

                @include media-breakpoint-down(md) {
                    margin-top: 44px;
                }
    
                @include media-breakpoint-down(lg) {
                    margin-top: 44px;
                }
            }

            &.dhl {
                max-width: 489px;

                &.first-page {
                    min-width: 848px;
                    margin-bottom: 260px;

                    @include media-breakpoint-down(md) {
                        min-width: 100%;
                        margin-bottom: 64px;
                    }

                    @include media-breakpoint-down(lg) {
                        min-width: 100%;
                        margin-bottom: 64px;
                    }
                }
            }

            &.return-options {
                min-width: 848px;
                max-width: 489px;
                margin: 40px auto 233px;

                @include media-breakpoint-down(md) {
                    min-width: 0px;
                    max-width: 100%;
                    margin: 40px auto 56px;
                }

                @include media-breakpoint-down(lg) {
                    min-width: 0px;
                    max-width: 100%;
                    margin: 40px auto 56px;
                }
            }
        }

        h1 {
            margin-bottom: 48px;
            transform: none !important;

            @include media-breakpoint-down(md) {
                max-width: 328px;
                margin: 0 auto 31px auto;
            }

            @include media-breakpoint-down(lg) {
                max-width: 328px;
                margin: 0 auto 31px auto;
            }
        }

        .card-dna-colletion {
            min-width: 416px;
            margin-right: 1rem;

            @include media-breakpoint-down(md) {
                min-width: 328px;
                margin: 0 16px 48px 16px;
                background: none;
                box-shadow: none;
                padding: 0;
            }

            @include media-breakpoint-down(lg) {
                min-width: 328px;
                margin: 0 16px 48px 16px;
                background: none;
                box-shadow: none;
                padding: 0;
            }

            .gen-card-body {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;

                @include media-breakpoint-down(md) {
                    padding: 0;
                }

                @include media-breakpoint-down(lg) {
                    padding: 0;
                }
            }

            h3 {
                &.title-sample-return {
                    text-decoration: none;
                }
            }

            &:last-child {
                margin-right: 0;

                @include media-breakpoint-down(md) {
                    margin: 0 16px;
                }

                @include media-breakpoint-down(lg) {
                    margin: 0 16px;
                }
            }

            img {
                width: 70px;
                height: 70px;
                margin-bottom: 16px;

                @include media-breakpoint-down(md) {
                    width: 80px;
                }

                @include media-breakpoint-down(lg) {
                    height: 80px;
                }
            }

            .default-red-btn {
                min-width: 225px;
                max-width: 260px;
                height: 46px;
                padding: 12px 16px 14px 16px;
                font-size: 16px;
                font-style: normal;
                line-height: 20px;
                letter-spacing: 0.25px;
                text-align: center;
                background: $cl-maximun-red;
            }
        }

        ._form-material {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            @include media-breakpoint-down(md) {
                min-width: 100%;
                flex-direction: column;
            }

            @include media-breakpoint-down(lg) {
                min-width: 100%;
                flex-direction: column;
            }
        }

        .box-perfil-new {
            .title-dna-colletion {
                margin-bottom: 48px;

                @include media-breakpoint-down(md) {
                    max-width: 328px;
                    margin: 0 auto 31px auto;
                }

                @include media-breakpoint-down(lg) {
                    max-width: 328px;
                    margin: 0 auto 31px auto;
                }
            }

            .container {
                @include media-breakpoint-down(md) {
                    display: block !important;
                    background: none;
                    padding: 0;
                }

                @include media-breakpoint-down(lg) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    margin: 0 auto !important;
                }

                @include media-breakpoint-lg2 {
                    margin-top: 2px !important;
                    padding: 0;
                }
            }

            @include media-breakpoint-down(md) {
                padding: 0 16px;
            }

            @include media-breakpoint-down(lg) {
                padding: 0 16px;
            }

            &.dhl-carousel {
                width: 100%;
                padding: 0;

                .new-form {
                    &.link-1st {
                        @media (min-width: 991px) {
                            width: 100%;
                            background: none;
                            box-shadow: none;
                            max-width: inherit;
                            padding: 0;
                        }
                    }
                    .container {
                        background: none;
                    }
                }
            }
        }

        .carousel-inner {
            transition: all 0.8s ease-in;
        }

        .carousel-item {
            max-height: 335px;
            transition: all .8s ease-in, max-height 0.8s ease-in;

            @include media-breakpoint-lg2 {
                max-height: 462px;
            }

            &.force-first-mobile {
                padding: 0 16px;
                margin-left: -16px;
                max-height: 405px;
                margin-bottom: 123px;

                @include media-breakpoint-down(md) {
                    max-height: 385px;
                    margin-left: 0;
                    margin-bottom: 0;
                    max-height: 100%;
                }

                @include media-breakpoint-down(lg) {
                    max-height: 385px;
                    margin-left: 0;
                    max-height: 100%;
                }

                @include media-breakpoint-lg2() {
                    max-height: 401px;
                    margin-bottom: 129px;
                }
            }

            &.carousel-step-2 {
                max-height: 360px;
                margin-bottom: 179px;

                @include media-breakpoint-down(md) {
                    margin-bottom: 0;
                    max-height: 100%;
                }

                @include media-breakpoint-down(lg) {
                    margin-bottom: 0;
                    max-height: 100%;
                }

                @include media-breakpoint-lg2() {
                    margin-bottom: 129px;
                }
            }

            &.carousel-step-3 {
                max-height: 380px;
                margin-bottom: 179px;

                @include media-breakpoint-down(md) {
                    margin-bottom: 0;
                    max-height: 100%;
                }

                @include media-breakpoint-down(lg) {
                    margin-bottom: 0;
                    max-height: 100%;
                }

                @include media-breakpoint-lg2() {
                    margin-bottom: 129px;
                }
            }

            &.carousel-step-5 {
                max-height: 335px;
                margin-bottom: 179px;

                @include media-breakpoint-down(md) {
                    margin-bottom: 150px;
                    max-height: 100%;
                    max-height: 290px;
                }

                @include media-breakpoint-down(lg) {
                    margin-bottom: 150px;
                    max-height: 100%;
                    max-height: 290px;
                }

                @include media-breakpoint-lg2() {
                    margin-bottom: 129px;
                }
            }

            &.carousel-step-6 {
                max-height: 335px;
                margin-bottom: 179px;

                @include media-breakpoint-down(md) {
                    margin-bottom: 0;
                    max-height: 100%;
                }

                @include media-breakpoint-down(lg) {
                    margin-bottom: 0;
                    max-height: 100%;
                }

                @include media-breakpoint-lg2() {
                    margin-bottom: 129px;
                }
            }

            &.carousel-step-7 {
                max-height: 480px;
                margin-bottom: 179px;

                @include media-breakpoint-down(md) {
                    margin-bottom: 0;
                    max-height: 100%;
                    max-height: 410px;
                }

                @include media-breakpoint-down(lg) {
                    margin-bottom: 0;
                    max-height: 410px;
                    margin-bottom: 62px;
                }

                @include media-breakpoint-lg2() {
                    margin-bottom: 129px;
                }
            }

            .carousel-step-item {
                background: $cl-white;
                width: 416px;
                min-height: 407px;
                margin-left: 16px;
                margin-right: 55px;
                border-radius: 6px;
                padding: 24px 16px;
                transition-duration: 2s;
                transition: all 0.5s;
                box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);

                @include media-breakpoint-down(md) {
                    background: none;
                    width: 328px;
                    height: 383px;
                    padding: 0;
                    margin-left: 0;
                    box-shadow: none;
                }

                @include media-breakpoint-down(lg) {
                    background: none;
                    width: 328px;
                    height: 383px;
                    padding: 0;
                    margin-left: 0;
                    box-shadow: none;
                }

                .new-register {
                    margin-bottom: 24px;

                    &.collect {
                        @include media-breakpoint-down(md) {
                            width: 98px;
                        }
        
                        @include media-breakpoint-down(lg) {
                            height: 98px;
                        }
                    }
                }

                &.step-1 {
                    padding: 24px 16px;

                    @include media-breakpoint-down(md) {
                        background: none;
                        width: 100%;
                        height: 383px;
                        padding: 0;
                        margin: 0 0 55px;
                    }

                    @include media-breakpoint-down(lg) {
                        background: none;
                        width: 100%;
                        height: 383px;
                        padding: 0;
                        margin: 0 0 55px;
                    }

                    @include media-breakpoint-lg2() {
                        background: $cl-white;
                        width: 416px;
                        height: 407px;
                        padding: 24px 16px;
                    }

                    p {
                        margin-bottom: 0 !important;

                        &:last-child {
                            margin-top: 24px !important;
                        }
                    }
                }

                &.step-2 {
                    padding: 24px 16px !important;
                    min-height: 360px;
                    transition-duration: 2s;
                    transition: all 0.5s;

                    @include media-breakpoint-down(md) {
                        padding: 0 16px !important;
                        background: none;
                        width: 100%;
                        height: 303px;
                        min-height: 303px;
                        padding: 0;
                        margin: 0 0 135px 0;
                    }

                    @include media-breakpoint-down(lg) {
                        padding: 0 16px !important;
                        background: none;
                        width: 100%;
                        height: 303px;
                        min-height: 303px;
                        padding: 0;
                        margin: 0 0 135px 0;
                    }
                }

                &.step-3 {
                    padding: 24px 16px !important;
                    min-height: 380px;
                    
                    @include media-breakpoint-down(md) {
                        padding: 0 16px !important;
                        width: 100%;
                        height: 335px;
                        min-height: 335px;
                        margin: 0 0 103px 0;
                    }

                    @include media-breakpoint-down(lg) {
                        padding: 0 16px !important;
                        width: 100%;
                        height: 335px;
                        min-height: 335px;
                        margin: 0 0 103px 0;
                    }
                }

                &.step-4 {
                    padding: 24px 16px !important;

                    @include media-breakpoint-down(md) {
                        padding: 0 !important;
                        width: 95%;
                        max-width: 95%;
                        min-height: 1000px;
                        margin: 0 0 64px 0;
                    }

                    @include media-breakpoint-down(lg) {
                        padding: 0 !important;
                        width: 95%;
                        max-width: 95%;
                        min-height: 1000px;
                        margin: 0 0 64px 0;
                    }

                    @include media-breakpoint-lg2() {
                        margin-bottom: 38px;
                    }

                    a {
                        color: $cl-gray-davys;
                    }

                    .find-kit {
                        font-family: Mulish;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 18px;
                        letter-spacing: 0.5px;
                        text-align: center;
                        margin-bottom: 24px;
                        color: $cl-gray-jet;

                        @include media-breakpoint-down(md) {
                            font-size: 11px;
                            font-weight: 600;
                            line-height: 14px;
                        }

                        @include media-breakpoint-down(lg) {
                            font-weight: 600;
                            font-size: 11px;
                        }
                    }

                    .arrow-code {
                        width: 77px;
                        position: relative;
                        top: 79px;
                        right: 118px;

                        @include media-breakpoint-down(md) {
                            top: 101px;
                            right: 116px;
                        }

                        @include media-breakpoint-down(lg) {
                            top: 101px;
                            right: 116px;
                        }

                        &.hidden {
                            display: none;
                        }
                    }

                    .arrow-code-latam {
                        position: relative;
                        width: 114px;
                        top: 79px;
                        right: 135px;

                        @include media-breakpoint-down(md) {
                            top: 101px;
                            right: 84px;
                        }

                        @include media-breakpoint-down(lg) {
                            top: 101px;
                            right: 84px;
                        }

                        &.hidden {
                            display: none;
                        }
                    }

                    .box-barcode {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        margin: -23px 0 24px !important;
                        background: #ffffff;
                        margin: 0;
                        padding: 0;

                        @include media-breakpoint-down(md) {
                            width: calc(100% + 55px);
                            padding: 0 24px 0 16px;
                            min-height: 152px;
                            margin: -23px 0 8px -16px !important;
                            box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.08);
                        }

                        @include media-breakpoint-down(lg) {
                            width: calc(100% + 55px);
                            padding: 0 24px 0 16px;
                            min-height: 152px;
                            margin: -23px 0 8px -16px !important;
                            box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.08);
                        }

                        &.hidden {
                            display: none;
                        }

                        .barcode {
                            width: 242px;
                            height: 107px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-image: url("../../assets/img/new-register/box-barcode.svg");

                            .activation-code {
                                width: 225px;
                                height: 92px;
                                background: $cl-silver-cultured;
                                border-radius: 6px;
                                margin: 0;

                                p {
                                    font-family: Mulish;
                                    font-size: 11px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 14px;
                                    letter-spacing: 0.5px;
                                    text-align: center;
                                    color: $cl-silver-chalice;
                                    margin-bottom: 4px;

                                    &:first-child {
                                        margin-top: 3px;
                                    }

                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                }

                                .img-barcode {
                                    margin-bottom: 4px;
                                }

                                .d-gray {
                                    color: $cl-gray-jet;
                                }
                            }
                        }
                    }

                    .change-label {
                        box-shadow: none;
                    }

                    .box-barcode-new {
                        margin-bottom: 24px;

                        &.hidden {
                            display: none;
                        }
                        
                        .barcode-content {
                            width: 100%;
                            display: flex;
                            justify-content: center;

                            .tag-info {
                                display: flex;
                                flex-direction: column;
                                width: 320px;
                                height: 140px;
                                margin: 10px 0;
                                padding: 12px;
                                background-image: url("../../assets/img/new-register/box-barcode-new.svg");

                                .svg-border {
                                    position: absolute;
                                    top: 0;
                                    left: 16px;
                                }

                                .activation-info {
                                    display: flex;
                                    width: 100%;
                                    justify-content: flex-start;
                                    padding: 0 0 8px;
                                    gap: 8px;

                                    .activation {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-start;
                                        border-right: 1px solid #ccc;
                                        padding-right: 14px;
                                        
                                        &:last-child {
                                            border-right: 0px;
                                        }

                                        .code {
                                            display: flex;
                                            flex-direction: column;
                                            align-items: flex-start;
                                            border: 2px solid $cl-maximun-red;
                                            border-radius: 5px;
                                            padding: 0 6px;
                                        }

                                        span {
                                            font-size: 9px;
                                            text-align: start;
                                            font-weight: 600;
                                            color: #000;
                                        }

                                        p {
                                            font-size: 13px;
                                            font-weight: 700;
                                            letter-spacing: 0;
                                            color: #000;
                                        }
                                    }
                                }

                                .barcode-info {
                                    display: flex;

                                    .barcode-svg {
                                        width: 190px;
                                    }

                                    .barcode-right {
                                        width: 130px;
                                        font-size: 10px;
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: flex-end;  
                                        align-items: flex-start;
                                        text-align: start;
                                        padding-left: 8px;

                                        .kit-name {
                                            font-size: 9px;
                                            font-weight: 800;
                                            margin-top: 6px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .local-barcode {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 18px;
                        letter-spacing: 0.5px;
                        text-align: center;

                        @include media-breakpoint-down(md) {
                            font-size: 11px;
                            font-weight: 600;
                            line-height: 14px;
                        }

                        @include media-breakpoint-down(lg) {
                            font-size: 11px;
                            font-weight: 600;
                            line-height: 14px;
                        }
                    }
                }

                &.step-5 { 
                    padding: 24px 16px !important;
                    min-height: 335px;

                    @include media-breakpoint-down(md) {
                        padding: 0 16px !important;
                        width: 100%;
                        margin: 0;
                    }

                    @include media-breakpoint-down(lg) {
                        padding: 0 16px !important;
                        width: 100%;
                        margin: 0;
                    }
                }

                &.step-6 { 
                    padding: 24px 16px !important;
                    min-height: 287px;

                    @include media-breakpoint-down(md) {
                        padding: 0 16px !important;
                        width: 100%;
                        height: 310px;
                        min-height: 310px;
                        margin: 0 0 126px 0;
                    }

                    @include media-breakpoint-down(lg) {
                        padding: 0 16px !important;
                        width: 100%;
                        height: 310px;
                        min-height: 310px;
                        margin: 0 0 126px 0;
                    }
                }

                &.step-7 {
                    height: 470px;
                    padding: 24px 16px !important;

                    @include media-breakpoint-down(md) {
                        width: 100%;
                        height: 410px;
                        margin: 0 0 62px 0;
                        padding: 0 16px !important;
                        margin-bottom: 62px;
                    }

                    @include media-breakpoint-down(lg) {
                        width: 100%;
                        height: 410px;
                        margin: 0 0 62px 0;
                        padding: 0 16px !important;
                    }

                    .default-red-btn {
                        max-width: 223px;
                        height: 46px;
                        padding: 12px 16px 14px;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: bold;
                        line-height: 20px;
                        letter-spacing: 0.25px;
                        text-align: center;
                        background: $cl-maximun-red;
                        margin: auto auto 16px;
                    }

                    .button-link {
                        width: 100%;
                        max-width: 100%;
                        height: 22px;
                        border: 0;
                        background: $cl-silver-cultured;
                    }
                }

                p {
                    font-family: Mulish;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0.5px;
                    text-align: center;
                    color: $cl-gray-davys;
                    margin-bottom: 24px;

                    @include media-breakpoint-down(md) {
                        margin-bottom: 16px;
                    }

                    @include media-breakpoint-down(lg) {
                        margin-bottom: 16px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                a {
                    line-height: 24px;
                    text-align: center;
                    margin-bottom: 1rem;
                }

                .label-important {
                    width: 100px;
                    height: 26px;
                    padding: 4px 8px;
                    border-radius: 6px;
                    background: $cl-maximun-red;
                    margin-bottom: 24px;

                    @include media-breakpoint-down(md) {
                        margin-bottom: 16px;
                    }

                    @include media-breakpoint-down(md) {
                        margin-bottom: 16px;
                    }

                    p {
                        color: $cl-white;
                        font-weight: 700;
                        font-style: normal;
                        font-size: 14px;
                        line-height: 18px;
                        letter-spacing: 0.5px;
                        text-align: center;
                        margin: 0 !important;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .label-code {
                    width: 105px;
                    height: 38px;
                    padding: 4px 8px;
                    background: $cl-gray-jet;
                    border-radius: 6px;
                    margin-bottom: 24px;

                    @include media-breakpoint-down(md) {
                        margin-bottom: 16px;
                    }

                    @include media-breakpoint-down(lg) {
                        margin-bottom: 16px;
                    }

                    p {
                        color: $cl-white;
                        font-weight: 700;
                        font-style: normal;
                        font-size: 24px;
                        line-height: 30px;
                        letter-spacing: 0.25px;
                        text-align: center;
                        margin: 0 !important;
                    }
                }
            }

            &.carousel-step-4 {
                max-height: 1190px;

                @include media-breakpoint-down(md) {
                    padding: 0 16px !important;
                }

                @include media-breakpoint-down(lg) {
                    padding: 0 16px !important;
                }

                .carousel-step-item {
                    min-height: 1000px;
                    margin-bottom: 30px;
                }
            }
        }

        .circle-progress {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
            margin-bottom: 24px;

            label {
                content: "";
                width: 4px;
                height: 4px;
                border-radius: 100%;
                background: #c4c4c4;
                margin: 0 4px 0 0;

                &.active {
                    background: $cl-orange-mandarin;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    &._perfil {
        >.container {
            display: flex;
            align-items: center;
            background: transparent;

            @include media-breakpoint-down(md) {
                justify-content: center;
                min-height: auto;
                padding: 20px 0 50px;
            }

            &.dhl {
                width: 100%;
                padding: 0;
                flex-direction: column;

                @include media-breakpoint-down(md) {
                    background: #f7f7f7;
                    align-items: baseline;
                    padding: 0;
                    margin-top: 0;
                }
    
                @include media-breakpoint-down(lg) {
                    background: #f7f7f7;
                    align-items: baseline;
                    padding: 20px;
                    margin-top: 0;
                    max-width: 1280px;
                }

                .box-perfil {
                    padding: 0 0px 30px;
                    background: #f7f7f7;
                    width: 98%;

                    @media (min-width: 991px) {
                        width: 100%;
                        max-width: 1280px;
                        background: #f7f7f7;
                        padding: 45px 16px 40px;
                    }
                    .gen-card {
                        background: #fff;
                        box-shadow: none;
                        max-width: 956px;
                        width: 100%;
                        margin: 0;
                        .gen-input input:not(.has-outline), 
                        .gen-input textarea:not(.has-outline), 
                        .gen-input select:not(.has-outline) {
                            background: #eeeeee;
                        }
                        button.gen-btn.gen-btn-primary.btn-new-cep {
                            margin-top: 17px;
                        }
                        &.log {
                            max-width: 416px;
                            order: unset;
                            @include media-breakpoint-down(md) {
                                margin-bottom: 24px;
                            }
                            @media (min-width: 991px) {
                                &:first-child{
                                    margin-right: 16px;
                                }
                            }
                        }
                        &.correio {
                            @media (min-width: 991px) {
                                max-width: 328px;
                            }
                        }
                        &.form {
                            background: none;
                        }
                    }
                }

                h2 {
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 25px;
                    text-align: center;
                }

                h3 {
                    margin: 40px auto 11px;
                }

                .new-form {
                    @media (min-width: 991px) {
                        width: 100%;
                        max-width: 850px;
                        margin: auto;
                    }
                    &.link-1st {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        position: relative;
                        padding-top: 30px;
                        min-height: 500px;
                        @media (min-width: 991px) {
                            width: 100%;
                            max-width: 410px;
                        }

                        img {    
                            margin-bottom: 8px;
                            margin-top: 32px;
                        }

                        .action-flex-column {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            button {
                                margin-top: 10px;
                            }
                            a {
                                margin-top: 16px;
                            }
                        }
                    }
                    ._form-material {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        @media (min-width: 991px) {
                            flex-direction: row;
                            align-items: inherit;
                            justify-content: space-between;
                        }
                        
                        .flex-column {
                            height: 170px;
                            background: #fff;
                            @media (min-width: 991px) {
                                width: 50%;
                                max-width: 411px;
                                height: 255px;
                                box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
                                border-radius: 6px;
                            }

                            h3 {
                                margin-top: 8px;
                                margin-bottom: 16px;
                            }
                        }
                    }
                }
            }
        }

        .container-genera {
            padding-top: 24px;
            padding-bottom: 48px;

            @include media-breakpoint-down(md) {
                padding-top: 44px;
            }

            @include media-breakpoint-down(lg) {
                padding-top: 44px;
            }
        }

        #img-alert {
            display: none;
        }

        .box-perfil {
            display: flex;
            flex-direction: column;
            width: 90%;
            max-width: 630px;
            margin: 0 auto;
            padding: 25px 10px 25px 25px;
            position: relative;
            background: #fff;
            border-radius: 6px;

            .edit-after {
                max-width: 530px;
                width: 90%;

                .pencil-icon {
                    cursor: pointer;
                    position: absolute;
                    top: 25px;
                    right: -50px;
                    img {
                        width: 25px;
                        height: 24px;
                        background-size: 12px;
                        background-color: #757575;
                        border-radius: 100%;
                        padding: 0 7px;
                    }
                }
            }
            .new-form {
                ._form-material {
                    .form-group {
                        padding: 0;
                        height: 65px;
                        background: #EBEBEB;
                        border-radius: 4px 4px 0px 0px;
                        .form-control {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 180%;
                            height: 65px;
                            display: flex;
                            align-items: center;
                            letter-spacing: 0.15px;
                            padding-left: 15px;
                            color: #333333;
                            border-bottom: 1px solid #AAAAAA;
                            &:focus ~ .float-label,
                            &:valid ~ .float-label {
                                top: 4px;
                                left: 15px;
                                font-weight: normal;
                                font-size: 12px;
                                line-height: 180%;
                                display: flex;
                                align-items: center;
                                letter-spacing: 0.5px;
                                color: #4B4B4B;
                                text-transform: capitalize;
                            }
                            &:invalid ~ .float-label {
                                top: 4px;
                                left: 15px;
                                font-weight: normal;
                                font-size: 12px;
                                line-height: 180%;
                                display: flex;
                                align-items: center;
                                letter-spacing: 0.5px;
                                color: #FF0000;
                                text-transform: capitalize;
                            }
                            &:invalid {
                                border-bottom: 1px solid #FF0000;
                                background: url('#{$path-image}/icon_invalid_red.svg') no-repeat;
                                background-position: 95% center;
                            }
                            &:valid {
                                border-bottom: 1px solid #6CC634;
                                background: url('#{$path-image}/icon_check_green.svg') no-repeat;
                                background-position: 95% center;
                            }
                            &:focus ~ .form-bar:before,
                            &:focus ~ .form-bar:after {
                                background: #333333;
                            }
                            &:invalid ~ .form-bar:before,
                            &:invalid ~ .form-bar:after {
                                background: #FF0000;
                            }
                            &:focus {
                                background: #ffffff;
                            }
                        }
                        .float-label {
                            top: 20px;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 180%;
                            display: flex;
                            align-items: center;
                            letter-spacing: 0.15px;
                            color: $cl-texto3;
                            text-transform: capitalize;
                            &.cpf {
                                top: 0;
                                left: 15px;
                                font-weight: normal;
                                font-size: 12px;
                                line-height: 180%;
                                display: flex;
                                align-items: center;
                                letter-spacing: 0.5px;
                                color: #4B4B4B;
                            }
                            &.gender-fix {
                                top: 4px;
                                left: 15px;
                                font-weight: normal;
                                font-size: 12px;
                                line-height: 180%;
                                display: flex;
                                align-items: center;
                                letter-spacing: 0.5px;
                                color: #4B4B4B;
                                text-transform: capitalize;
                            }
                        }
                        &.actions {
                            width: 400px;
                            max-width: 100%;
                            display: flex;
                            flex-direction: row;
                            background: transparent;
                            button,
                            a {
                                height: 48px;
                                flex-grow: 1;
                                flex-shrink: 1;
                                flex-basis: 0;
                                text-align: center;
                                @include media-breakpoint-down(md) {
                                    min-width: 144px;
                                    margin: 0!important;
                                }
                            }
                            button {
                                background: $cl-maximun-red;
                                border-radius: 40px;
                                font-style: normal;
                                font-weight: bold;
                                font-size: 14px;
                                line-height: 0%;
                                display: flex;
                                align-items: center;
                                text-align: center;
                                justify-content: center;
                                color: #FFFFFF;
                                @include media-breakpoint-down(md) {
                                    margin-right: 10px!important;
                                }
                            }
                            a {
                                background: transparent;
                                border: 2px solid #333333;
                                box-sizing: border-box;
                                border-radius: 40px;
                                font-style: normal;
                                font-weight: bold;
                                font-size: 14px;
                                line-height: 100%;
                                display: flex;
                                align-items: center;
                                text-align: center;
                                justify-content: center;
                                color: #333333;
                                &:hover {
                                    background: #4f4f4f;
                                    color: #fff;
                                }
                                @include media-breakpoint-down(md) {
                                    margin-left: 10px!important;
                                }
                            }
                        }
                    }
                    hr {
                        width: 530px;
                        max-width: 90%;
                        height: 0px;
                        margin: 30px 0;
                        background: #C1C1C1;
                    }
                    .btn-csv {
                        margin-bottom: 30px;
                        .cloud-download {
                            width: 400px;
                            max-width: 100%;
                            height: 48px;
                            font-size: 13px;
                            border: 2px solid #333333;
                            box-sizing: border-box;
                            border-radius: 40px;
                            color: #333333;
                            position: relative;
                            box-shadow: none;
                            &:hover {
                                background: #4f4f4f;
                                color: #fff;
                                &::before {
                                    content: '';
                                    background-image: url('../../assets/img/cloud-form2.png');
                                }
                            }

                            &::before {
                                content: '';
                                background-image: url('../../assets/img/cloud-form.png');
                                background-size: 25px;
                                background-repeat: no-repeat;
                                background-position: center;
                                position: absolute;
                                left: 15px;
                                top: 15px;
                                width: 25px;
                                height: 16px;
                            }
                        }
                    }
                }
            }

            @include media-breakpoint-down(md) {
                flex-direction: column;
                padding: 40px 30px;
            }

            .box-left {
                border-radius: 50%;
                display: flex;
                justify-content: center;

                figure {
                    margin: 15px 0 0 0;

                    img {
                        border-radius: 50%;
                        width: 80px;
                    }
                    .initials {
                        width: 80px;
                        height: 80px;
                        background-color: #CDCDCD;
                        border-radius: 50%;
                        display: inline-block;

                        p {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 35px;
                            line-height: 60px;
                            padding: 8px;
                            text-align: center;
                            color: #333333;
                        }
                    }
                }
            }

            .box-right {
                @include width-flex(70%);
                margin: 0 0 0 40px;

                @include media-breakpoint-down(md) {
                    @include width-flex(100%);
                    margin: 0;
                }
            }
        }

        .box-perfil-new {
            min-width: 448px;
            width: 448px;
            margin: 0 auto;

            @include media-breakpoint-down(md) {
                margin: 0;
                width: 95%;
                min-width: 95%;
            }

            .box-img-perfil {
                height: 64px !important;
                padding: 0;
            }

            .img-perfil {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                position: relative;
                height: 64px;
                margin-right: 16px;
                img {
                    width: 64px;
                    height: 64px;
                    border-radius: 100%;
                }
                .initials {
                    width: 64px;
                    height: 64px;
                    background-color: $cl-silver-chalice;
                    border-radius: 50%;
                    display: inline-block;
                    p {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        letter-spacing: 0.5px;
                        line-height: 50px;
                        padding: 8px;
                        text-align: center;
                        color: #FFFFFF;
                    }
                }
                .change-photo {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 180%;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.5px;
                    color: #333333;
                    text-decoration: underline;

                    @include media-breakpoint-down(md) {
                        max-width: 180px;
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .radio-gender {
                display: flex;
                margin: 1rem 0;

                .gen-radio {
                    margin-right: 16px;

                    &:last-child {
                        margin: 0;
                    }
                }
            }

            .gender {
                display: flex;
                flex-direction: row;
                background: transparent!important;
                font-weight: 600;
                font-size: 11px;
                margin: 0;

                &.title {
                    border: 0;
                    margin: 0;
                    padding: 0;
                    height: 14px;
                }

                .btn-info {
                    margin: 0 !important;
                    background-color: #FFFF;
                    cursor: pointer;
                }

                p {

                    margin: 0;
                }

                .label-tooltip {
                    margin: 0 0 0 1rem;
                }

                .gender-fix {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    color: #4B4B4B;
                    text-transform: capitalize;
                }
            }

            .pencil-icon {
                cursor: pointer;
                position: absolute;
                top: 25px;
                right: -50px;
                img {
                    width: 25px;
                    height: 24px;
                    background-size: 12px;
                    background-color: #757575;
                    border-radius: 100%;
                    padding: 0 7px;
                }
            }
        }

        .btn-cinza {
            background: $cl-cinza4;
            font-weight: $fw-semibold;

            &:hover {
                background: $cl-texto;
            }
        }

        .btn-form {
            &:hover {
                background: $cl-texto;
                color: $cl-branco;
            }
        }

        ._form-material .form-group {
            @include media-breakpoint-down(md) {
                &.group-buttons {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .btn {
                    width: 80%;
                    margin: 10px 10% !important;
                }
            }

            &.edit:after {
                content: '';
                background: transparent url('#{$path-image}/icon_edit.svg') no-repeat center center;
                background-size: 20px;
                pointer-events: none;
                position: absolute;
                top: 6px;
                right: 10px;
                width: 30px;
                height: 20px;
            }

            .form-control {
                font-weight: 300;
                border: none;
                position: relative;
            }

            &.edit {
                .form-control {
                    padding-right: 24px;
                }
            }
        }

        .img-perfil {
            position: relative;
            height: 80px;

            &:hover {
                // cursor: pointer;

                .icon-camera {
                    padding: 30px;
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    width: 80px !important;
                    transition: all 200ms ease;
                }
            }

            .icon-camera {
                background: rgba(255, 255, 255, 0.47);
                padding: 10px;
                position: absolute;
                left: 20px;
                top: 20px;
                width: 40px !important;
                transition: all 200ms ease;
            }
        }
    }

    &._consent {
        >.container {
            &.consent {
                .card-consent {
                    max-width: 710px;

                    .h1 {
                        &.gen-text {
                            --text-size: 2rem;
                        }
                    }

                    .gen-text {
                        --text-size: .875rem;
                    }

                    h5 {
                        --text-weight: 600;
                    }
                }
                .card-download-raw {
                    max-width: 746px;
                    
                    .h1 {
                        --text-size: 26px;
                    }
                    
                    .h2 {
                        --text-size: 16px;
                        line-height: 20px;
                    }

                    .text-underline {
                        text-decoration: underline;
                        --text-weight: 600;
                    }
                }
                .gen-checkbox {
                    label {
                        &.gen-text {
                            .checkbox-text {
                                p {
                                    padding-left: 1.6875rem;
                                    
                                    &:first-of-type {
                                        display: initial;
                                        vertical-align: top;
                                        padding-left: 0;
                                    }
                                }

                                a {
                                    color: $cl-gray-davys;
                                    text-decoration: underline;
                                }
                            }
                        }
                    }

                    .caption {
                        padding-left: 1.6875rem;
                        margin-top: -.875rem;
                    }
                }

                .box-perfil {
                    display: flex;
                    flex-direction: column;
                    width: auto;
                    max-width: 710px;
                    margin: 0;
                    padding: 0;
                    position: relative;
                    border-radius: 6px;

                    small {
                        font-family: Mulish;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 18px;
                        display: flex;
                        align-items: center;
                        letter-spacing: 0.25px;
                        margin: 14px 0px 20px;
                        color: #D12E26;
                    }

                    h1 {
                        font-family: Mulish;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 32px;
                        line-height: 40px;
                        letter-spacing: 0.25px;
                        color: #333333;
                        transform: none!important;
                    }

                    p {
                        font-family: Mulish;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 24px;
                        letter-spacing: 0.5px;
                        color: #565656;
                    }

                    ._form-material {
                        display: flex;
                        justify-content: flex-start;
                        flex-direction: row;
                        margin-top: -5px;
                        button {
                            max-width: 100%;
                            width: 110px;
                            min-width: inherit;
                            text-decoration: none;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                    #form-register {
                        .option {
                            margin-bottom: 20px;
                        }
                        input {
                            display: none;
                        }
                        small {
                            color: #d12e26;
                            font-size: 12px;
                            margin-left: 27px;
                        }
                        .error {
                            display: none;
                        }
                    }
                    .check-before {
                        position: relative;
                        margin-bottom: 0;
                        &:before {
                            content: '';
                            position: absolute;
                            width: 16px;
                            height: 16px;
                            left: 0;
                            top: 5px;
                            background-image: url('../../assets/img/form-icon/check-0.svg');
                        }
                        p {
                            margin-left: 27px;
                            padding-right: 20px;
                            font-weight: 700;
                            margin-bottom: 0;

                            a {
                                color:#565656;
                                text-decoration: underline;
                            }
                        }
                    }
                    input:checked + .check-before {
                        &:before {
                            content: '';
                            background-image: url('../../assets/img/form-icon/check-1.svg');
                        }
                    }
                    input:disabled + .check-before {
                        &:before {
                            content: '';
                            background-image: url('../../assets/img/form-icon/check-1.svg');
                            opacity: 0.75;
                        }
                    }
                }
            }
        }
    }

    &._login {
        height: 90vh;
        display: flex;
        align-items: center;

        @media (max-width: 768px) {
            height: auto;
        }

        >.container {
            margin-top: -90px;
            background: none;
            display: flex;
            align-items: center;

            @include media-breakpoint-down(md) {
                max-width: 100%;
            }

            @media (max-width: 768px) {
                margin: 20px 0 40px;
                padding: 0;
                min-height: auto;
            }
        }

        .box-login {
            width: 434px;

            .captcha {
                height: 92px;
            }

            .remember-login {
                padding: 0;
                margin-bottom: 1rem;

                input {
                    margin-right: 1rem;
                }

                label {
                    margin: 0;
                }

            }

            @media (max-width: 768px) {
                width: 320px;

                .captcha {
                    height: 72px;
                    margin: 0 0 20px 0;
                }

                .g-recaptcha {
                    margin: 0px -14px;
                    width: 304px;
                    transform: scale(0.9);
                }
            }

            .row-1 {
                text-align: left;
                margin-bottom: 25px;

                h1 {
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 30px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.25px;
                    color: #333333;
                    transform: none!important;
                    margin: 0 0 16px;
                }

                h3 {
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.5px;
                    color: #333333;
                }

                .detalhe {
                    @include bar-gradient();
                }
            }

            .row-2 {
                .btn {
                    width: 100%;
                    height: 46px;
                    margin: 15px 0 0 0;
                }

                ._form-padrao .form-group label {
                    margin: 0 0 5px 0;
                    text-align: center;
                    width: 100%;
                }

                ._form-padrao {
                    .form-group {
                        input:focus ~ .floating-label,
                        input:not(:focus):valid ~ .floating-label{
                            top: 0px;
                            bottom: 10px;
                            left: 14px;
                            font-size: 11px;
                            opacity: 1;
                            color: #565656;
                            font-weight: 600;
                        }
                        input:invalid ~ .floating-label,
                        .floating-label {
                            position: absolute;
                            pointer-events: none;
                            left: 13px;
                            top: 13px;
                            font-size: 14px;
                            font-weight: 700;
                            color: #4f4f4f;
                            transition: .2s ease all;
                        }
                    }
                }
            }

            .row-3 {
                display: flex;
                flex-direction: column;

                div.d-flex {
                    justify-content: left;
                    padding: 0;
                    margin-bottom: 16px;
                }

                .gen-btn-link {
                    font-size: 14px !important;
                }
            }
            .form-group {
                padding: 0;
                margin-bottom: 15px;
                height: 45px;
                &.flex-row {
                    display: flex;
                    flex: inherit;
                    align-items: center;
                }
                label {
                    text-align: left!important;
                }
                .default-red-btn {
                    max-height: 155px;
                    min-width: 125px;
                }
                .gray-no-bg {
                    margin-left: 16px;
                }
            }
            .form-control {
                background: #E4E4E4;
                border-radius: 4px 4px 0px 0px;
                border-bottom: 1px solid $cl-silver-chalice;
                padding: 13px 14px 7px;
                font-weight: bold;
                font-size: 14px;
                color: #333;
            }
        }
    }

    &._ancestralidade, &._assinatura {
        z-index: 2;

        .ancestry-content {
            padding-top: 0;
            margin-top: -32px;
            margin-bottom: 48px;
            
            @include media-breakpoint-down(md) {
                margin-top: -22px;
            }

            @include media-breakpoint-down(lg) {
                margin-top: -22px;
            }

            .coluna-2 {
                @include media-breakpoint-down(lg) {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    max-width: none;

                    ._partial-regiao-collapse {
                        @include media-breakpoint-down(md) {
                            width: 100%;
                            margin: 0 0 1rem 0;
                            max-width: 345px;
                        }

                        @include media-breakpoint-down(lg) {
                            width: 100%;
                            margin: 0 0 1rem 0;
                            max-width: 345px;
                        }
                    }
                }
            }

            .coluna-3 {
                margin: 0;

                @include media-breakpoint-down(lg) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    align-content: center;
                    flex-direction: column;
                    margin: 0;
                    width: 100%;
                }
            }
        }

        .assinatura-content {
            padding: 0;
            margin-top: -32px;
            margin-bottom: 48px;
            display: flex;
            gap: 24px;
            
            @include media-breakpoint-down(lg) {
                margin-top: -22px;
                align-items: center;
                max-width: 960px;
            }
            
            @include media-breakpoint-down(md) {
                margin-top: -22px;
                max-width: 690px;
            }

            @include media-breakpoint-down(xs) {
                margin-top: 24px;
                margin-bottom: 0px;
            }

            .mb-6{
                margin-bottom: $twenty-four-px !important;
            }

            .coluna-1{
                margin: 0 !important;
            }

            .coluna-2 {
                margin: 0;
                @include media-breakpoint-down(lg) {
                    display: flex !important;
                    width: 100%;
                    justify-content: center;
                    max-width: 630px;
                    flex: none;
                }

                &.coluna-2-subscription{
                    padding: 0;

                    ._partial-regiao-collapse{
                        @include media-breakpoint-down(sm) {
                            display: none !important;
                        }
                    }
                }


                ._partial-regiao-collapse {
                    @include media-breakpoint-down(lg) {
                            width: 100%;
                            margin: 0 0 1rem 0;
                            max-width:630px;
                    }

                    @include media-breakpoint-down(md) {
                        width: 100%;
                        margin: 0 0 1rem 0;
                        max-width: 100%;
                        min-width: 328px;
                    }

                  
                     
                }

                @include media-breakpoint-down(md) {
                    padding: 0 ;
                }

              

                @include media-breakpoint-down(sm) {
                    padding: 0 16px;
                }
            }

            .coluna-3 {
                margin: 0;

                @media screen and (max-width:1330px){
                    width: 45%;
                }

                @include media-breakpoint-down(lg) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    align-content: center;
                    flex-direction: column;
                    margin: 0;
                    width: 100%;
                }

        
                @include media-breakpoint-down(md) {
                    @include width-flex(100%);
                    margin: 0;
                    

                    &.full {
                        width: 100%;
                    }
        
                    .title-coluna {
                        display: none;
                    }
                }
        
                @media (max-width: 767px) {
                    width: 100%;
        
                    &.full {
                        width: 100%;
                    }
                }
                
                @media (max-width: 400px) {
                    width: 100%;
                }

                ._partial-caracteristica-box-texto ._principal,
                ._partial-caracteristica-box-texto
                {
                    @include media-breakpoint-down(lg) {

                        max-width: 630px;
                    }
                }
                
                #partial_like{
                    width: 100%;

                    .gen-card{
                        margin-bottom: $twenty-four-px !important;
                    }
                }

                .what-to-do{
                    width: 100%;
                    margin-top: 24px;
                    
                    max-width: 630px;

                    @media (min-width: 1330px) {
                        width: 630px;
                        padding-right: 0;
                    }

                    @include media-breakpoint-down(lg) {
                        padding-right: 0;
                        margin-top: 0;
                    }
                    @include media-breakpoint-down(md) {
                        padding-right: 0;
                        margin-top: 24px;
                    }

                    @include media-breakpoint-down(xs) {
                        width: 358px;
                    }


                    @media (max-width: 400px) {
                        width: 100%;
                    }

                    .what-to-do-txt{
                        max-width: 100%;
                        width: 100%;
                        margin-right: 0;

                       

                        .gen-card-body{
                            p{
                                font-family: 'Dasa Sans', sans-serif !important;
                                color: $graydark-genera !important;
                                font-size: 1rem !important;
                            }
                        }


                    }
                }

                .bibliographic-reference,.additional-information{
                    width: 100%;
                    max-width: 99%;
                    margin-top: 24px;
                    

                    @media (min-width: 1310px) {
                        max-width: 630px;   
                    }

                    @include media-breakpoint-down(lg) {
                        max-width: 630px !important;
                        margin: 24px 0 0 0 ;
                    }

                    @include media-breakpoint-down(xs) {
                        width: 358px;
                    }

                    @media (max-width: 400px) {
                        width: 100%;
                    }
                }


            }
        }


        
        .box-buttons {
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 999;
            display: flex;
            flex-direction: column;

            #mapa_btn_zoomIn,
            #mapa_btn_zoomOut,
            #mapa_ampliarReduzir {
                background: #696969;
                font-size: 20px;
                padding: 8px;
                height: 38px;
                box-shadow: none;
                line-height: 10px;
                width: 30px;
                border-radius: 6px;
                color: #dadada;
                @include transition;

                span {
                    margin: 0;
                }

                &:hover {
                    background: #474747;
                }

                &.disabled {
                    cursor: default;

                    &:hover {
                        background: #696969;
                    }
                }
            }

            #mapa_btn_zoomIn {
                border-radius: 6px 6px 0 0;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    background: #909090;
                    width: 20px;
                    height: 1px;
                    bottom: -1px;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }

            #mapa_btn_zoomOut {
                border-radius: 0 0 6px 6px;
            }

            #mapa_ampliarReduzir {
                position: relative;
                background: #696969;
                height: 30px;
                padding: 5px;
                margin: 10px 0 0 0;

                @media (max-width: 1200px) {
                    display: none;
                }

                img {
                    width: 14px;
                    height: 14px;
                }
            }
        }
    }

    &._caracteristica {
        z-index: 2;
        
        >.container {
            @include media-breakpoint-down(md) {
                flex-direction: column;
            }

            &.schedule-exam {
                padding-top: 0;
                margin-top: -32px;
                margin-bottom: 56px;

                @include media-breakpoint-down(md) {
                    margin-top: 16px;
                }
        
                @include media-breakpoint-down(lg) {
                    margin-top: 16px;
                }
            }
        }

        .coluna-2 {
            .pub-genera-skin {
                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
        }

        .coluna-3 {
            margin: 0;
        }
    }

    &._busca-parentes {
        z-index: 2;

        > .container {
            background: transparent;
            flex-direction: initial;

            @include media-breakpoint-down(md) {
                max-width: 100%;
                padding: 0;
            }

            &.bp-content {
                padding-top: 0;
                margin-top: -32px;
                margin-bottom: 56px;

                @include media-breakpoint-down(md) {
                    margin-top: 0;
                }
        
                @include media-breakpoint-down(lg) {
                    margin-top: 0;
                }
            }
        }

        .coluna-2-3 {
            @include media-breakpoint-down(md) {
                max-width: 375px;
                padding: 16px;
                margin: 0 auto;
            }

            @include media-breakpoint-down(lg) {
                max-width: 375px;
                padding: 16px;
                margin: 0 auto;
            }
        }

        ._partial-title-box-exemplo {
            margin: 0;
            width: 100%;

            form {
                position: relative;

                input,
                button {
                    outline: none;
                }

                input {
                    background: none;
                    border: 0;
                    border-bottom: 1px solid #FFF;
                    color: #FFF;
                    padding-right: 25px;
                    width: 200px;
                }

                button,
                a {
                    align-items: center;
                    bottom: 0;
                    display: flex;
                    justify-content: center;
                    margin: 0;
                    position: absolute;
                    right: -5px;
                    top: 0;
                    width: 25px;
                }

                button {
                    background: none;
                    border: 0;
                    box-shadow: none;
                    color: #FFF;
                    cursor: pointer;
                }

                a {
                    color: #FFF;
                    text-decoration: none;
                }

                input[value=""] ~ a {
                    display: none;
                }

                input:not([value=""]) ~ button {
                    display: none;
                }
            }

            @include media-breakpoint-down(md) {
                border-radius: 0;

                form {
                    input,
                    button,
                    a {
                        color: #2B2B2B;
                    }

                    input {
                        border-bottom-color: #2B2B2B;
                        width: 150px;
                    }
                }
            }
        }

        ._partial-order {
            label {
                display: flex;
                align-content: center;
                justify-content: center;
                align-items: center;
                width: 16px;
                height: 16px;
                background: $cl-silver-chalice;
                color: #fff;
                border-radius: 100px;
                margin: 0 0 0 8px;
                font-size: 12px;
                font-weight: 700;
                cursor: pointer;
                
                @include media-breakpoint-down(md) {
                    width: 20px;
                    height: 20px;
                }

                @include media-breakpoint-down(lg) {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        ._partial-bp {
            img {
                margin-right: 13px;
            }

            align-items: center;
            justify-content: flex-start;
            overflow: hidden;

            img {
                margin-right: 13px;
            }

            select {
                cursor: pointer;
                padding: 0;
                width: auto;
                padding: 16px 8px;
                background: #fff;
                border: 1px solid $cl-silver-chalice;
                box-sizing: border-box;
                box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
                border-radius: 6px;
                font-family: Mulish;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.25px;
                color: $cl-gray-jet;
                -webkit-appearance: none;

                option {
                    color: $cl-gray-jet;
                }
            }

            select::-ms-expand {
                display: none;
            }

            span {
                margin: 0;
            }

            button {
                background: none;   
                border: none;
                box-shadow: none;
                outline: none;
                padding: 0;
                margin-left: 21px;
                margin-right: 18px;
            }

            .fa-search {
                font-size: 20px;
                color: $cl-gray-jet;
                cursor: pointer;
            }

            .btn-open-modal {
                display: flex;
                align-content: center;
                justify-content: center;
                align-items: center;
                width: 16px;
                height: 16px;
                background: $cl-silver-chalice;
                color: #fff;
                border-radius: 100px;
                margin: 0 0 0 8px;
                font-size: 12px;
                font-weight: 700;
                cursor: pointer;
                
                @include media-breakpoint-down(md) {
                    width: 20px;
                    height: 20px;
                }

                @include media-breakpoint-down(lg) {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        ._partial-box-results {
            margin: 0;

            .relative-header {
                margin-bottom: $twenty-four-px;

                &.search {
                    margin-bottom: 16px;
                }

                .relative-column {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    &.name {
                        width: 42px;
                        margin: 0 112px;
                    }
    
                    &.relationship {
                        width: 140px;
                        min-width: 140px;
                        margin-right: 16px;
                    }
                    
                    &.shared-dna {
                        width: 169px;
                        min-width: 169px;
                        margin-right: 38px;
                    }

                    &.mt-dna {
                        width: 92px;
                        min-width: 92px;
                        margin-right: 42px;
                    }

                    &.y-dna {
                        width: 73px;
                        min-width: 78px;
                        margin-right: 24px;
                    }

                    h5 {
                        &.gen-text {
                            line-height: $eighteen-px;
                        }
                    }

                    .column-title {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        label {
                            display: flex;
                            align-content: center;
                            justify-content: center;
                            align-items: center;
                            width: 16px;
                            height: 16px;
                            background: $cl-silver-chalice;
                            color: $cl-branco;
                            border-radius: 100px;
                            margin: 0 0 0 8px;
                            font-size: 12px;
                            font-weight: bold;
                            cursor: pointer;
                        }
                    }
                }

                .fa-chevron-up {
                    font-size: 14px;
                    margin-bottom: 6px;
                    color: $cl-silver-chalice;
                    cursor: pointer;

                    &.active {
                        color: $cl-gray-jet;
                    }
                }

                .fa-chevron-down {
                    font-size: 14px;
                    margin-top: 8px;
                    color: $cl-silver-chalice;
                    cursor: pointer;

                    &.active {
                        color: $cl-gray-jet;
                    }
                }

                .search-parent {
                    background: none;
                    border: none;
                    box-shadow: none;
                    outline: none;

                    i {
                        font-size: 20px;
                        color: $cl-gray-jet;
                        cursor: pointer;
                    }
                }

                .sortable {
                    &:hover {
                        text-decoration: none;
                    }
                }

                #searchModal {
                    h5 {
                        font-family: 'Mulish';
                        font-weight: bold;
                        text-align: left;
                        font-size: 14px;
                        line-height: 18px;
                        color: $cl-gray-davys;
                        margin: 0;
                    }

                    .form-group {
                        padding: 0;
                        margin: 0;
                        min-height: 45px;
                        position: relative;
                    }

                    .input-group-addon {
                        display: block;
                    }

                    button {
                        background: none;   
                        border: none;
                        box-shadow: none;
                        outline: none;
                        position: absolute;
                        top: 15px;
                        right: 0px;
                    }

                    .fa-search {
                        font-size: 20px;
                        color: $cl-gray-jet;
                        cursor: pointer;
                    }

                    label{
                        left: 20px;
                    }

                    small {
                        width: 100%;
                        text-align: start;
                    }

                    p {
                        font-family: Mulish;
                        font-size: 11px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 14px;
                        letter-spacing: 0.5px;
                        text-align: left;
                        margin: 4px 0 0;
                        color: $cl-gray-davys;
                    }
                }

                .info-modal {
                    p {
                        line-height: $twenty-four-px;
                    }
                }
            }

            .relative-tips {
                display: flex;
                align-items: center;
                margin-bottom: 16px;

                .gen-btn-link {
                    --text-size: .875rem;
                }

                .tip {
                    display: flex;
                    align-content: center;
                    align-items: center;
                    justify-content: space-between;
                    height: 30px;
                    background-color: $cl-gray-jet;
                    border-radius: 6px;
                    padding: 8px;
                    border: none;
                    box-shadow: none;
                    outline: none;
                    cursor: text;
                    margin: 0 16px 0 0;

                    p {
                        color: $cl-branco;
                        font-family: Mulish;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 11px;
                        line-height: 14px;
                        letter-spacing: 0.5px;
                        margin: 0 8px 0 0;
                    }
                }

                .remove-name {
                    cursor: pointer;
                    margin: 0;

                    i {
                        color: $cl-branco;
                        font-size: 12px;
                        cursor: pointer;
                    }
                }

            }

            .relative-results {
                .relative {
                    display: flex;
                    margin-bottom: $sixteen-px;

                    .avatar {
                        width: 182px;
                        margin-right: 20px;
                        text-align: start;
                        > div {
                            p {
                                word-break: break-all;
                            }
                        }
                    }

                    .initial-avatar-col {
                        width: 48px;
                        margin-right: 16px;

                        .initials {
                            width: 48px;
                            height: 48px;
                            background-color: $cl-gray-platinum;

                            p {
                                line-height: 24px;
                                padding: 12px 6px;
                            }
                        }
                    }

                    .suggestion {
                        width: 140px;
                        margin-right: 6px;
                    }

                    .shared-dna {
                        width: 185px;
                        margin-right: 32px;
                    }
                    
                    .mt-dna {
                        width: 92px;
                        margin-right: 76px;
                    }

                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                    
                    @include media-breakpoint-down(lg) {
                        display: none;
                    }
                }

                .relative-mobile {
                    display: none;
                    margin-bottom: $sixteen-px;

                    @include media-breakpoint-down(md) {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        .mobile-line-1 {
                            .initial-avatar-col {
                                width: auto;
                                padding: 0;
                                margin-right: 16px;

                                .initials {
                                    width: 48px;
                                    height: 48px;

                                    p {
                                        font-size: 16px;
                                        padding: 11px 5px;
                                        margin: 0;
                                    }
                                }
                            }

                            .avatar {
                                .name {
                                    line-height: 18px;
                                    text-align: left;
                                    margin-bottom: 8px;
                                    word-break: break-word;
                                }

                                .email {
                                    line-height: 14px;
                                    text-align: left;
                                    margin: 0;
                                }
                            }
                        }

                        .mobile-line-2 {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            padding-left: 64px;

                            p {
                                --text-size: 12px;
                                line-height: 14px;
                                text-align: left;

                                strong {
                                    color: $cl-gray-jet;
                                }
                            }
                        }
                    }

                    @include media-breakpoint-down(lg) {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        .mobile-line-1 {
                            .initial-avatar-col {
                                width: auto;
                                padding: 0;
                                margin-right: 16px;

                                .initials {
                                    width: 48px;
                                    height: 48px;

                                    p {
                                        font-size: 16px;
                                        padding: 11px 5px;
                                        margin: 0;
                                    }
                                }
                            }

                            .avatar {
                                .name {
                                    line-height: 18px;
                                    text-align: left;
                                    margin-bottom: 8px;
                                    word-break: break-word;
                                }

                                .email {
                                    line-height: 14px;
                                    text-align: left;
                                    margin: 0;
                                    word-break: break-all;
                                }
                            }
                        }

                        .mobile-line-2 {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            padding-left: 64px;

                            p {
                                --text-size: 12px;
                                line-height: 14px;
                                text-align: left;

                                strong {
                                    color: $cl-gray-jet;
                                }
                            }
                        }
                    }

                    hr {
                        width: 100%;
                        border-top: 1px solid #F7F7F7;
                        margin: 16px 0;
                    }
                }
            }

            .count-relatives {
                p {
                    line-height: $twenty-four-px;
                    text-align: left;
                }
            }

            ._pagination {
                &.bp {
                    ul {
                        margin-top: $twenty-four-px;
                    }
                }
            }
        }

        .pagination {
            margin: 0;
        }

        #mobileModal {
            font-family: 'Mulish';
            letter-spacing: .5px;
            color: $cl-gray-davys;

            .modal-dialog {
                margin: 16px;
            }

            h4 {
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
            }        

            p {
                font-size: 14px;
                line-height: 18px;
            }

            .y-dna {
                p {
                    margin: 0;
                }
            }
        }

        #searchMobileModal {
            button {
                background: none;   
                border: none;
                box-shadow: none;
                outline: none;
                position: absolute;
                top: 16px;
                right: 0px;

                &.btn-close {
                    position: initial;
                }
            }

            .fa-search {
                font-size: 20px;
                color: $cl-gray-jet;
                cursor: pointer;
            }
        }

        .header-modal {
            display: flex;
            justify-content: space-between;
            margin-bottom: 11px;
            align-items: center;

            &.all {
                justify-content: flex-end;
                margin-bottom: 4px;
            }
        }

        .btn-close {
            position: inherit;

            &.is-modal {
                margin-left: 0;
                margin-right: 0;
            }

            img {
                margin: 0;
            }
        }
       
        @include media-breakpoint-down(md) {
            ._partial-box-results {
                margin: 0;
            }
        }

        @include media-breakpoint-down(lg) {
            ._partial-box-results {
                margin: 0;
            }
        }

        #container-bp {
            padding: 0;
            min-height: 100%;
            background: none;
            display: block;
            position: relative;
    
            @include media-breakpoint-down(md) {
                width: 100%;
                flex-direction: column-reverse;
                clear: both;
            }
    
            @include media-breakpoint-down(lg) {
                width: 100%;
                flex-direction: column-reverse;
                clear: both;
            }

            .card-filter-bp {
                display: none;
        
                @include media-breakpoint-down(md) {
                    display: flex;
                    justify-content: center;
                    width: calc(100% + 25px);
                    padding: 0;
                    width: 100%;
                }
        
                @include media-breakpoint-down(lg) {
                    display: flex;
                    justify-content: center;
                    width: calc(100% + 25px);
                    padding: 0;
                    width: 100%;
                }
        
                .filter-bp {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    align-content: center;
                    position: relative;
                    height: 50px;
        
                    @include media-breakpoint-down(md) {
                        padding: 0;
                    }
        
                    @include media-breakpoint-down(lg) {
                        padding: 0;
                    }
        
                    #select-bp-filter {
                        width: 204px;
                        height: 40px;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 18px;
                        letter-spacing: 0.25px;
                        color: #333333;
                    }
        
                    .custom-select-bp {
                        position: relative;
                        float: right;
                        background: #FFFFFF;
                        box-sizing: border-box;
                        border: none;
                        border-radius: 6px;
                        width: 220px;
                        min-width: 220px;
                        height: 50px;
                        font-size: 14px;
                        line-height: 25px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding: 16px 8px;
                        border: 1px solid $cl-silver-chalice;      
                        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16); 
        
                        .hide-button,
                        .hide-link {
                            display: none;
                        }
        
                        select {
                            display: none;
                        }
        
                        .select-selected {
                            display: flex;
                            align-items: center;
                            font-family: 'Mulish';
                            font-size: 14px;
                            line-height: 18px;
                            letter-spacing: .25px;
                            border-radius: 4px;
        
                            &:after {
                                position: absolute;
                                z-index: 9999;
                                content: "";
                                top: 22px;
                                right: 7px;
                                width: 12px;
                                height: 7px;
                                background: url('#{$path-image}/arrow-bottom.svg') no-repeat;
                            }
        
                            &.select-arrow-active{
                                &:after {
                                    border-color: transparent transparent #fff transparent;
                                }
                            }
                        }
                    }
        
                    .select-items-bp div,
                    .select-selected {
                        color: $cl-gray-jet;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 18px;
                        letter-spacing: .25px;
                        text-align: center;
                        user-select: none;
                        cursor: pointer;
                    }
        
                    .select-items-bp {
                        position: absolute;
                        background-color: #fff;
                        top: 40px;
                        left: -1px;
                        right: 0;
                        z-index: 99;
                        border-radius: 4px;
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                        border: 1px solid $cl-silver-chalice;
                        border-top: 0;
                        width: calc(100% + 2px);
        
                        div {
                            padding: 10px;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            height: 45px;
                            line-height: 18px;
                            letter-spacing: .25px;
                        }
        
                        &.select-hide {
                            display: none;
                        }
                    }
        
                    .select-items-bp div:hover,
                    .same-as-selected {
                        background-color: #ccc;
                    }
                }
            }

            
        }

        #filter-bp {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            align-content: center;
            position: relative;
            width: 100%;
            height: 40px;

            #select-bp-filter {
                width: 204px;
                height: 40px;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.25px;
                color: #333333;
            }

            .custom-select-bp {
                position: relative;
                float: right;
                background: #FFFFFF;
                box-sizing: border-box;
                border: none;
                border-radius: 4px;
                width: 204px;
                height: 40px;
                font-size: 14px;
                line-height: 25px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 11px 8px 11px 28px;
                border: 1px solid $cl-silver-chalice;

                &.selected-all {
                    &::before {
                        content: '';
                        position: absolute;
                        margin-left: -20px;
                        width: 12px;
                        height: 12px;
                        border-radius: 100%;
                        background: $cl-maximun-red;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        margin-left: -11px;
                        width: 12px;
                        height: 12px;
                        border-radius: 100%;
                        background: $cl-yellow-munsel;
                    }

                    &.circle-all .select-selected::before {
                        content: '';
                        position: absolute;
                        width: 12px;
                        height: 12px;
                        border-radius: 100%;
                        left: 26px;
                        z-index: 2;
                        background: $cl-cyan-process;
                    }

                    .select-selected {
                        margin-left: 20px;
                    }
                }

                &.selected-high {
                    &::before {
                        content: '';
                        position: absolute;
                        margin-left: -20px;
                        width: 12px;
                        height: 12px;
                        border-radius: 100%;
                        background: $cl-maximun-red;
                    }
                }

                &.selected-medium {
                    &::before {
                        content: '';
                        position: absolute;
                        margin-left: -20px;
                        width: 12px;
                        height: 12px;
                        border-radius: 100%;
                        background: $cl-yellow-munsel;
                    }
                }

                &.selected-low {
                    &::before {
                        content: '';
                        position: absolute;
                        width: 12px;
                        height: 12px;
                        border-radius: 100%;
                        left: 8px;
                        z-index: 2;
                        background: $cl-cyan-process;
                    }
                }

                @include media-breakpoint-down(md) {
                    padding-right: 5px;
                }

                @include media-breakpoint-down(lg) {
                    padding-right: 5px;
                }

                .hide-button,
                .hide-link {
                    display: none;
                }

                select {
                    display: none;
                }

                .select-selected {
                    display: flex;
                    align-items: center;
                    font-family: 'Mulish';
                    font-size: 14px;
                    line-height: 18px;
                    letter-spacing: .5px;
                    border-radius: 4px;

                    &:after {
                        position: absolute;
                        z-index: 9999;
                        content: "";
                        top: 15px;
                        right: 7px;
                        width: 12px;
                        height: 7px;
                        background: url('#{$path-image}/arrow-bottom.svg') no-repeat;
                    }

                    &.select-arrow-active{
                        &:after {
                            border-color: transparent transparent #fff transparent;
                        }
                    }
                }
            }

            .select-items-bp div,
            .select-selected {
                color: #333;
                font-weight: 700;
                text-align: center;
                cursor: pointer;
                user-select: none;
            }

            .select-items-bp {
                position: absolute;
                background-color: #fff;
                top: 35px;
                left: -1px;
                right: 0;
                z-index: 99;
                border-radius: 4px;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border: 1px solid $cl-silver-chalice;
                border-top: 0;
                width: calc(100% + 2px);

                &::before {
                    content: '';
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    border-radius: 100%;
                    top: 11px;
                    left: 26px;
                    z-index: 2;
                    background: $cl-cyan-process;
                }

                &.no-before {
                    &::before {
                        display: none;
                    }
                }

                div {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-left: 28px;
                    height: 34px;
                    line-height: 18px;
                    letter-spacing: .5px;
                    padding: 10px;
                    color: #333;
                    font-weight: 700;
                    text-align: center;
                    cursor: pointer;
                }

                div[data-select-risk$="Menor DNA Compartilhado"] {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-left: 28px;
                    height: 34px;
                    line-height: 18px;
                    letter-spacing: .5px;
                }

                div[data-select-risk$="Nome ( A-Z )"]{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-left: 28px;
                    height: 34px;
                    line-height: 18px;
                    letter-spacing: .5px;
                }

                div[data-select-risk$="Nome ( Z-A )"],
                div[data-select-risk$="reducido"] {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-left: 28px;
                    height: 34px;
                    line-height: 18px;
                    letter-spacing: .5px;
                }

                &.select-hide {
                    display: none;
                }
            }

            .select-items-bp div:hover,
            .same-as-selected {
                background-color: #ccc;
            }
        }
    }

    &._recurring_release {
        z-index: 2;

        .fas {
            display: none;
        }

        @include media-breakpoint-down(md) {
            margin-top: 0;

            .fas {
                display: block;
                transform: rotateZ(0deg);
                transition: all 300ms ease-in-out;

                &.collapse {
                    -webkit-transform: rotateZ(180deg);
                    transform: rotateZ(180deg);
                    transition: all .3s ease-in-out;
                }
            }

            .coluna-2-3 {
                padding: 0;

                .box-title-next-results {
                    padding: 16px;
                }

                .box-await-results  {
                    padding: 16px;
                }

                .box-next-result {
                    padding: 16px 16px 0 16px;
                    min-height: 56px;

                    h4 {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 16px;
                    }

                    .exam-features {
                        label {
                            margin: 0 16px 16px 0;

                            &:first-child {
                                margin: 0 16px 16px 0;
                            }
                            
                            &:last-child {
                                margin: 0 0 16px 0;
                            }
                        }
                    }

                    &:last-child {
                        margin-bottom: 24px;
                    }
                }
            }

            .menu-recurring-results {
                .box-schedule-results {
                    padding: 16px !important;
                }
            }
        }

        @include media-breakpoint-down(lg) {
            margin-top: 0;

            .fas {
                display: block;
                transform: rotateZ(0deg);
                transition: all 300ms ease-in-out;

                &.box-open {
                    -webkit-transform: rotateZ(180deg);
                    transform: rotateZ(180deg);
                    transition: all .3s ease-in-out;
                }
            }

            .coluna-2-3 {
                padding: 0;

                .box-title-next-results {
                    padding: 16px;
                }

                .box-await-results  {
                    padding: 16px;
                }

                .box-next-result {
                    padding: 16px 16px 0 16px;
                    min-height: 56px;

                    h4 {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 16px;
                    }

                    .exam-features {
                        label {
                            margin: 0 16px 16px 0;

                            &:first-child {
                                margin: 0 16px 16px 0;
                            }
                            
                            &:last-child {
                                margin: 0 0 16px 0;
                            }
                        }
                    }

                    &:last-child {
                        margin-bottom: 24px;
                    }
                }
            }

            .menu-recurring-results {
                .box-schedule-results {
                    padding: 16px !important;
                }
            }
        }

        .menu-recurring-results {
            background:none;
            padding: 0 0 0 11px;

            &.mobile {
                padding: 0 16px 16px;
            }
        }

        .box-await-results {
            @include box-branco;
            padding: 24px;

            h3 {
                font-family: Mulish;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
                letter-spacing: .5px;
            }

            p {
                font-family: Mulish;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: .5px;
                margin: 0;
            }
        }

        .box-recurring-results {
            @include box-branco;
            padding: 24px;
        }
        
        .box-title-next-results {
            @include box-branco;
            padding: 24px;

            h3 {
                font-family: Mulish;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
                letter-spacing: .5px;
                margin: 0;
            }
        }

        .box-next-result {
            @include box-branco;
            padding: 24px 24px 0 24px;
            margin-bottom: 18px;

            &:last-child {
                margin-bottom: 64px;
            }

            h4 {
                font-family: Mulish;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: .5px;
                margin-bottom: 24px;
            }

            .exam-features {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                flex-wrap: wrap;

                label {
                    padding: 8px 16px;
                    background: $cl-silver-cultured;
                    border-radius: 6px;
                    margin: 0px 16px 24px 0;

                    font-family: Mulish;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    letter-spacing: .5px;
                    color: $cl-gray-jet;

                    &:first-child {
                        margin: 0 16px 24px 0;
                    }
                    
                    &:last-child {
                        margin: 0 0 24px 0;
                    }
                }
            }
        }
    }
}

.page-print {
    @media print {
        width: 262.5mm;
        max-width: 262.5mm !important;
        min-height: 371mm !important;
        padding: 0;
        margin: 0mm auto;
        border-radius: 5px;
        background: #f7f7f7;
        -webkit-print-color-adjust: exact;
        border: initial;
        box-shadow: initial;
    }

    .container {
        @media print {
            max-width: 215mm !important;
        }
    }

    .content-break-page {
        padding-top: 38px;
    }

    .page-print-content {
        @media print {
            padding: 0;
            height: 350mm;
            background: #f7f7f7;
            -webkit-print-color-adjust: exact;    
        }

        .full-banner-image {
            .exams-cover {
                &.print {
                    @media print {
                        width: 100%;
                        max-width: 1370px;
                    }
                }
            }
        }

        #header {
            .content {
                .perfil {
                    img {
                        border-radius: 50%;
                        width: 64px;
                        height: 64px;
                        margin-top: 2px;
    
                        @media print {
                            width: 44px;
                            height: 44px;
                        }
                    }

                    .initials {
                        width: 54px;
                        height: 54px;
                        margin-top: 2px;

                        @media print {
                            width: 44px;
                            height: 44px;
                        }

                        p {
                            line-height: 40px;

                            @media print {
                                line-height: 28px;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }

    .header-print {
        .show-pefil-menu {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0 0 0 15px;
            color: $cl-branco;

            .perfil {
                padding-right: 16px;
                margin: 16px 0;

                img {
                    border-radius: 50%;
                    width: 64px;
                    height: 64px;
                    margin-top: 2px;

                    @media print {
                        width: 44px;
                        height: 44px;
                    }
                }

                .initials {
                    width: 64px;
                    height: 64px;
                    background-color: #cdcdcd;
                    border-radius: 50%;
                    display: inline-block;
                    margin-top: 2px;

                    @media print {
                        width: 44px;
                        height: 44px;
                    }

                    p {
                        font-style: normal;
                        font-weight: 600;
                        line-height: 45px;
                        font-size: 20px;
                        padding: 8px;
                        text-align: center;
                        color: $cl-gray-jet;

                        @media print {
                            line-height: 28px;
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        &.detail {
            .view {
                #container-prs-print {
                    margin-top: -10px;

                    @media print {
                        margin-top: -18px;
                    }
                }
            }

            .exams-cover {
                &.print {
                    .cover-description {
                        @media print {
                            height: 200px !important;
                            min-height: 200px !important;
                            max-height: 200px !important;

                            .gen-card-body {
                                --card-body-padding-x: 1rem;
                                --card-body-padding-y: 1rem;

                                @media print {
                                    --card-body-padding-x: 0;
                                    --card-body-padding-y: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .fas {
        display: none;
    }
}

.page-break {
    @media print {
        page-break-before: always;
    }
}

.footer-print {
    width: 100%;
    position: fixed;
    bottom: 0;
    background: $cl-silver-cultured;
    -webkit-print-color-adjust: exact; /* WebKit (Chrome, Safari, Opera) */
    print-color-adjust: exact; /* Padrão CSS */
    z-index: 99999;

    @media print {
        display: block;
        width: 242mm;
        min-width: 242mm;
        margin: 16px 26px 0 26px;
    }
    
    >.container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: $cl-silver-cultured;
        -webkit-print-color-adjust: exact; /* WebKit (Chrome, Safari, Opera) */
        print-color-adjust: exact; /* Padrão CSS */
        border-top: 1px solid $cl-gray-davys;
        padding: 5px 0 10px;
        margin: 0 auto;

        @media print {
            width: 246mm!important;
            min-width: 246mm!important;
            margin: 0 auto;
        }
        
        .copyright {
            p {
                font-family: Mulish;
                font-size: 11px;
                font-style: normal;
                font-weight: 600;
                line-height: 14px;
                letter-spacing: 0.5px;
                text-align: right;
                margin: 0;
            }
        }
    }
    .logo {
        margin: 0;
    }
}

.btn-back-mobile {
    display: none;

    @include media-breakpoint-down(md) {
        display: inherit;
        position: fixed;
        bottom: 4px;
        left: 0;
        z-index: 99999;
    }
}

.carousel {
    .tns-controls {
        display: none;
        outline: 0;
        position: absolute;
        right: 0;
        top: -32px;
        width: 70px;
        z-index: 1;

        button {
            background: none;
            border: 0;
            bottom: 0;
            box-shadow: none;
            width: 32px;
            height: 32px;
            margin: auto 0;
            outline: 0;
            padding: 0;
            position: absolute;
            top: 0;

            &[disabled] {
                opacity: .5;
            }

            i {
                display: flex;
                justify-content: center;
                align-items: center;
                background: $cl-gray-davys;
                color: $cl-branco;
                border-radius: 50%;
                width: 32px;
                height: 32px;
                outline: 0;
            }

            &:first-child {
                left: -8px;
            }

            &:last-child {
                right: 0;
            }
        }
    }

    &.carousel-initialized {
        .tns-controls {
            display: block;
        }
    }
}

.btn-print {
    align-items: center;
    background: #CCCCCC;
    border-radius: 8px;
    color: #757575;
    display: flex;
    font-size: 12px;
    font-weight: 600;
    height: auto;
    min-height: 38px;
    padding: 5px 0;
    justify-content: center;
    line-height: 38px;
    text-align: center;
    transition: background .2s ease;

    @include media-breakpoint-down(lg) {
        display: none;
    }

    @include media-breakpoint-down(lg) {
        display: none;
    }

    &.caracteristica {
        display: none;

        @include media-breakpoint-down(lg) {
            display: contents;
        }
    
        @include media-breakpoint-down(lg) {
            display: contents;
        } 

        i {
            svg {
                path {
                    fill: #FFF;
                }
            }
        }
    }

    i {
        display: inline-block;
        height: 22px;
        margin: 0 5px 0 0;
        width: 25px;

        svg {
            display: block;

            path {
                fill: #777;
                height: 22px;
                width: 25px;
            }
        }

        &.spinner-border {
            border-color: #757575 transparent #757575 #757575;
            border-width: 2px;
            height: 18px;
            margin: 0 12px 0 0;
            width: 18px;

            svg {
                display: none;
            }
        }
    }

    &._btn-ancestry {
        transition: background .2s ease;
        background: transparent;
        margin: 0;
        width: 24px;
        height: 24px;

        @include media-breakpoint-down(md) {
            display: none;
        }
    
        @include media-breakpoint-down(lg) {
            display: none;
        }

        i {
            margin: 0;
            svg {
                path {
                    fill: #fff;
                    height: 22px;
                    width: 25px;
                }
            }
        }
    }

    &:hover {
        color: #393939;
        text-decoration: none;

        svg path {
            fill: #393939;
        }
    }

    &.loading {
        color: #757575;
        cursor: default;
        pointer-events: none;

        svg path {
            fill: #757575;
        }
    }

    &.btn-prs {
        min-width: 136px;
    }
}

.btn-print-ancestry-mobile {
    .btn-print {
        line-height: 28px;
        
        &._btn-ancestry {
            display: block;
        }
    }
}

.btn-print-result {
    i {
        &.spinner-border {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            color: $cl-gray-jet;
        }
    }
}

._linhagem {
    @include media-breakpoint-up(lg) {
        .row {
            margin: 0 -8px;

            .col-12 {
                padding-left: 8px;
                padding-right: 8px;
            }
        }
    }
}

//Modal PRS styles
#acceptDiseasePrsModal {
    .modal-dialog {
        max-width: 640px;
        margin: 1.75rem auto;
        border: none;
        @media (max-width: 992px ) {
            width: 95%;
        }
        .modal-content {
            border-radius: 10px;
            border: none;
        }
        &:before {
            content: '';
            width: 100%;
            height: 8px;
            position: absolute;
            background: $cl-maximun-red;
            border-top-left-radius:6px;
            border-top-right-radius:6px;
            z-index: 1;
            right: 0;
            left: 0;
            top: 0;
        }
    }
    .modal-body {
        @media (max-width: 992px ) {
            padding: 1rem 0;
        }
    }
    .form-group {
        padding-top: 16px;
    }
    small {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.25px;
        color: #66114C;
    }
    h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 0.25px;
        color: #3C3C3B;
        margin: 8px 0 16px;
        @media (max-width: 992px ) {
            font-size: 20px;
            line-height: 25px;
        }
    }
    p {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.5px;
        color: #757575;
        b {
            color: #333;
        }
    }
    button {
        box-shadow: none;
        font-weight: 600;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 26px;
        text-align: center;
        letter-spacing: 0.25px;
        color: #FFFFFF;
        margin: 0px 30px 40px;
        @media (max-width: 992px ) {
            margin-left: 16px;
        }
    }
    .modal-footer {
        background: #757575;
        border-radius: 0px 0px 6px 6px;

        label {
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.25px;
            color: #FFFFFF;
            position: relative;
            padding-left: 10px;
            margin-left: 30px;

            &:before {
                content: '';
                height: 18px;
                width: 18px;
                position: absolute;
                left: -16px;
                top: 0;
                border-radius: 3px;
                border: 2px solid #fff;
            }
        }
        input[type=checkbox]:checked + label:before {
            content: "";
            height: 18px;
            width: 18px;
            background: url('#{$path-image}/icon-check-white.png') no-repeat;
            background-size: 100%;
            position: absolute;
            left: -16px;
            top: 0;
            border-radius: 3px;
            border: 2px solid #fff;
        }
        input[type="checkbox"] {
            display: none;
        }
    }
}

.new-modal-genera {
    .modal-content {
        max-width: 448px;
    }

    .modal-title {
        display: flex;
        width: 100%;
        margin-bottom: 26px;
    }

    .return-results-panel {
        text-decoration: underline;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.5px;
        color: #333333;
        margin-bottom: 2px;
    }

    img {
        margin-right: 16px;
    }

    h5 {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.25px;
        margin: 0;
    }

    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 24px;
        letter-spacing: 0.5px;
        color: #333333;
    }
}

.cursor-pointer {
  cursor: pointer;
}

.tooltip-class {
    position: absolute;
    right: 0;
    top: 5px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    margin: 0 0 0 5px;
    background: #eae8e8;
    color: #ababab;
    font-weight: 700;
    border-radius: 50%;
    font-size: 14px;
    display: inline-block;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.gen-btn-link {
    box-shadow: none;
    
    &:focus {
        outline: none;
    }
}

.actions-footer {
    display: flex;
    justify-content: start;
    align-items: center;

    button {
        margin-right: 1rem;

        &:last-child {
            margin: 0;
        }
    }
}

.modal-footer {
    &.btns-width-full {
        button {
            width: 100%;
        }
    }
}

.container.dhl {
    .gen-bg-transparent {
        background: transparent!important;    
        margin: auto;
        @media (min-width: 1025px) {
            display: flex;
            flex-direction: row;
            max-width: inherit;
            justify-content: center;
            margin-bottom: 112px;
        }

        h3 {
            margin: 40px 0 32px;
        }
    }
    .no-shadow {
        .gen-card-body {
            .gen-card {
                width: 328px;
                margin-bottom: 24px;
                &:last-child {
                    @include media-breakpoint-down(md) {
                        margin-bottom: 32px;
                    }
                }

                &.reduced {
                    width: auto;
                    margin: 16px 0 8px;
                }

                .icon-alert {    
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    padding: 0;
                    width: 228px;
                    align-items: center;
                    img {
                        margin: 0;
                    }
                    p {
                        font-size: 11px;
                    }
                }
            }
        } 
    } 
}

.footer-inline {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .other-options {
        --text-weight: 500;
        --text-size: 1rem;
        line-height: 150%;
        text-decoration: underline;
        margin-top: 1rem;

        @include media-breakpoint-down(md) {
            margin-top: 1.5rem;
        }
    }
}

.gen-btn {
    &.gen-btn-link {
        &.text-underline {
            text-decoration: underline;
        }
    }
}

.line-height-14 {
    line-height: $fourteen-px;
}

.line-height-18 {
    line-height: $eighteen-px;
}

.line-height-24 {
    line-height: $twenty-four-px;
}

.line-height-25 {
    line-height: $twenty-five-px;
}

.line-height-28 {
    line-height: $twenty-eight-px;
}

.line-height-30 {
    line-height: $twenty-five-px;
}

.fw-600 {
    --text-weight: 600;    
}

.fw-700 {
    --text-weight: 700;    
}

.box-coleta {
    margin: 80px 0 281px 151px;

    @include media-breakpoint-down(md) {
        margin: 0;
    }

    * {
        font-family: 'Dasa Sans', sans-serif;
        --text-font-family: 'Dasa Sans', sans-serif;
    }
    
    p {
        line-height: 170%;
        color: $cl-texto2;
        font-size: 1rem;

        &.gen-validation {
            margin-top: .25rem;
            --text-size: .6875rem;
            --text-weight: 400;
        }

        &.warning-extended-deadline {
            line-height: 150%;
            --text-color: #EB1F40;
            --text-letter-spacing: 0;
            
            a {
                --text-color: #EB1F40;
                --text-weight: 700;
                text-decoration: underline;
            }
        }

        &.caption {
            line-height: 150%;

            &.code-coverage-area {
                max-width: 46.3125rem;
                margin-bottom: 1.5rem;

                @include media-breakpoint-down(md) {
                    --text-weight: 400;
                    letter-spacing: 0;
                }
            }
        }
    }
    
    h1 {
        --text-size: 2rem;
        --text-letter-spacing: -1px;
        line-height: 125%;
        transform: none !important;
    }

    hr {
        width: 100%;
        border-top: 1px solid rgba(22, 22, 22, 0.24);
    }

    .gen-card {
        --background: none;
        box-shadow: none;
        
        .card-return-partner-lab {
            width: 740px;

            @include media-breakpoint-down(md) {
                max-width: calc(100vw - 32px);
                margin: 0 auto;
            }

            .gen-btn-link {
                &.text-underline {
                    --text-size: 1rem;
                    text-decoration: underline;
                    line-height: 1.75rem;
                }
            }
        
            p {
                &.caption {
                    line-height: 1.125rem;
                }

                &.cep {
                    line-height: 150%;
                }
            }
        }

        &.card-address-check {
            max-width: 589px;
        }

        &.card-postal-code-correios,
        &.card-address-complete,
        &.card-address-confirmation,
        &.card-address-confirmation-3,
        &.card-collect-confirmed,
        &.card-error-return {
            max-width: 46.25rem;

            @include media-breakpoint-down(md) {
                max-width: 100%;
            }
        }

        &.card-address-complete {
            margin-bottom: 5rem;
            
            @include media-breakpoint-down(md) {
                padding: 4rem 1rem 0;
                margin-bottom: 2rem;
            }
        }

        &.card-collect-confirmed {
            @include media-breakpoint-down(md) {
                padding: 4rem 1rem 2rem;
            }

            p {
                line-height: 150%;
                --text-letter-spacing: 0;
            }

            .alma-btn {
                display: none;

                @include media-breakpoint-down(md) {
                    display: block;
                    width: fit-content;
                }
            }
        }

        &.card-error-return {
            @include media-breakpoint-down(md) {
                padding: 4rem 1rem 2rem;
            }

            p {
                a {
                    text-decoration: underline;
                    color: $cl-cinza3;
                }
            }

            ul {
                margin-bottom: 1.5rem;

                @include media-breakpoint-down(md) {
                    padding-left: 1rem;
                }

                li {
                    font-size: 1rem;
                }
            }
        }
        
        &.card-return-correios,
        &.card-address-check,
        &.card-address-confirmation,
        &.card-address-confirmation-3,
        &.card-postal-code-correios {
            width: 100%;
            
            @include media-breakpoint-down(md) {
                margin-bottom: 1rem;
                padding-top: 2rem;
            }
            
            @include media-breakpoint-down(lg) {
                margin-bottom: 1rem;
            }
        
            .gen-card-body {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0;
                
                @include media-breakpoint-down(md) {
                    padding: 1.5rem 1rem;
                }
        
                img {
                    margin-bottom: .5rem;
                    margin-top: 2rem;
        
                    @include media-breakpoint-down(md) {
                        margin-top: 0;
                    }
        
                    @include media-breakpoint-down(lg) {
                        margin-top: 0;
                    }
                }
        
                .address-box {
                    background: $cl-cinza6;
                    border: 1px solid rgba(22, 22, 22, 0.24);
                    border-radius: 6px;
                    width: 100%;
                    max-width: 20.5rem;
                    padding: 1rem;

                    @include media-breakpoint-down(md) {
                        max-width: 100%;
                    }
                }
        
                h4 {
                    --text-weight: 600;
                }
            }
        }

        &.card-address-confirmation,
        &.card-address-confirmation-3 {
            .gen-card-body {
                h4 {
                    --text-weight: 700;
                }

                .address-box {
                    &.confirmation {
                        max-width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        @include media-breakpoint-down(md) {
                            align-items: flex-start;
                            flex-direction: column;
                            gap: 1rem;
                        }
    
                        .address {
                            max-width: 29.5rem;
                            font-size: .875rem;
                        }
    
                        .gen-btn {
                            border: 1px solid #161616;
                            border-radius: .25rem;
    
                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }

                .pickup-information {
                    width: 100%;
                    display: flex;

                    @include media-breakpoint-down(md) {
                        align-items: flex-start;
                        flex-direction: column;
                        gap: 1.5rem;
                    }
                    
                    .deadline-pickup {
                        width: 30%;
                        margin-right: 1rem;
                        
                        @include media-breakpoint-down(md) {
                            width: 100%;
                            margin-right: 0;
                        }
        
                        h4 {
                            --text-letter-spacing: 0;
                        }
        
                        p {
                            line-height: 150%;
                        }
                    }
        
                    .pickup-time {
                        width: 70%;

                        @include media-breakpoint-down(md) {
                            width: 100%;
                        }
        
                        h4 {
                            --text-letter-spacing: 0;
                        }
        
                        p {
                            line-height: 150%;
                            --text-letter-spacing: 0;
                        }
                    }
                }
            }
        }

        &.card-address-confirmation-3 {
            .gen-card-body {
                .address-box {
                    &.confirmation {
                        max-width: 20.5rem;
                        letter-spacing: 0;
                        
                        @include media-breakpoint-down(md) {
                            max-width: 100%;
                        }
                    }
                }

                .important {
                    line-height: 150%;
                }
            }

            @include media-breakpoint-down(md) {
                padding: 2rem 1rem 0;
                margin-bottom: 2rem;
            }
        }

        &.card-postal-code-coverage-area {
            width: 100%;
            max-width: 66.5rem;

            @include media-breakpoint-down(md) {
                max-width: 410px;
                padding: 2rem 1rem;
                margin-top: 2rem;
            }

            .gen-card {
                &.block {
                    width: 338px;
                    max-width: 90%;
                    margin: 0 auto 24px;
                    @media (min-width: 1025px) {
                        margin: 0 12px;
                    }
                }
            }

            .gen-card-body {         
                h1 {
                    --text-letter-spacing: 0;
                }

                .box-options {
                    display: flex;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    gap: 1rem;

                    .gen-card {
                        @include media-breakpoint-down(md) {
                            width: 100%;
                        }
                    }

                    @include media-breakpoint-down(md) {
                        gap: 1.5rem;
                    }
                }
                
                &.return-options {
                    border: 1px solid rgba(22, 22, 22, 0.24);
                    border-radius: 8px;
                    background: #FFFFFF;
                    padding: 16px;

                    strong {
                        font-size: .75rem;
                        --text-letter-spacing: 1.5px;
                        text-transform: uppercase;
                        line-height: 133%;
                        color: #d12e26;
                    }

                    .gen-card-title {
                        h2 {
                            @include media-breakpoint-down(md) {
                                max-width: 18.875rem;
                            }
                        }
                    }

                    .ul-advantages,
                    .ul-alert,
                    .ul-check {
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        display: flex;
                        flex-direction: row;

                        @include media-breakpoint-down(md) {
                            max-width: 100%;
                        }
                            
                        li {
                            color: $cl-texto2;

                            &:first-child {
                                margin-right: 9.33px;
                            }

                            p {
                                line-height: 150%;
                                --text-letter-spacing: 0;

                                @include media-breakpoint-down(md) {
                                    max-width: 17rem;
                                }       
                            }
                        }
                    }   
        
                    .ul-alert,
                    .ul-check {
                        margin-bottom: 0;
                    }

                    .card-caption {
                        --text-color: #363636;
                        line-height: 133%;
                        margin: 0;
                    }
                }

                &.card-lab {
                    max-width: 26rem;
                    
                    @include media-breakpoint-down(md) {
                        max-width: 100%;
                    }
                }

                &.card-my-address {
                    width: 26.125rem;

                    @include media-breakpoint-down(md) {
                        max-width: 100%;
                    }
                }

                &.card-correios {
                    width: 26rem;

                    @include media-breakpoint-down(md) {
                        max-width: 100%;
                    }
                }
        
                img {
                    width: 13.33px;
                    height: 13.33px;
                    margin: 6px 0 10px;
                }
            }
        }

        &.card-correios-get-code,
        &.card-return-correios {
            width: 100%;
            max-width: 46.25rem;
            .gen-card-body {
                .description {
                    line-height: 150%;
                    color: $cl-cinza3;
                    --text-letter-spacing: 0;
                }
    
                .address-box {
                    max-width: 12.5rem;
                    display: flex;
                    justify-content: center;
                    padding: .5rem;

                    @include media-breakpoint-down(md) {
                        max-width: 100%;
                    }

                    strong {
                        font-size: 1.5rem;
                        line-height: 133%;
                    }
                }

                .validate {
                    width: 100%;
                    padding-bottom: 1.5rem;
                    margin: 0;
                    line-height: 133%;
                    --text-letter-spacing: 0;

                    @include media-breakpoint-down(md) {
                        text-align: center;
                        border-bottom: 1px solid rgba(22, 22, 22, 0.24);
                        margin-bottom: 1.5rem;
                    }
                }

                .important {
                    --text-letter-spacing: 0;
                    line-height: 150%;

                    a {
                        font-weight: 700;
                        color: $cl-cinza3;
                    }
                }
            }
        }

        &.card-return-correios {
            @include media-breakpoint-down(md) {
                margin: .5rem 0 0;
            }
        }

        .box-title {
            display: flex;
            gap: .625rem;
            margin-bottom: 1.5rem;

            @include media-breakpoint-down(md) {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }
}

.gen-input {
    .gen-input-group {
        input {
            &.alma {
                background:rgba(22, 22, 22, 0.04);
                box-shadow: inset 0px -1px 0px #666666;
                border: 0;
                border-radius: 4px 4px 0px 0px;
                --text-weight: 400;
                --text-size: 1rem;
                --text-color: #363636;
                line-height: 150%;

                &:hover {
                    background: rgba(22, 22, 22, 0.08);
                }

                + .gen-input-labels-group label {
                    --text-color: #666666 ;
                    --text-weight: 400;
                    --text-size: 1rem;

                    &:focus {
                        --text-size: .75rem;
                        --text-color: #666666;
                        line-height: 133%;
                    }
                }

                &:focus {
                    border-bottom-color: #666666;

                    + .gen-input-labels-group label {
                        --text-color: #666666;
                    }

                    + .gen-input-labels-group label,
                    .gen-input .gen-input-group input:not(:placeholder-shown)+.gen-input-labels-group label {
                        --text-size: .75rem;
                        --text-weight: 400;
                        --text-color: #666666;
                    }
                }
            }

            &:not(:placeholder-shown)+.gen-input-labels-group label {
                &.label {
                    --text-weight: 400;
                    --text-size: .75rem ;
                    --text-color: #666666;
                    line-height: 133%;
                }
            }
        }
    }
}

button {
    &.gen-btn {
        &.alma {
            padding: .75rem 1rem;
            border-radius: 4px;
            border: 0;
            box-shadow: none;
            --text-size: 1rem;
            line-height: 150%;
        }
    }
}

a {
    &.gen-btn {
        &.alma-btn {
            padding: .75rem 1rem;
            border-radius: 4px;
            border: 0;
            box-shadow: none;
            --text-size: 1rem;
            line-height: 150%;
        }

        &.alma {
            &.gen-btn-link {
                &.text-underline {
                    line-height: 115%;
                    --text-size: 1rem;
                    --text-weight: 500;
                }
            }
        }
    }
}

.h1 {
    &.gen-text {
        &.alma {
            line-height: 125%;
            --text-letter-spacing: -1px;
        }
    }
}

.row-gap {
    display: flex;
    flex-wrap: wrap;

    > div {
        padding: 0 1rem 0 0;

        @include media-breakpoint-down(md) {
            padding: 0;
        }

        &.field-city {
            @include media-breakpoint-down(md) {
                padding: 0 .5rem 0 0;
            }   
        }
    }

    @include media-breakpoint-down(md) {
        margin: 0;
    }
}

.fs-20 {
   --text-size: 1.25rem;
   font-size: 1.25rem;
}

.fs-16 {
    --text-size: 1rem;
    font-size: 1rem;
}

.fs-12 {
    --text-size: .75rem;
    font-size: .75rem;
}

.fs-14 {
    --text-size: .875rem;
    font-size: .875rem;
}

.side-margin-auto {
    margin-right: auto;
    margin-left: auto;
}

.text-black-light{
    color:  #262626 !important;
}

// latam
.gen-card {
    &.card-correios-get-code,
    &.card-return-partner-lab {
        &.card-mobile {
            width: 100%;
            max-width: 25.625rem;
            margin-top: 2.8125rem;
            margin-bottom: 2.5rem;

            @include media-breakpoint-down(lg) {
                background: transparent;
                box-shadow: none;
                margin: 0 auto 1rem;

                .gen-card-body {
                    padding: 0;
                }
            }

            .gen-btn-link {
                text-decoration: underline;
            }
        }
    }
}

.caption {
    &.ft-12 {
        --text-size: 12px;
    }

    &.letter-0 {
        --text-letter-spacing: 0;
    }
}
