.container {
    background: #f0eff0;
    max-width: 1280px;

    // @media (max-width: 1440px){
    //     max-width: 100%;
    // }

    @include media-breakpoint-down(md) {
        max-width: 350px;
        background: none;
    }

    @media (max-width: 480px) {
        max-width: 100%;
    }
}
